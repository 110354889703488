

import { useState, useEffect } from 'react'
import UserCard from '../components/UserCard'
import { StyledUsers } from './styles/Users.styled'
import { useUsersContext } from '../contexts/users/UsersState'

export default function Users() {

	const [ users, setUsers ] = useState([])
	const [ queryInfo, setQueryInfo ] = useState('')
	const { getUsers, setAdmin, delUser, logout } = useUsersContext()

	useEffect(()=>{
		getUsersData()
	}, [])

	useEffect(()=>{
		if (queryInfo==='') return
		setTimeout(()=>setQueryInfo(''), 5000)
	}, [queryInfo])

	const getUsersData = async () => {
		let data = await getUsers()
		if (!data.ok) return setQueryInfo(data.msg??'')
		setUsers(data.data.users)
	}

	const updtUser = async (data) => {
		let res = await setAdmin(data)
		if (!res.ok) return setQueryInfo(res.msg??'')
		let newUser = res.data.user
		setUsers(prev=>prev.map(user=>user._id===newUser._id?newUser:user))
		
	}

	const removeUser = async (_id) => {
		let res = await delUser(_id)
		if (!res.ok) return setQueryInfo(res.msg??'')
		let rmvUser = res.data.user
		setUsers(prev=>prev.filter(user=>user._id!==rmvUser._id))
	}

	return (
		<StyledUsers>
			<div className='info'>
				{queryInfo}
			</div>
			<div className='head'>
				<p>Username</p>
				<p>Admin</p>
			</div>
			{users.map(user=>
				<UserCard 
					user={user}
					key={user._id} 
					delCB={removeUser}
					adminCB={updtUser}
				/>
			)}
		</StyledUsers>
	)
}

