
import Fade from 'react-reveal/Fade'
import { StyledAboutUsHero } from './styles/AboutUsHero.styled' 

export default function AboutUsHero({ data }) {

	return (
		<StyledAboutUsHero>
			<Fade>
			<div className='contact-hero_head'>
				<h4 className='contact-hero_head--route'>
					{data.path1}
					<i className="fa-solid fa-chevron-right"></i>
					{data.path2}
				</h4>
				<h1 className='contact-hero_head--title'>
					{data.title1}
				</h1>
				<p className='contact-hero_head--text'>
					{data.text1}
				</p>
			</div>
			<div className='contact-hero_body'>
				<h1 className='contact-hero_body--title'>
					{data.title2}
				</h1>
				<p className='contact-hero_body--text'>
					{data.text2}
				</p>
			</div>
			<img src='../images/hero-banners/contact-hero-img.webp' />
			</Fade>
		</StyledAboutUsHero>
	)

}