
import styled from 'styled-components'

export const StyledServices = styled.div`

	max-width: 100%;

	& .services-nav {
		gap: 2rem;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 2rem 4rem;
		text-align: center;
		background: #F1F1F1;
		justify-content: center;

	}

	& .services-nav a {
		color: ${({theme})=>theme.text.color.scheme4}
	}

	& .services-nav a.is-active {
		color: ${({theme})=>theme.text.color.scheme3}
	}

`