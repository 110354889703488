

import styled from 'styled-components'

export const StyledUserCard = styled.div`

	gap: 1rem;
	width: 100%;
	height: 50px;
	display: flex;
	display: grid;
	align-items: center;
	padding: 0.5rem 1rem;
	transition: box-shadow 0.5s;
	justify-content: space-between;
	box-shadow: 4px 4px 10px 0px #0002;
	grid-template-columns: 1fr 60px min-content;

	&:hover {
		box-shadow: 4px 4px 10px 0px #0004;
	}

	& > *:first-child {
		flex-grow: 1;
	}

	& li {
		position: relative;
		list-style-type: none;
	}

	& i {
		cursor: pointer;
		justify-self: right;
	}

`

