// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.login-page {
	height: max-content;
	padding: 10rem 0 8rem;
	display: grid;
	place-content: center;
	background: gray;
	position: relative;
}

.login-page > *:not(img) {
	z-index: 1;
}

.login-page::before {
	content: '';
	inset: 0;
	background: #262121aa;
	position: absolute;
	z-index: 1;
}

.login-page img {
	height: 100%;
	position: absolute;
	object-fit: cover;
	z-index: 0;
}

body:has(.login-page) {
	height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login.css"],"names":[],"mappings":";;AAEA;CACC,mBAAmB;CACnB,qBAAqB;CACrB,aAAa;CACb,qBAAqB;CACrB,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,WAAW;CACX,QAAQ;CACR,qBAAqB;CACrB,kBAAkB;CAClB,UAAU;AACX;;AAEA;CACC,YAAY;CACZ,kBAAkB;CAClB,iBAAiB;CACjB,UAAU;AACX;;AAEA;CACC,aAAa;AACd","sourcesContent":["\r\n\r\n.login-page {\r\n\theight: max-content;\r\n\tpadding: 10rem 0 8rem;\r\n\tdisplay: grid;\r\n\tplace-content: center;\r\n\tbackground: gray;\r\n\tposition: relative;\r\n}\r\n\r\n.login-page > *:not(img) {\r\n\tz-index: 1;\r\n}\r\n\r\n.login-page::before {\r\n\tcontent: '';\r\n\tinset: 0;\r\n\tbackground: #262121aa;\r\n\tposition: absolute;\r\n\tz-index: 1;\r\n}\r\n\r\n.login-page img {\r\n\theight: 100%;\r\n\tposition: absolute;\r\n\tobject-fit: cover;\r\n\tz-index: 0;\r\n}\r\n\r\nbody:has(.login-page) {\r\n\theight: 100vh;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
