
// Components
import { 
	HeadText, 
	CustomList,
	ImageBanner
} from '../components/ServicesComponents'

// Styles
import { StyledCambiarios } from './styles/Cambiarios.styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'


export default function Cambiarios() {

	const { lang, servs } = useLanguageContext()

	return (
		<StyledCambiarios>
			<HeadText {...servs.cambiarios} />
			<ImageBanner {...servs.cambiarios.img} />
			<div className='cambiarios-list'>
				{servs.cambiarios.list2.map((item, idx)=>
					<div key={idx}>
						<img src={item.img} />
						<h4>{item.title}</h4>
						<p>{item.text}</p>
					</div>
				)}
			</div>
		</StyledCambiarios>
	)
}

