
// Thirdparty
import { useEffect, useState } from 'react'

// Components
import ArticleCard from '../components/ArticleCard'
import ArticleMenu from '../components/ArticleMenu'

// Styles
import { StyledArticles } from './styles/Articles.styled'

// Context
import { useArticlesContext } from '../contexts/articles/ArticlesState'

export default function Articles() {

	const [ newMenu, setNewMenu ] = useState(false)
	const [ editMenu, setEditMenu ] = useState(null)
	const [ queryInfo, setQueryInfo ] = useState('')
	const { 
		articles, 
		getArticle, 
		putArticle, 
		postArticle,
		deleteArticle
	} = useArticlesContext()

	useEffect(()=>{
		if (queryInfo==='') return
		setTimeout(()=>setQueryInfo(''), 5000)
	}, [queryInfo])

	const setArticle = async (uuid) => {
		let res = await getArticle(uuid)
		if (!res.ok) return setQueryInfo(res.msg??'')
		let data = res.data.article.reduce((a, b)=>({
			...a, 
			[b.lang]: {
				...b
			}
		}), {})
		setEditMenu(data)
	}	

	const callDelete = async (uuid) => {
		let res = await deleteArticle(uuid)
		if (!res.ok) return setQueryInfo(res.msg??'')
	}	

	return (
		<StyledArticles>
			<div className='info'>
				{queryInfo}
			</div>
			<i 
				title='add article'
				className="fa-solid fa-plus" 
				onClick={e=>setNewMenu(prev=>!prev)}
			></i>
			{newMenu &&
				<ArticleMenu 
					saveCB={postArticle}
					closeCB={e=>setNewMenu(false)}
				 />
			}
			{editMenu &&
				<ArticleMenu 
					init={editMenu}
					saveCB={putArticle}
					closeCB={e=>setEditMenu(null)}
				 />
			}
			{articles.map(article=>
				<ArticleCard 
					{...article}
					isEdit={true}
					key={article._id}
					editCB={setArticle}
					delCB={callDelete}
				/>
			)}
		</StyledArticles>
	)
}