
import './Login.css'
import LoginMenu from '../components/LoginMenu'
import { useUsersContext } from '../contexts/users/UsersState'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Login() {

	const navigate = useNavigate()
	const { user, login, register } = useUsersContext()

	useEffect(()=>{
		if (user.userId) {
			navigate('/dashboard')
		}
	}, [user])

	const sendUser = async (action, data) => {
		if (action==='login') return await login(data)
		if (action==='register') return await register(data)
	}

	return (
		<div className='login-page'>
			<img src='./images/hero-banners/landing-hero-img.webp' />
			<LoginMenu loginCallb={sendUser}/>
		</div>
	)

}