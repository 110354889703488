
import styled from 'styled-components'

export const StyledPopupMenu = styled.ul`
	
	z-index:2;
	top: 100%;
	display: none;
	min-width: 100%;
	background: #fff;
	border-radius: 3px;
	width: max-content;
	padding: 1rem 1rem 1.2rem;

	&.true { 
		display: grid;
	}

	&.floating {
		position: absolute
	}

	& div {
		margin-top: 10px;
	}

	& div a {
		list-style-type: none;
		color: ${({theme})=>theme.text.color.scheme3}		
	}

	& a:hover {
		color: ${({theme})=>theme.text.color.scheme4}
	}

	@media screen and (max-width: 650px) {

		// padding: 0.5rem 0.5rem 0.6rem;

		width: auto;

	}

`
