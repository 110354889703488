
import styled from 'styled-components'

export const StyledCambiarios = styled.div`
	
	gap: 5rem;
	margin: auto;
	display: grid;
	padding: 7rem 0rem;
	width: min(calc(100% - 1rem), 1200px);

	.cambiarios-list > div {
		display: grid;
		row-gap: 1.5rem;
		column-gap: 0.75rem;
		align-items: center;
		grid-template-columns: min-content 1fr;
	}

	.cambiarios-list > div h4 {
		font-weight: 600;
		letter-spacing: 0.25rem;
		text-transform: uppercase;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	.cambiarios-list > div p {
		gap: 1rem;
		display: flex;
		font-weight: 400;
		align-items: center;
		grid-column: 2 / span 1;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	.cambiarios-list > div img {
		width: 2rem;
		height: 2rem;
	}

	.cambiarios-list {
		display: grid;
		row-gap: 4rem;
		column-gap: 2rem;
		padding: 0rem 4rem;
		align-items: center;
		justify-content: center;
		grid-template-columns: repeat(2, 1fr);
	}

	.cambiarios-list > *:not(first-child) {
		width: 100%
	}

	@media screen and (max-width: 750px) {

		.cambiarios-list {
			row-gap: 2.5rem;
			padding: 0rem 2rem;
			grid-template-columns: repeat(1, 1fr);
		}

	}

`