
import { StyledTimeline } from './styles/Timeline.styled'
import { useEffect, useRef } from 'react'

export default function Timeline({ conf, dates }) {

	const div = useRef()

	useEffect(()=>{
		watchScroll()
		addEventListener('scroll', watchScroll)
		return ()=>removeEventListener('scroll', watchScroll)
	}, [])

	const watchScroll = () => {
		let children = [...div.current.children]
		let scrollAt = window.scrollY + document.documentElement.clientHeight 
		children.forEach(child=>{
			if (scrollAt > child.offsetTop + child.clientHeight) {
				child.classList.add('show')
				if (child===children.at(-1)) {
					removeEventListener('scroll', watchScroll)
				}
			}
		})
	}

	return (
		<StyledTimeline
			ref={div}
			$colgap = {conf?.colgap ??'3.0'}
			$dotsize= {conf?.dotsize??'1.0'}
			$dotbord= {conf?.dotsize??'0.5'}
		>
			{dates.map((date, idx)=>
				<div key={idx}>
					<h1>{date.year}</h1>
					<h4>{date.title}</h4>
					<p>{date.text}</p>
				</div>
			)}
		</StyledTimeline>
	)
}


