import styled from 'styled-components'

export const StyledCustomHero = styled.div`
	
	width: 100%;
	position: relative;
	z-index: 1;

	&::before {
		inset: 0;
		content: '';
		background: #262121aa;
		position: absolute;
		z-index: 0
	}

	& > div {
		display: grid;
		grid-gap: 0.5rem;
		padding: 13rem 4rem 4rem;	
		width: 100%;
		margin: auto;
		text-align: center;
		position: relative;
		z-index: 1
	}

	& > img {
		top:0;
		z-index: -1;
		width: 100%;
		height: 100%; 
		object-fit: cover;
		position: absolute;
	}

	.custom-hero--title {
		font-weight: 500;
		line-height: 3rem;
		font-size: 3.125rem;
		font-family: 'Jost', sans-serif;
		color: ${({theme}) => theme.text.color.scheme1}
	}

	.custom-hero--route {
		display: flex;
		gap: 1rem;
		align-items: center;
		font-weight: 100;
		justify-content: center;
		font-family: 'Nunito', sans-serif;
		color: ${({theme}) => theme.text.color.scheme1};
	}

`