
import { NavLink as ReactNavLink } from 'react-router-dom'


export default function NavLink({to, children, classnames, ...props}) {
	return (
		<ReactNavLink 
			to={to}
			{...props}
			className={({ isActive })=>{
				return (isActive?'is-active ':'').concat(classnames??'')
			}}>
			{children}
		</ReactNavLink>
	)
}