
import styled from 'styled-components'

export const StyledTimeline = styled.div`

	--row-gap: 2;
	margin: auto;
	display: grid;
	text-align: right;
	grid-template-columns: 1fr;
	gap: calc(var(--row-gap) * 1rem);
	width: min(1200px, calc(100% - 6rem));

	& > div {
		width: 50%;
		opacity: 0;
		position: relative;
		transition: opacity 3s;
	}

	& > div.show {
		opacity: 1;
	}

	& > div:nth-child(2n + 1) {
		padding-right: calc(${({$colgap})=>$colgap} * 1rem);
	}

	& > div:nth-child(2n) {
		text-align: left;
		justify-self: right;
		padding-left: calc(${({$colgap})=>$colgap} * 1rem);
	}

	& > div:after {
		top: 0;
		bottom: 0;
		content: '';
		margin: auto;
		border-radius: 50%;
		position: absolute;
		width: calc(${({$dotsize})=>$dotsize} * 1rem);
		height: calc(${({$dotsize})=>$dotsize} * 1rem);
		background: ${({theme})=>theme.text.color.scheme4};
		border: calc(${({$dotbord})=>$dotbord} * 1rem) solid #fff;
		outline: 0.1rem solid ${({theme})=>theme.text.color.scheme4};
	}

	& > div:before {
		top:0;
		right:0;
		content: '';
		width: 100%;
		opacity: 0.8;
		margin: auto;
		background: #0000;
		position: absolute;
		height: calc(100% + var(--row-gap) * 1rem);
	}

	& > div:first-child:before {
		height: calc(50% + var(--row-gap) * 1rem);
		transform: translateY(calc(100% - var(--row-gap) * 1rem));
	}

	& > div h1 {
		font-size: 5rem;
		font-weight: 500;
	}

	& > div h4 {
		color: ${({theme})=>theme.text.color.scheme2}
	}

	& > div p {
		opacity: 0.9;
		color: ${({theme})=>theme.text.color.scheme3}
	}

	@media screen and (max-width: 500px) {
		& > div:before {
			border-left: 0px;
			border-right: 1px solid ${({theme})=>theme.text.color.scheme3};
		}
		& > div {
			width: 100%;
			text-align: left;
		}
		& > div:after {
			left: none;
			right: calc(${({$dotsize})=>$dotsize} * -0.5rem - ${({$dotbord})=>$dotbord} * 1rem);
		}

	}

	@media screen and (min-width: 500px) {


		& > div:nth-child(2n):before {
			border-left: 1px solid ${({theme})=>theme.text.color.scheme3};
		}

		& > div:nth-child(2n+1):before {
			border-right: 1px solid ${({theme})=>theme.text.color.scheme3};
		}

		& > div:nth-child(2n+1):after {
			right: calc(${({$dotsize})=>$dotsize} * -0.5rem - ${({$dotbord})=>$dotbord} * 1rem);
		}

		& > div:nth-child(2n):after {
			left: calc(${({$dotsize})=>$dotsize} * -0.5rem - ${({$dotbord})=>$dotbord} * 1rem);
		}
	}

`