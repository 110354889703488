
const sectionsES = [
	{
		title: 'Desarrollamos soluciones “Taylor made” para el estado de cada empresa',
		content: 'Nuestro enfoque personalizado se adapta a las necesidades específicas de cada cliente, ofreciendo un modelo de negocio -a medida- que abarca la planeación y estrategia. Como expertos conocedores del mercado colombiano, nos erigimos como aliados estratégicos de nuestros clientes, brindando apoyo y asesoramiento integral para facilitar la toma de decisiones y el logro de resultados exitosos en sus negocios. En NDR garantizamos un acompañamiento sólido y confiable en la travesía empresarial, impulsando el crecimiento y el éxito de nuestros clientes',
		img: './images/home/home-section-img-1.png',
		link: {
			label: 'Leer más',
			url: null
		}
	},
	{
		title: 'Responsabilidad social para todos',
		content: 'Nuestra iniciativa social "Mochuelo" nace como un proyecto familiar que acompaña a las madres comunitarias en el barrio Mochuelo en Ciudad Bolívar, llevando alegría navideña a más de 1000 niños vulnerables cada año. A lo largo del tiempo, hemos tejido una red de corazones generosos que se multiplica compartiendo el espíritu festivo de la navidad con el apoyo de familia, amigos, clientes y personas dispuestas a unirse a nuestro propósito común.',
		img: './images/home/home-section-img-2.png',
		link: {
			label: 'Conoce nuestra historia',
			url: '/mochuelo'
		}
	}
]

const sectionsEN = [
	{
		title: 'We developed taylor made solutions for every bussiness state',
		content: 'Our personalized approach adapts to the specific needs of each client, offering a tailor-made business model that encompasses planning and strategy. As knowledgeable experts in the Colombian market, we stand as strategic allies to our clients, providing comprehensive support and advice to facilitate decision-making and the achievement of successful results in their businesses. At NDR, we ensure a strong and reliable companionship on the business journey, driving the growth and success of our clients.',
		img: './images/home/home-section-img-1.png',
		link: {
			label: 'More about',
			url: null
		}
	},
	{
		title: 'Social awarenes for everyone',
		content: 'Our social initiative "Mochuelo" is born as a family project that accompanies community mothers in the Mochuelo neighborhood in Ciudad Bolívar, bringing Christmas joy to over 1000 vulnerable children each year. Over time, we have woven a network of generous hearts that multiplies by sharing the festive spirit of Christmas with the support of family, friends, clients, and individuals willing to join our common purpose.',
		img: './images/home/home-section-img-2.png',
		link: {
			label: 'Know our history',
			url: '/mochuelo'
		}
	}
]

const hoverCardsES = [
	{
		title: 'Contabilidad y NIIF',
		text: 'En NDR, adaptamos procesos contables a las necesidades específicas de nuestros clientes, ofreciendo asesoría actualizada y apoyo estratégico para su éxito empresarial.',
		link: {
			label: 'Conoce más',
			url: '/servicios/contabilidad&niif'
		}
	},
	{
		title: 'Tributarios',
		text: 'Nos dedicamos a fortalecer el proceso tributario de nuestros clientes, abarcando desde la planificación hasta la optimización fiscal, enfocándonos en reducir riesgos ymaximizar beneficios. Aseguramos una gestión eficaz para promover su crecimiento y éxito financiero.',
		link: {
			label: 'Conoce más',
			url: '/servicios/tributarios'
		}
	},
	{
		title: 'Laborales',
		text: 'Apoyamos a nuestros clientes en la gestión laboral para sus operaciones en Colombia, cubriendo desde afiliaciones hasta la liquidación de contratos, con un enfoque en minimizar riesgos laborales mediante una diligencia meticulosa en todos los procesos.',
		link: {
			label: 'Conoce más',
			url: '/servicios/laborales'
		}
	},
	{
		title: 'Cambiarios',
		text: 'Apoyamos a nuestros clientes en áreas clave como inversión extranjera y procesos de importación/exportación, adaptando nuestros servicios a sus necesidades específicas. Nos esforzamos por asegurar su éxito y prosperidad internacional mediante una asociación a largo plazo y una atención detallada a cada etapa del proceso.',
		link: {
			label: 'Conoce más',
			url: '/servicios/cambiarios'
		}
	},
	{
		title: 'Auditoría y aseguramiento',
		text: 'Brindamos auditoría y revisoría fiscal centradas en la independencia, enfocadas en la prevención y el análisis proactivo de controles y cumplimiento normativo, para garantizar la eficiencia operacional y la precisión financiera de nuestros clientes.',
		link: {
			label: 'Conoce más',
			url: '/servicios/auditoria&aseguramiento'
		}
	},
	{
		title: 'Back Office',
		text: 'Ofrecemos soluciones personalizadas en gestión administrativa, abarcando tesorería, facturación electrónica, control de cartera, nómina, y manejo documental, para apoyar el desarrollo eficiente de las operaciones de las compañías como aliados estratégicos.',
		link: {
			label: 'Conoce más',
			url: '/servicios/backoffice'
		}
	}
]

const hoverCardsEN = [
	{
		title: 'Accounting & IFRS',
		text: 'At NDR, we adapt accounting processes to the specific needs of our clients, offering updated advice and strategic support for their business success.',
		link: {
			label: 'More about',
			url: '/servicios/contabilidad&niif'
		}
	},
	{
		title: 'Taxation',
		text: 'We are dedicated to strengthening the tax process of our clients, covering everything from planning to tax optimization, focusing on reducing risks and maximizing benefits. We ensure effective management to promote their growth and financial success.',
		link: {
			label: 'More about',
			url: '/servicios/tributarios'
		}
	},
	{
		title: 'Labor',
		text: 'We support our clients in labor management for their operations in Colombia, covering everything from affiliations to contract termination, with a focus on minimizing labor risks through meticulous diligence in all processes.',
		link: {
			label: 'More about',
			url: '/servicios/laborales'
		}
	},
	{
		title: 'Exchange',
		text: 'We support our clients in key areas such as foreign investment and import/export processes, adapting our services to their specific needs. We strive to ensure their success and international prosperity through long-term partnerships and detailed attention to each stage of the process.',
		link: {
			label: 'More about',
			url: '/servicios/cambiarios'
		}
	},
	{
		title: 'Audit & assurance',
		text: 'We provide audit and fiscal review focused on independence, focused on prevention and proactive analysis of controls and regulatory compliance, to ensure operational efficiency and financial accuracy for our clients.',
		link: {
			label: 'More about',
			url: '/servicios/auditoria&assurance'
		}
	},
	{
		title: 'Back Office',
		text: 'We offer customized solutions in administrative management, covering treasury, electronic invoicing, accounts receivable management, payroll, and document management, to support the efficient development of company operations as strategic allies.',
		link: {
			label: 'More about',
			url: '/servicios/backoffice'
		}
	}
]

const quotesES = [
	{
		quote: 'No tengo que ser un experto en todas las empresas, ni tampoco en muchas. Sólo tengo que estar en la capacidad de evaluar las industrias y los lideres de estas.',
		author: 'Charlie Munger'
	},
	{
		quote: 'En el mundo de los negocios, el retrovisor siempre es más claro que el parabrisas.',
		author: 'Warren Buffet'
	},
	{
		quote: 'Vivimos en un mundo que cambia a toda velocidad, donde resulta necesario dedicar tanto tiempo a pensar como a reconsiderar.',
		author: 'Adam Grant'
	}
]

const quotesEN = [
	{
		quote: "I don't have to be an expert on every company, or even many. I only have to be able to evaluate industries and the leaders within them.",
		author: 'Charlie Munger'
	},
	{
		quote: 'In the business world, the rearview mirror is always clearer than the windshield.',
		author: 'Warren Buffet'
	},
	{
		quote: 'We live in a rapidly changing world, where we need to spend as much time rethinking as we do thinking.',
		author: 'Adam Grant'
	},
]

const heroES = {
	title: 	'Estamos listos para ayudarte a hacer\nbuenos negocios',
	subtl: 	'Trabajamos para empoderar a los medianas empresas a través de\nsoluciones contables de alto impacto.',
	button: 'Conoce más',
	link:   'Aprende sobre nuestro programa de responsabilidad social'
}

const heroEN = {
	title: 	'We are ready to help you doing\ngreat business',
	subtl: 	'We work to empower medium-sized businesses through\nhigh impact accounting solutions.',
	button: 'More about',
	link:   'Learn about our social awareness program'	
}

export {
	heroES,
	heroEN,
	quotesES,
	quotesEN,
	sectionsEN,
	sectionsES,
	hoverCardsEN,
	hoverCardsES
}