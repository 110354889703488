
import Fade from 'react-reveal/Fade'
import { StyledCustomHero } from './styles/CustomHero.styled' 

export default function CustomHero(props) {

	return (
		<StyledCustomHero>
			<Fade>
			<div>
			<h1 className='custom-hero--title'>
				{props.title}
			</h1>
			<h4 className='custom-hero--route'>
				{props.label1}
				<i className="fa-solid fa-chevron-right"></i>
				{props.label2}
			</h4>
			</div>
			<img src='../images/hero-banners/landing-hero-img.webp' />
			</Fade>
		</StyledCustomHero>
	)

}