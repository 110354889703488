
import styled from 'styled-components'

export const StyledArticleCard = styled.div`
	
	display: grid;
	overflow: hidden;
	border-radius: 5px;
	position: relative;
	box-shadow: 0 4px 5px 0px #0001;
	background: ${({theme})=>theme.text.color.scheme1};

	& > img {
		height: 250px;
		overflow: hidden;
		object-fit: cover;
	}

	&  .card-info {
		margin: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.card-info > *:not(div) {
		margin: 1rem 0;
	}

	& .card-info > div {
		overflow: hidden;
		min-height: 4rem;
		display: -webkit-box;
		-webkit-line-clamp: 5;
				line-clamp: 5;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	& .card-info a small {
		font-weight: 700;
		width: max-content;
		color:  ${({theme})=>theme.text.color.scheme4};
	}

	& .card-info a:hover small {
		color:  ${({theme})=>theme.text.color.scheme3};
	}

	.edit-div {
		gap: 1rem;
		right: 2rem;
		bottom: 2rem;
		display: flex;
		position: absolute;
	}

	.edit-div i {
		cursor: pointer;
	}


`