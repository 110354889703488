
import styled from 'styled-components'

export const StyledAboutUs = styled.div`

	gap: 6rem;
	display: grid;
	max-width: 100%;
	padding-bottom: 8rem;

	.history-div,
	.team-head {
		gap: 1rem;
		margin: auto;
		display: grid;
		text-align: center;
	}

	.history-div h4 {
		font-weight: 600;
		white-space: pre-wrap;
		letter-spacing: 0.3rem;
		color: ${({theme})=>theme.text.color.scheme3}
	}

	.history-div p {
		opacity: 0.9;
		white-space: pre-wrap;
		color: ${({theme})=>theme.text.color.scheme3}
	}

	.team-div {
		gap: 2rem;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		padding-bottom: 6rem;
		justify-content: center;
		width: min(1200px, calc(100% - 4rem));
	}

	.team-head p {
		font-weight: 500;
		letter-spacing: 0.5rem;
		color: ${({theme})=>theme.text.color.scheme3}; 
	}

	.team-head h1 {
		font-weight: 100;
		font-size: 2.8125rem;
		color: ${({theme})=>theme.text.color.scheme3}; 
	}

	.team-div--more {
		left: 0;
		right: 0;
		bottom: 1rem;
		margin: auto;
		cursor: pointer;
		position: absolute;
		width: max-content;
		height: max-content;
		padding: 0.5rem 2rem;
		color: ${({theme})=>theme.text.color.scheme1};
		background: ${({theme})=>theme.text.color.scheme4};
	}

	.team-div--more:hover {
		opacity: 0.7
	}

`