
import { useEffect, useState } from 'react'
import CustomSpinner from './CustomSpinner'
import { StyledTeamCard } from './styles/TeamCard.styled'


export default function TeamCard(props) {

	const [ imageSrc , setImageSrc ] = useState('./images/default.jpg')

	useEffect(()=>{
		getImage()
	}, [props.updatedAt])

	const getImage = async () => {
		try {
			let res = await fetch(props.image, {
					cache: 'reload',
					mode: 'no-cors'
				})
			if (!res.ok) return
			let blob = await res.blob()
			let src  = URL.createObjectURL(blob) 
			setImageSrc(src)
		} catch (err) {
			console.log(err)
		}
	}

	const callEditCB = () => {
		if (!props.editCB||!props._id) return
		props.editCB(props._id)
	}

	const callDelCB = () => {
		if (!props.delCB||!props._id) return
		props.delCB(props._id)
	}

	return (
		<StyledTeamCard>
			{imageSrc && <img src={imageSrc} />}
			{!imageSrc && <CustomSpinner size={150} />}
			<h6>
				{props.name}<br/>
				<span>{props.charge}</span>
			</h6>
			<p>{props.profile}</p>
			<div className='social'>
				{props?.email && 
					<a
						target='_BLANK'
						href={`mailto: ${props.email}`}
					>
						<i className="fa-regular fa-envelope"></i>
					</a>
				}
				{props?.linkedin && 
					<a
						target='_BLANK'
						href={props.linkedin}
					>
						<i className="fa-brands fa-linkedin-in"></i>
					</a>
				}
			</div>
			{props.isEdit && 
				<div className='edit-div'>
					<i 
						onClick={callEditCB}
						className="fa-solid fa-pen-to-square"></i>
					<i 
						onClick={callDelCB}
						className="fa-solid fa-trash"></i>
				</div>
			}
		</StyledTeamCard>
	)

}