
import styled from 'styled-components'

export const StyledHome = styled.div`
	
	display: grid;
	grid-gap: 3rem;
	max-width: 100%;
	position: relative;

	& .servicios-banner {
		width: 100%;
		margin: auto;
		padding: 4rem;
		display: grid;
		grid-gap: 2rem;
		text-align: center;
		background: ${({theme})=>theme.text.color.scheme1}
	}

	& .servicios-banner p {
		font-weight: 300
		letter-spacing: 0.25rem;
	}

	& .servicios-banner h3 {
		font-weight: 400
	}

`
