
// Components
import NavLink from './NavLink'
import PopupMenu from './PopupMenu'
import { StyledHeader } from './styles/Header.styled'

// Thirdparty
import { useState, useEffect } from 'react'
import { useNavigate  } from 'react-router-dom'

// Context
import { useLanguageContext } from '../contexts/language/LanguageState'

export default function Header({ links }) {

	const navigate = useNavigate()
	const [ hoverOn, setHoverOn ] = useState('')
	const [ clickOn, setClickOn ] = useState('')
	const [ isClick, setIsClick ] = useState(false)
	const [ showNav, setShowNav ] = useState(false)

	const { lang, setLang } = useLanguageContext()

	useEffect(()=>{
		const checkWidth = () => {
			if (window.innerWidth<650) setIsClick(true)
			if (window.innerWidth>649) setIsClick(false)
		}
		checkWidth()
 		addEventListener('resize', checkWidth)
	}, [])

	const handleClick = (url) => {
		navigate(url)
		setShowNav(false)
	}

	return (
		<StyledHeader className='header'>
			<img
				className='logo-white'
				onClick={e=>navigate('/')} 
				src='../images/logo-2.png' />
			<img
				className='logo-black'
				onClick={e=>navigate('/')} 
				src='../images/logo-black-2.png' />
			<nav className={`${showNav?'show':'hide'}`}>
				{links.map(link=>
					<div key={link.label}>
					{link.options && 
						<li
							onMouseLeave={e=>setHoverOn('')}
							onMouseEnter={e=>setHoverOn(link.label)}
							onClick={e=>setClickOn(prev=>prev===link.label?'':link.label)}
						>
							{link.label}
							{link.type && link.type==='action' && 
								<>
									&emsp;
									{link.options.find(opt=>opt.url===lang).label}
								</>
							}
							<i className="fa-solid fa-chevron-down"></i>
							<PopupMenu 
								links={link}
								abs={!isClick} 
								click={e=>setShowNav(false)}
								action={link.type?setLang:null} 
								show={
									(hoverOn===link.label&&!isClick)||
									(clickOn===link.label&&isClick)
								}
							/>
						</li>
					}
					{!link.options && 
						<a 
							onClick={e=>handleClick(link.url)}
						>
							{link.label}
						</a>
					}
					</div>
				)}
			</nav>
			<i 
				className="fa-solid fa-bars"
				onClick={e=>setShowNav(prev=>!prev)}>	
			</i>
		</StyledHeader>
	)

}
