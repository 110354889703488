
import Fade from 'react-reveal/Fade'
import { StyledStats } from './styles/Stats.styled'
import { useEffect, useRef, useState } from 'react'

export default function Stats({ data, clearBg = false }) {

	const statsDiv = useRef()
	const [ animStat, setAnimStat ] = useState(false)

	useEffect(()=>{
		const watchScroll = (e) => {
			if (!statsDiv.current) return
			if (window.scrollY + window.innerHeight >= statsDiv.current.offsetTop) {
				setAnimStat(true)
			}
		}
		setTimeout(()=>{
			addEventListener('scroll', watchScroll)
		}, 1000)
		
		return ()=>removeEventListener('scroll', watchScroll)
	}, [])

	return (
		<StyledStats
			style={{
				background: `${clearBg?'none':'#E5E4E4'}`
			}} 
			ref={statsDiv}>
			<Fade>
			<h1>
				{data.title}
			</h1>
			<p>
				{data.subtl}
			</p>
			<h1 className='stats-section--gradient'>
				STATS
			</h1>
			</Fade>
			<Fade>
			<div>
				{data.stats.map((stat,idx)=>
					<div 
						key={idx}
						style={{'--val': stat.value}}
						className={`
							${animStat?'anim':''} 
							${stat.pctg?'pctg':''}
							${stat.curr?'curr':''}
							`}
					>
						<p>{stat.text}</p>
					</div>
				)}
			</div> 
			</Fade>
		</StyledStats>
	)
}