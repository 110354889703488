import styled from 'styled-components'

export const StyledTeamMenu = styled.div`

	inset: 0;
	z-index: 30000;
	margin: auto;
	display: grid;
	position: fixed;
	column-gap: 1rem;
	width: max-content;
	padding: 3rem 2rem;
	border-radius: 10px;
	height: max-content;
	box-shadow: 0px 0px 10px 0px #0004;
	grid-template-columns: max-content min-content;
	background: ${({theme})=>theme.text.color.scheme1};

	& > input,
	& > textarea {
		grid-column: 1 / span 2;
		padding: 0.2rem 1rem;
	}

	& > label {
		margin-top: 1rem;
	}

	& > textarea {
		resize: none;
		height: 200px;
		width: min(350px, calc(100vw - 8rem));
	}

	& button {
		border: none;
		cursor: pointer;
		margin-top: 2rem;
		padding: 0.5rem 0;
		justify-self: right;
		width: max(100px, 80%);
	}

	& #cancel {
		color: #0096A3;
		background: #fff0;
	}

	& #save {
		color: #fff;
		background: #0096A3;
	}

	& #save i {
		animation: spinAnimation 1s infinite linear;
	}

	@keyframes spinAnimation {
		0% {
			transform: rotate(0deg)
		}
		100% {
			transform: rotate(360deg);
		}
	}

	& .info {
		top: auto;
		width: 100%;
		bottom: 1rem;
		margin: auto;
		color: salmon;
		padding: 0 2rem;
		font-size: 0.9rem;
		position: absolute;
		text-align: center;
		overflow: hidden;
		height: max-content;
		display: -webkit-box;
		-webkit-line-clamp: 1;
				line-clamp: 1;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
	}


	@media only screen and (max-width:  450px) {

		input[type='file'] {
		  width: 11.8rem;
		  overflow: hidden;
		}

	}


`