
import { useState } from 'react'
import PopupMenu from '../components/PopupMenu'
import { StyledUserCard } from './styles/UserCard.styled'

export default function UserCard({ user, adminCB, delCB }) {

	const [ pmenu, setPmenu ] = useState(null)
	const [ hoverOn , setHoverOn ] = useState(null)

	const setAdmin = (value) => {
		if (!adminCB) return
		adminCB({
			value,
			_id: user._id,
		})
	}

	return (
		<StyledUserCard>
			<p>{user.username}</p>
			<li
				onMouseLeave={e=>setHoverOn(null)}
				onMouseEnter={e=>setHoverOn('admin')}
			>
				{user.admin.toString()}
				<i className="fa-solid fa-chevron-down"></i>
				<PopupMenu 
					abs={true}
					show={hoverOn==='admin'}
					action={setAdmin} 
					links={{options: ['true', 'false']}} 
				/>
			</li>
			<i 
				className="fa-solid fa-trash"
				onClick={e=>delCB?delCB(user._id):null}>
			</i>
		</StyledUserCard>
	)

}