
// Thirdparty
import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

// Components
import NavLink from '../components/NavLink'

// Styles
import { StyledDashboard } from './styles/Dashboard.styled'

// Contexts 
import { useUsersContext } from '../contexts/users/UsersState'

const menu = [
	{
		name : 'users',
		label: 'Usuarios',
		icon : <i className="fa-solid fa-users"></i>
	},
	{
		name : 'articles',
		label: 'Articulos',
		icon : <i className="fa-regular fa-newspaper"></i>
	},
	{
		name : 'team',
		label: 'Equipo',
		icon : <i className="fa-solid fa-people-group"></i>
	},		
]

export default function Dashboard() {

	const navigate = useNavigate()
	const { user, logout } = useUsersContext()
	const [ showMenu, setShowMenu ] = useState(true)
	
	return (

		<StyledDashboard
			$showMenu={showMenu}
		>
			<div className='dashboard-header'>
				<i 
					onClick={e=>setShowMenu(prev=>!prev)}
					className="fa-solid fa-bars">
				</i>
				<img
					className='logo'
					onClick={e=>navigate('/')} 
					src='./images/logo-black.svg' />
				<img
					className='logo-small'
					onClick={e=>navigate('/')} 
					src='./images/logo-small.svg' />
				<i 
					onClick={e=>logout('/')}
					className="fa-solid fa-right-from-bracket">
				</i>
			</div>
			{showMenu && 
				<div className='dashboard-menu'>
				{menu.map(item=>
				 	<NavLink 
				 		key={item.name}
				 		to={`/dashboard/${item.name}`}
				 	>
				 		{item.icon}&emsp;{item.label}
				 	</NavLink>
				 )}
				</div>
			}
			<div className='dashboard-content'>
				<Outlet />
			</div>
		</StyledDashboard>

	)
}


