
import SliderDots from './SliderDots'
import { useState, useEffect } from 'react'
import { StyledCustomSlider } from './styles/CustomSlider.styled'

import Fade from 'react-reveal/Fade';

export default function CustomSlider({ children, ...props }) {

	const [ sliderIdx, setSliderIdx ] = useState(null)
	const [ controlIdx, setControlIdx] = useState(null)
	const [ ncards, setNCards ] = useState(()=>
		window.innerWidth<500?1:window.innerWidth<749?2:3)

	useEffect(()=>{
		const checkWidth = () => {
			if (window.innerWidth > 749) setNCards(3)			
			if (window.innerWidth < 750) setNCards(2)
			if (window.innerWidth < 500) setNCards(1)
		}

		checkWidth()
		addEventListener('resize', ()=>checkWidth())

		return removeEventListener('resize', ()=>checkWidth())
	}, [])

	const changeIdx = (idx) => {
		const nItems = Math.ceil(children.length / ncards)
		setSliderIdx(prev=>
			parseInt(prev) + idx < 0 ?
			nItems - 1 :
			parseInt(prev) + idx >= nItems ?
			0 :
			parseInt(prev) + idx
		)
	}

	return (
		<StyledCustomSlider 
			$idx={sliderIdx}
			$ncards={ncards}
			$show={children.length?true:false}
		>
			<Fade>
			<div className='slider-div'>
			<div className='slider-grid'>
				{children}
			</div>
			<SliderDots 
				w={40}
				cb={setSliderIdx} 
				current={sliderIdx}
				nItems={Math.ceil(children.length / ncards)} />
			</div>
			<i 
				onClick={e=>changeIdx(-1)}
				className="fa-solid fa-circle-chevron-left">
			</i>
			<i 
				onClick={e=>changeIdx(+1)}
				className="fa-solid fa-circle-chevron-right">
			</i>
			</Fade>
		</StyledCustomSlider>
	)
}

