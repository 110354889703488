
import styled from 'styled-components'

export const StyledTeamCard = styled.div`

	gap: 1rem;
	display: flex;
	padding: 2rem;
	margin-top: 2rem;
	text-align: center;
	position: relative;
	align-items: center;
	border-radius: 1rem;
	height: min-content;
	flex-direction: column;
	width: min(100%, 300px);
	box-shadow: 0 5px 9px 2px #0003;

	& > img {
		border-radius: 50%;
		height: 50%;
		width: 60%;
	}

	& h6 {
		font-size: 0.9rem;
	}

	& h6 span {
		font-weight: 400;
		color: ${({theme})=>theme.text.color.scheme4};
	}

	& p {
		color: ${({theme})=>theme.text.color.scheme3};
		font-size: 0.8rem;
	}

	& .social {
		gap: 1rem;
		display: flex;
	}

	& .social i {
		display: grid;
		font-size: 1rem;
		padding: 0.5rem;
		border-radius: 50%;
		place-content: center;
	}

	& .social a:first-child i {
		opacity: 1;
		background: #0096A355;
		color: ${({theme})=>theme.text.color.scheme4};
	}

	& .social a:last-child i {
		background: #0096A355;
		color: ${({theme})=>theme.text.color.scheme1};
	}

	& .social a:hover i {
		background: #0096A388;
	}

	.edit-div {
		gap: 1rem;
		top: 2rem;
		right: 2rem;
		display: flex;
		position: absolute;
	}

	.edit-div i {
		cursor: pointer;
	}

`