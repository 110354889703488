

// Components
import MyMap from '../components/Map'
import CustomHero from '../components/CustomHero'
import ContactSection from '../components/ContactSection'

// Styles 
import { StyledContact } from './styles/Contact.styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'

// Thirdparty
import Fade from 'react-reveal/Fade'
import { Wrapper } from "@googlemaps/react-wrapper"

export default function Contact() {

	const { lang, cntct, netws } = useLanguageContext()

	return (
		<StyledContact>
			<CustomHero 
				title ={lang==='ES'?'Contactános':'Contact us'}
				label1={lang==='ES'?'Inicio':'Home'}
				label2={lang==='ES'?'Contacto':'Contact'}
				/>
			<Fade>
			<div className='contact-links'>
				{netws.map((item,idx)=>
					<div key={idx}>
						{item.title}
						{item.links.map((link, aid)=>
							<a 
								key={aid} 
								href={link.url} 
								target='_BLANK'
							>
								{link.label}
							</a>
						)}
					</div>
				)}
			</div>
			</Fade>
			<div className='contact-form'>
				<ContactSection data={cntct} />
			    <Wrapper
			      version="beta"
			      libraries={["marker"]}
			      apiKey={process.env.GOOGLE_API}
			    >
			      <MyMap />
			    </Wrapper>
			</div>
		</StyledContact>
	)
}

