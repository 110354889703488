

import { 
	HeadText, 
	CustomList,
	ImageBanner
} from '../components/ServicesComponents'

// styles 
import { StyledLaborales } from './styles/Laborales.styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'


export default function Laborales() {

	const { lang, servs } = useLanguageContext()

	return (
		<StyledLaborales>
			<HeadText {...servs.laborales} />
			<ImageBanner {...servs.laborales.img} />
			<CustomList {...servs.laborales.list} />
		</StyledLaborales>
	)
}

