// Thirdparty
import { useEffect, useState } from 'react'

// Components
import TeamCard from '../components/TeamCard'
import TeamMenu from '../components/TeamMenu'

// Styles
// import { StyledTeam } from './styles/Team.styled'
import './Team.css'

// Context
import { useTeamContext } from '../contexts/team/TeamState'

export default function Team() {

	const [ newMenu, setNewMenu ] = useState(false)
	const [ editMenu, setEditMenu ] = useState(null)
	const [ queryInfo, setQueryInfo ] = useState('')
	const { 
		team, 
		getMember, 
		putMember, 
		postMember,
		deleteMember
	} = useTeamContext()

	useEffect(()=>{
		if (queryInfo==='') return
		setTimeout(()=>setQueryInfo(''), 5000)
	}, [queryInfo])

	const callDelete = async (id) => {
		let res = await deleteMember(id)
		if (!res.ok) return setQueryInfo(res.msg??'')
	}	

	return (
		<div className='team-div'>
			<div className='info'>
				{queryInfo}
			</div>
			<i 
				title='add article'
				className="fa-solid fa-plus" 
				onClick={e=>setNewMenu(prev=>!prev)}
			></i>
			{newMenu &&
				<TeamMenu 
					saveCB={postMember}
					closeCB={e=>setNewMenu(false)}
				 />
			}
			{editMenu &&
				<TeamMenu 
					init={editMenu}
					saveCB={putMember}
					closeCB={e=>setEditMenu(null)}
				 />
			}
			{team.map((member, idx)=>
				<TeamCard 
					key={idx}
					{...member}
					isEdit={true}
					delCB={callDelete}
					editCB={e=>setEditMenu(member)}
				/>
			)}
		</div>
	)
}