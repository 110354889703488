
import styled from 'styled-components'

export const StyledStats = styled.div`
	
	max-width: 100%;
	margin-top: -3rem;
	padding: 5rem 0 0;
	text-align: center;
	background: #E5E4E4;

	& > p {
		font-weight: 300;
		font-size: 0.8rem;
		letter-spacing: 0.5rem;
	}

	.stats-section--gradient {
		font-size: 7.5rem;
		margin-top: 1.5rem;
		letter-spacing: 3rem;
		font-family: sans-serif;
		background: linear-gradient(to
		 bottom, #D2D0D0 20%, #E7E3E300 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	@property --value {
		syntax: '<integer>';
		initial-value: 0;
		inherits: false;
	}

	& > div {
		margin: auto;
		display: grid;
		grid-row-gap: 3rem;
		position: relative;
		justify-items: center;
		justify-content: center;
		padding: 7rem 7rem 5rem;
		grid-template-columns: repeat(4, min(300px, calc(calc(100vw - 7rem)/5)));
	}

	& > div::before,
	& > div::after {
		content: '';
		height: 100%;
		background: #fff;
		position: absolute;
		width: min(1200px, calc(100vw - 7rem));
	}

	& > div::before {
		top: 2.5rem;
		min-width: 100%;
		background: #FAFAFA;
		height: calc(100% - 2.5rem);
	}

	& > div div {
		--value: 0;
		z-index: 1;
		padding: 1rem;
		position: relative;
		counter-reset: value var(--value);
		transition: --value 3s cubic-bezier(0.42, 0, 0.58, 1.0);
	}
	& > div div p {
		opacity: 0.8;
		color: ${({theme})=>theme.text.color.scheme3}
	}

	& > div div.anim {
		--value: var(--val);
	}

	& > div div::before {
		left: 0;
		right: 0;
		top: -4rem;
		margin: auto;
		display: flex;
		font-size: 2rem;
		font-weight: 700;
		min-height: 4.5rem;
		position: absolute;
		align-items: flex-end;
		justify-content: center;
		content: counter(value);
		color: ${({theme})=>theme.text.color.scheme4}
	}

	& > div div.pctg::before {
		content: counter(value) '%';
	}

	& > div div.curr::before {
		line-height: 2.3rem;
		content: '+ $' counter(value) ' Millones';
	}

	@media screen and (max-width: 850px) {
		& > div {
			padding: 5rem 4rem 3rem;
			grid-template-columns: repeat(2, 1fr);
		}	

		.stats-section--gradient {
			font-size: 5rem;
			letter-spacing: 1.5rem;
		}

	}

	@media screen and (max-width: 400px) {

		& > div {
			padding: 7rem 5rem;
			grid-row-gap: 4rem;
			grid-template-columns: repeat(1, 1fr);
		}
	
		.stats-section--gradient {
			font-size: 3.5rem;
			letter-spacing: 1rem;
		}

	}

`