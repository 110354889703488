

import './LoginMenu.css'
import { useState, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import queryReducer from '../Utils/query-reducer'

export default function LoginMenu({loginCallb}) {

	const navigate = useNavigate()
	const [ isLogin, setIsLogin ] = useState(true)
	const [ query, dispatch ] = useReducer(queryReducer, {
		waiting: false,
		success: null,
		tryed: false,
		info: ''
	})
	const [ user, setUser ] = useState({
		username: '',
		password: '',
		email: ''
	})

	function handleChange(e) {
		const { name, value } = e.target
		setUser(prevState=>({
			...prevState, 
			[name]: value
		}))
	}

	async function sendUser() {
		if (!loginCallb) return
		if (query.waiting) return
		await dispatch({type: 'NEW_QUERY'})
		let action = !isLogin?'register':'login'
		const res = await loginCallb(action, user)
		if (res?.ok) dispatch({type: 'SUCCESS', msg:`${action} succeed!`})
		else dispatch({type: 'ERROR', msg: res?.msg??'BAD REQUEST'})
	}

	return (

		<div className='login-menu'>
			<form 
				className='sign-form'>
				<div
					style={{visibility: !isLogin?'visible':'hidden'}}
					className='sign-form_user'>
					<i className="fa-solid fa-user"></i>
					<input 
						disabled={query.waiting}
						name='username'
						onChange={handleChange}
						value={user.username}
						placeholder='Username'/>
				</div>
				<div className='sign-form_email'>
					<i className="fa-solid fa-envelope"></i>
					<input 
						disabled={query.waiting}
						name='email'
						onChange={handleChange}
						value={user.email}
						placeholder='Email'/>
				</div>
				<div className='sign-form_pass'>
					<i className="fa-solid fa-lock"></i>
					<input 
						disabled={query.waiting}
						type='password'
						name='password'
						onChange={handleChange}
						value={user.password}
						placeholder='Password' />
				</div>
			</form>
			<div 
				onClick={e=>sendUser()}
				className='sigin-button'>
				{!isLogin?'Sign Up':'Sign In'}
			</div>
			<div 
				onClick={e=>setIsLogin(prev=>!prev)}
				className='regis-button'>
				{isLogin?'Login':'Register'}
			</div>
			<div
				title={query.info} 
				className='login-menu_info'>
				<p>{query.info}</p>
			</div>
			<i 
				style={{
					display: 
					`${query.success||query.waiting?'':'none'}`
				}}
				className="fa-solid fa-spinner"></i>
		</div>

	)
}