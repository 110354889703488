
import styled from 'styled-components'

export const StyledTributarios = styled.div`
	
	gap: 5rem;
	margin: auto;
	display: grid;
	padding: 7rem 0rem;
	width: min(calc(100% - 1rem), 1200px);

	& h1:last-child {
		font-weight: 600;
		color: ${({theme})=>theme.text.color.scheme4}
	}
	
`