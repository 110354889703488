// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

footer {
	gap: 5rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
	justify-content: center;
	padding: 7rem 5rem;
	background: #262121;
	color: #fff;
	min-width: 100vw;
}

footer div {
	display: grid;
	grid-gap: 0.5rem;
	grid-auto-rows: max-content;
}

footer div h3 {
	font-weight: 500;
	margin-bottom: 1rem;
	letter-spacing: 0.3rem;
}
footer div a {
	font-weight: 300;
}
footer div a.is-active {
	color: inherit;
}
footer div a:hover {
	color: #0096A3;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":";;AAEA;CACC,SAAS;CACT,aAAa;CACb,mEAAmE;CACnE,uBAAuB;CACvB,kBAAkB;CAClB,mBAAmB;CACnB,WAAW;CACX,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,gBAAgB;CAChB,2BAA2B;AAC5B;;AAEA;CACC,gBAAgB;CAChB,mBAAmB;CACnB,sBAAsB;AACvB;AACA;CACC,gBAAgB;AACjB;AACA;CACC,cAAc;AACf;AACA;CACC,cAAc;AACf","sourcesContent":["\r\n\r\nfooter {\r\n\tgap: 5rem;\r\n\tdisplay: grid;\r\n\tgrid-template-columns: repeat(auto-fit, minmax(200px, max-content));\r\n\tjustify-content: center;\r\n\tpadding: 7rem 5rem;\r\n\tbackground: #262121;\r\n\tcolor: #fff;\r\n\tmin-width: 100vw;\r\n}\r\n\r\nfooter div {\r\n\tdisplay: grid;\r\n\tgrid-gap: 0.5rem;\r\n\tgrid-auto-rows: max-content;\r\n}\r\n\r\nfooter div h3 {\r\n\tfont-weight: 500;\r\n\tmargin-bottom: 1rem;\r\n\tletter-spacing: 0.3rem;\r\n}\r\nfooter div a {\r\n\tfont-weight: 300;\r\n}\r\nfooter div a.is-active {\r\n\tcolor: inherit;\r\n}\r\nfooter div a:hover {\r\n\tcolor: #0096A3;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
