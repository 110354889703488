
import Globe from './Globe'
import { 
	NoToneMapping, 
	LinearEncoding 
} from 'three'
import * as THREE from 'three'
import styled from 'styled-components'
import { Canvas } from '@react-three/fiber'
import { useLocation } from 'react-router-dom'

import { useState, Suspense } from 'react'

import map from './map.json'

export default function Scene() {

	const path = useLocation().pathname
	const [ hoverOn, setHoverOn ] = useState(null)
	const [ showGlobe, setShowGlobe ] = useState(false)

	return (
		<StyledScene 
			style={{
				display: path.includes('clientes')?'block':'none'
			}}
			className={`webgl ${showGlobe?'show':'hide'}`}>
			{map.maps.map((item,idx)=>
				<div 
					key={idx}
					className="label"
					onMouseEnter={e=>setHoverOn(idx)}
					onMouseLeave={e=>setHoverOn(null)}
				>
					<div>{item.city}</div>
				</div>
			)}
			<Canvas 
				orthographic
				gl={{
					toneMapping: NoToneMapping,
					outputEncoding: LinearEncoding
				}}
				camera={{
					far: 1000,
					near: 0.01,
					position: [
						0,
						0,
						150
					],
					zoom: 2.85
				}}
			>
				<Suspense>
				<Globe 
					hoverOn={hoverOn} 
					onLoad={e=>setShowGlobe(true)}
				/>
				</Suspense>
			</Canvas>
		</StyledScene>		
	)
}

const StyledScene = styled.div`

    left: 0;
    opacity: 0;
    z-index: 1;
    top: 22rem;
    margin: auto;
    display: grid;		
    outline: none;
    margin-right: 0;
    padding: 5rem 0;
    --chevSize: 10px;
    width: calc(100%);
    min-height: 600px;
    position: absolute;
	overflow-x: hidden;
	transition: opacity 1s;

	& canvas {
		aspect-ratio: 1;
		min-height: 600px;
	}

	&.show  {
		opacity: 1;
	}

	.label {
		inset: 0;
		z-index: 1;
		margin: auto;
    	--dotSize: 1rem;
		user-select: none;
		border-radius: 50%;
		position: absolute;
		width: var(--dotSize);
		height: var(--dotSize);
		transition: opacity 1s;
		background: ${({theme})=>theme.text.color.scheme4};
	}	

	.label:hover div {
		opacity: 1
	}

	.label div {
		left: 0;
		right: 0;
		opacity: 0;
		z-index: 2;
		margin: auto;
		padding: 1.2rem;
		background: #fff;
		font-size: 1.2rem;
		position: absolute;
		width: max-content;
		transition: inherit;
		height: min-content;
		pointer-events: none;
		white-space: pre-wrap;
		border-radius: 0.75rem;
		bottom: calc(100% + 1rem);
		box-shadow: 0 4px 10px 4px #0004;
		transform: translateX(calc(-50% + 0.5rem));
	}

	.label div:before {
		left: 0;
		right: 0;
		content: '';
		margin: auto;
		position: absolute;
		width: min-content;
		border: var(--chevSize) solid transparent;
		border-top: var(--chevSize) solid #fff;
		bottom: calc(var(--chevSize) * -2 + 1px);
	}

`