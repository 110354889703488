// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `	

.team-div {
	gap: 1rem;
	width: 100%;
	display: grid;	
	padding-top: 2rem;
	position: relative;
	grid-template-columns: repeat(auto-fill, minmax(250px, max-content));	
}


.team-div .info {
	width: 100%;
	color: salmon;
	position: absolute;
	text-align: center;
	top: calc(80px + 1rem);
}

.team-div > i {
	z-index: 3;
	width: 2rem;
	right: 2rem;
	top: -1.5rem;
	height: 2rem;
	display: grid;
	cursor: pointer;
	position: absolute;
	border-radius: 50%;
	place-content: center;
	color: #fff;
	background: #0096A3;
}

.team-div > i:hover {
	opacity: 0.6;
	background: #0096A3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Team.css"],"names":[],"mappings":";;AAEA;CACC,SAAS;CACT,WAAW;CACX,aAAa;CACb,iBAAiB;CACjB,kBAAkB;CAClB,oEAAoE;AACrE;;;AAGA;CACC,WAAW;CACX,aAAa;CACb,kBAAkB;CAClB,kBAAkB;CAClB,sBAAsB;AACvB;;AAEA;CACC,UAAU;CACV,WAAW;CACX,WAAW;CACX,YAAY;CACZ,YAAY;CACZ,aAAa;CACb,eAAe;CACf,kBAAkB;CAClB,kBAAkB;CAClB,qBAAqB;CACrB,WAAW;CACX,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,mBAAmB;AACpB","sourcesContent":["\t\r\n\r\n.team-div {\r\n\tgap: 1rem;\r\n\twidth: 100%;\r\n\tdisplay: grid;\t\r\n\tpadding-top: 2rem;\r\n\tposition: relative;\r\n\tgrid-template-columns: repeat(auto-fill, minmax(250px, max-content));\t\r\n}\r\n\r\n\r\n.team-div .info {\r\n\twidth: 100%;\r\n\tcolor: salmon;\r\n\tposition: absolute;\r\n\ttext-align: center;\r\n\ttop: calc(80px + 1rem);\r\n}\r\n\r\n.team-div > i {\r\n\tz-index: 3;\r\n\twidth: 2rem;\r\n\tright: 2rem;\r\n\ttop: -1.5rem;\r\n\theight: 2rem;\r\n\tdisplay: grid;\r\n\tcursor: pointer;\r\n\tposition: absolute;\r\n\tborder-radius: 50%;\r\n\tplace-content: center;\r\n\tcolor: #fff;\r\n\tbackground: #0096A3;\r\n}\r\n\r\n.team-div > i:hover {\r\n\topacity: 0.6;\r\n\tbackground: #0096A3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
