
import styled from 'styled-components'

export const StyledAuditoria = styled.div`
	
	gap: 5rem;
	margin: auto;
	display: grid;
	padding: 7rem 0rem;
	width: min(calc(100% - 1rem), 1200px);

	& h3 {
		font-weight: 400;
		line-height: 2.5rem;
	}

	@media screen and (max-width: 900px) {

		& h3 {
			width: 100%;
			font-weight: 400;
			line-height: 1.5rem;
		}

	}

`