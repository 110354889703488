
// Components
import NavLink from './NavLink'
import Fade from 'react-reveal/Fade'

// Thirdparty
import styled from 'styled-components'

export default function SectionCard(props) {
	return (
		<StyledSectionCard className='section-card' $pos={props.pos}>
			<Fade>
			<img src={props.img} />
			<h2>{props.title}</h2>
			<small>{props.content}</small> 
			<NavLink 
				style={{
					display: props.link.url?'auto':'none'
				}}
				to={props.link.url}
			>
				{props.link.label}&emsp;
				<i className="fa-solid fa-arrow-right-long"></i>
			</NavLink>
			</Fade>
		</StyledSectionCard>

	)
}

const StyledSectionCard = styled.div`

	margin: auto;
	margin-bottom: 3rem;
	width: min(calc(100% - 100px), 1200px);

	& img {
		width: 55%;
		object-fit: cover;
		border-radius: 24px;
		float: ${({ $pos })=> $pos?'right':'left'};
		margin: ${({ $pos })=> $pos?'0 0 1rem 2rem':'0 2rem 1rem 0'};
	}

	& * {
		margin-bottom: 1rem;
	}

	& small {
		white-space: pre-line;
	}

	& a {	
		display: block;
		font-weight: 700;
		padding-top: 2rem;
		width: max-content;
		margin-left: ${({ $pos })=> $pos?0:'auto'};
		margin-right: ${({ $pos })=> $pos?'auto':0};
		color: ${({theme}) => theme.text.color.scheme4};
	}

	& a:hover {
		color: ${({theme}) => theme.text.color.scheme3}
	}	

	@media screen and (max-width: 1050px) {

		& img {
			width: 50%;
		}

	}

	@media screen and (max-width: 950px) {
	
		height: max-content;

		& img {
			width: 100%;
		}

	}

`


