
import styled from 'styled-components'

export const StyledHoverGrid = styled.div`

	margin: auto;
	display: grid;
	margin-top: -3rem;
	position: relative;
	width: min(calc(100% - 1rem), 1200px);
	grid-template-columns: repeat(3, 1fr);

	&::before {
		inset: 0;
		content: '';
		background: #0008;
		position: absolute;
	}

	&::after {
		content: '';
		inset: 2rem 2rem;
		position: absolute;
		background-image: linear-gradient(to right, #0000, #0000 calc(33.3% - 0.75px - 0.67rem), #fff8 calc(33.3% - 0.67rem), #0000 calc(33.3% + 0.75px - 0.67rem), #0000 calc(66.6% - 0.75px + 0.67rem), #fff8 calc(66.6%  + 0.67rem), #0000 calc(66.6% + 0.75px + 0.67rem)), linear-gradient(to bottom, #0000, #0000 calc(50% - 1px), #fff8 50%, #0000 calc(50% + 1px));	
	}

	& > img {
		z-index: -1;
		height: 100%;
		object-fit: cover;
		position: absolute;
	}

	& > div {
		width: 100%;
		position: relative
	}

	& .content-div {
		gap: 10px;
		z-index: 1;
		opacity: 0;
		width: 100%;
		display: grid;
		aspect-ratio: 1;
		font-weight: 300;
		background: #0005;
		position: relative;
		padding: 20px 40px;
		align-content: center;
		transition: opacity 0.5s;
		--title: var(--label);
		color: ${({theme})=>theme.text.color.scheme1};
	}

	& > div small {
		font-size: 0.7rem;
		line-height: 1rem;
	}

	& > div a {
		display: flex;
		font-size: 0.8rem;
		width: max-content;
		align-items: center;
	}

	& .content-div a {
		pointer-events: none;		
	}

	& > div a i {
		font-size: 1.5rem;
		color: ${({theme})=>theme.text.color.scheme4};
	}


	& .content-div:hover {
		opacity: 1;
	}

	& .content-div:hover + div {
		opacity: 0;
	}

	& .content-div:hover a {
		animation-name: showlink;
		animation-duration: 0.5s;
		animation-fill-mode: forwards;
	}

	@keyframes showlink {

		0% {
			pointer-events: none;
		}

		100% {
			pointer-events: auto;
		}

	}

	& .title-div {
		left: 2rem;
		z-index: 2;
		bottom: 3rem;
		font-size: 1.5rem;
		position: absolute;
		width: max-content;
		height: max-content;
		transition: opacity 0.5s;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	@media screen and (max-width: 749px) {

		grid-template-columns: repeat(2, 1fr);

		&::after {
			background-image: linear-gradient(to bottom, #0000, #0000 calc(33.3% - 0.75px - 0.67rem), #fff8 calc(33.3% - 0.67rem), #0000 calc(33.3% + 0.75px - 0.67rem), #0000 calc(66.6% - 0.75px + 0.67rem), #fff8 calc(66.6%  + 0.67rem), #0000 calc(66.6% + 0.75px + 0.67rem)), linear-gradient(to right, #0000, #0000 calc(50% - 1px), #fff8 50%, #0000 calc(50% + 1px));	
		}	

		& .content-div { 
			padding: 15px 30px
		}

	}

	@media screen and (max-width: 450px) {

		& .content-div{ 
			padding: 10px 15px
		}

		& > div small {

			display: none;
		}

		& .title-div a {
			width: min-content;
		}

	}

`