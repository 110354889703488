

import styled from 'styled-components'

export const StyledHeader = styled.header`
	
	z-index: 2;
	display: grid;
	min-width: 100%;
	position: absolute;
	padding: 2rem 4rem;
	align-items: center;
	justify-items: space-between;
	justify-content: space-between;
	grid-template-columns: 1fr min-content min-content;
	color: ${({theme}) => theme.text.color.scheme1};


	nav {
		gap: 40px;
		display:  flex;
		align-items: center;
	}

	nav div {
		gap: 5px;
		z-index: 20
		display: flex;
		cursor: pointer;
		width: max-content;
		position: relative;
		align-items: center;
		list-style-type: none;
	}

	& .logo-black {
		display: none;
	}

	& > img {
		width: auto;
		height: 100%;
		cursor: pointer;
		max-height: 25px;
	}

	& > i {
		display: none;
		font-size: 2rem;
		cursor: pointer;
	}

	& > i:hover {
		color: ${({theme}) => theme.text.color.scheme4};
	}

	@media screen and (max-width: 1100px) {

		padding: 2rem 2rem;

		nav {
			gap: 15px
		}

	}

	@media screen and (max-width: 875px) {

		nav {
			right: 2rem;
			display: grid;
			background: #fff;
			position: absolute;
			width: min(70%, 250px);
			// width: min-content;
			top: calc(100% - 2rem);
			padding: 1rem 1rem 1.2rem;
		}

		nav a,
		nav li {
			display: block;
			color: ${({theme})=>theme.text.color.scheme3}
		}

		nav > a:hover,
		nav > li:hover {
			color: ${({theme})=>theme.text.color.scheme4}
		}

		nav.hide {
			display: none
		}

		& > i {
			display: block
		}
	}

`
