
import styled from 'styled-components'

export const StyledClients = styled.div`

	background: #F6F6F6;
	padding: 0rem 0 8rem;
	
	.clients-scene {
	    top: 0;
	    left: 0;
	    width: 100%;
	    margin: auto;
	    display: grid;		
	    outline: none;
	    margin-right: 0;
	    padding: 5rem 0;
	    position: relative;
	    min-height: calc(600px + 10rem);
	}

	.clients-info {
		left: 0;
		z-index: 10;
		bottom: 5rem;
		margin: auto;
		user-select: none;
		position: absolute;
		right:  min(300px, 40%);
		max-width: min(500px, 50%);
	}

	.clients-info h1 {
		font-weight: 600;
		font-size: 2.8125;
	}

	.clients-info p {
		margin-top: 1.5rem;
		color: ${({theme})=>theme.text.color.scheme3}
	}

	.clients-testimonials {
	    margin: auto;
	    padding: 4rem 0;
	    text-align: center;
	    width: min(1200px, calc(100% - 4rem));
	}

	.clients-testimonials h1 {
		margin-bottom: 5rem;
	}

	.clients-testimonials small {
		letter-spacing: 0.3rem;
	}

	.clients-cases {
		gap: 2rem;
		margin: auto;
		display: grid;
		padding: 3rem 0;
		border-top: 1px solid #D2D0D0;
		max-width: min(1200px, calc(100% - 4rem));
	}

	.clients-cases h4 {
		letter-spacing: 0.2rem;
		color: ${({theme})=>theme.text.color.scheme4}
	}

	.clients-cases > div {
		display: grid;
		text-align: center;
		justify-content: space-between;
		grid-template-columns: repeat(3, 1fr);
		gap: 4rem;
	}

	.clients-cases > div > a {
		font-weight: 700;
		list-style-type: none;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	.clients-cases > div > a:hover {
		color: ${({theme})=>theme.text.color.scheme4};
	}

	@media screen and (max-width: 750px) {
		.clients-info {
			left: 4rem;
			max-width: min(500px, calc(100% - 8rem));
		}
	}

	@media screen and (max-width: 400px) {

		.clients-cases h4 {
			text-align: center
		}

		.clients-cases > div  {
			display: grid;
			text-align: center
		}	

	}

`