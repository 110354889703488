
import styled from 'styled-components'

export const StyledUsers = styled.div`

	gap: 1rem;
	width: 100%;
	display: grid;

	& .info {
		width: 100%;
		color: salmon;
		position: absolute;
		text-align: center;
		top: calc(80px + 1rem);
	}

	& .head {
		gap: 1rem;
		width: 100%;
		height: 50px;
		display: flex;
		display: grid;
		align-items: center;
		padding: 0.5rem 1rem;
		transition: box-shadow 0.5s;
		justify-content: space-between;
		box-shadow: 4px 4px 10px 0px #0002;
		grid-template-columns: 1fr 60px min-content;
		color: ${({theme})=>theme.text.color.scheme1};
		background: ${({theme})=>theme.text.color.scheme4};
	}

`