

import { 
	HeadText, 
	CustomList,
	ImageBanner
} from '../components/ServicesComponents'

// Styles
import { StyledTributarios } from './styles/Tributarios.styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'


export default function Tributarios() {

	const { lang, servs } = useLanguageContext()

	return (
		<StyledTributarios>
			<HeadText {...servs.tributarios} />
			<ImageBanner {...servs.tributarios.img} />
			<CustomList {...servs.tributarios.list} />
			<CustomList {...servs.tributarios.list2} />
		</StyledTributarios>
	)
}

