import styled from 'styled-components'

export const StyledAboutUsHero = styled.div`
	
	width: 100%;
	position: relative;
	z-index: 1;
	color: ${({theme}) => theme.text.color.scheme1};

	&::before {
		inset: 0;
		content: '';
		background: linear-gradient(to top, #262121 0%, #262121 50%, #26212188 100%);
		position: absolute;
		z-index: 1
	}

	.contact-hero_head {
		display: grid;
		grid-gap: 0.5rem;
		height: min-content;
		// padding: 13rem 4rem 4rem;
		top: min(300px, calc(44.59% * 0.5));
		// bottom: 0;	
		width: 100%;
		margin: auto;
		text-align: center;
		// position: relative;
		z-index: 1;
		position: absolute
	}


	.contact-hero_head--title {
		font-weight: 500;
		line-height: 3rem;
		font-size: 3.125rem;
		font-family: 'Jost', sans-serif;
		color: ${({theme}) => theme.text.color.scheme1}
	}

	.contact-hero_head--route {
		display: flex;
		gap: 1rem;
		align-items: center;
		font-weight: 100;
		justify-content: center;
		font-family: 'Nunito', sans-serif;
		color: ${({theme}) => theme.text.color.scheme1};
	}


	.contact-hero_head--text {
		font-weight: 300;
		white-space: pre-wrap;
	}

	.contact-hero_body {
		display: grid;
		gap: 2rem;
		position: relative;
		z-index: 1;
		// margin-left: max(4rem, 25%);
		padding: 0 4rem;
		padding-top: min(calc(44.59% + 4rem), calc(600px + 4rem));
		padding-bottom: 4rem;
		max-width: min(1400px, calc(100%));
		// background: red;
		margin: auto;
		// width: 100%;
	}

	.contact-hero_body--title {
		font-weight: 700;
		color: ${({theme}) => theme.text.color.scheme4};
	}

	.contact-hero_body--text {
		white-space: pre-wrap;
		max-width: min(800px, calc(100%));
	}

	& > img {
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%; 
		max-height: 800px;
		// min-height: 800px;
		object-fit: contain;
		object-position: top;
		position: absolute;
	}

	@media screen and (max-width: 1200px) {
		&::before {
			background: linear-gradient(to top, #262121 0%, #262121 60%, #26212188 100%);
		}
	}

	@media screen and (max-width: 800px) {
		&::before {
			background: linear-gradient(to top, #262121 0%, #262121 65%, #26212188 100%);
		}
		.contact-hero_body {
			padding: 0 2rem;
			padding-top: 30rem;
			padding-bottom: 4rem;
		}	
	}

	@media screen and (max-width: 500px) {
		&::before {
			background: linear-gradient(to top, #262121 0%, #262121 30%, #26212188 100%);
		}
	}

	@media screen and ((min-width: 1800px)  or (max-width: 500px)) {

		& > img {
			object-fit: cover;
			object-position: bottom;
		}

	}
`