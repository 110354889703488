
const servicesES = {
	'niif': {
		title : 'Contabilidad y NIIF',
		sub   : 'Estrategias adaptadas a información financiera',
		text  : 'En NDR, creamos procesos contables personalizados y adaptados a las necesidades únicas de cada empresa. Nuestro modelo se constituye desde el conocimiento profundo de los requerimientos de nuestros clientes, lo que nos permite entender y adaptar nuestros procesos contables y financieros  para apoyarlos en el manejo de sus operaciones.\n\nNuestra asesoría se basa en las últimas tendencias y generamos informes de alto valor agregado para guiar decisiones estratégicas. Somos un aliado contable comprometido con el éxito empresarial.',
		img: {
			url   : '../images/services/services-niif-img.webp',
			pos   : ['none', '4rem', '4rem', 'none'],
			txt   : '',
			list  : [],
			title : 'Auditoria del proceso de implementación de NIIF',
			grad  : '',
			align : 'right',
			grdstp: '%',
			grdend: '100%',			
			aspect: '54.38%',
		},
		list  : {
			title: '',
			ncols: 2,
			marker: true,
			text : '',
			items: [
			]
		},
		procs : [
			{
				label : 'Consultoría contable',
				img   : '../images/icons/money.svg'
			},
			{
				label : 'Outsourcing contable',
				img   : '../images/icons/outsource.svg'
			},
			{
				label : 'Consultoría en NIIF',
				img   : '../images/icons/niif.svg'
			},
			{
				label : 'Reportes a entidades de vigilancia y control',
				img   : '../images/icons/reports.svg'
			}
		]
	},
	'tributarios': {
		title : 'Tributarios',
		sub   : '',
		text  : 'Estamos comprometidos con brindar un respaldo sólido en el proceso tributario para nuestros clientes; desde la planificación y preparación de declaraciones, el análisis de opciones y alternativas para un cierre de año eficiente, y complementamos el esquema tributario de las compañías con el de sus socios como personas naturales. Nuestra metodología se enfoca en minimizar riesgos de sanciones o multas, y busca la maximización de beneficios simultáneamente. Garantizamos una gestión tributaria efectiva y un cumplimiento óptimo de las responsabilidades fiscales, para impulsar el crecimiento y éxito financiero de nuestros clientes.',
		img: {
			url   : '../images/services/services-tributarios-img.webp',
			pos   : [],
			txt   : '',
			list  : [],
			title : '',
			grad  : '',
			align : '',
			grdstp: '%',
			grdend: '%',			
			aspect: '52.57%',
		},
		list  : {
			title: '¿Qué puede hacer NDR por ti?',
			ncols: 1,
			marker: true,
			text : '',
			items: [
				'Consultoría y asesoría en asuntos de cumplimiento tributario',
				'Preparación y presentación de todas las declaraciones de impuestos municipales y nacionales',
				'Preparación de reportes ante las entidades de vigilancia y control fiscal',
				'Trámites de devolución y/o compensación de saldos a favor',
				'Servicios tributarios para personas naturales',
				'Planeación fiscal'
			]
		},
		list2 : {
			title: '',
			ncols: 3,
			marker: false,
			text : '',
			items: [
			]
		}
	},
	'laborales': {
		title : 'Laborales',
		sub   : '',
		text  : 'Acompañamos a nuestros clientes en todo el proceso de incorporación laboral que requieran para el desarrollo de sus operaciones en Colombia; desde las afiliaciones, liquidación de nóminas y planillas mensuales, hasta la liquidación de contratos. Nos caracterizamos por hacer la debida diligencia de los procesos laborales, minimizando los riesgos laborales que puedan presentar nuestros clientes.',
		img: {
			url   : '../images/services/services-laborales-img.webp',
			pos   : ['4rem', 'none', '4rem', '4rem'],
			txt   : '“Apoyamos nuestra operación con un sistema que nos permite cumplir con las promesas hechas a nuestros clientes”',
			list  : [],
			grad  : '#0096A3',
			title : '',
			align : 'left',
			grdstp: '40%',
			grdend: '100%',			
			aspect: '48.54%',
		},
		list  : {
			title: '¿Por qué NDR?',
			ncols: 1,
			marker: true,
			text : 'Apoyamos nuestra operación con un sistema que nos permite cumplir con las promesas hechas a nuestros clientes, apalancándonos con procesos de auditoria interna que supervisa que se cumplan los pilares y valores que prometemos ',
			items: [
				'Consultoría y asesoría en asuntos laborales',
				'Afiliación empresarial ante las Empresas Promotoras de Salud (EPS), Fondos de Pensión Obligatoria, Riesgos Profesionales (ARP) y Caja de Compensación Familiar.',
				'Afiliaciones a cada una de las entidades de seguridad social, por cada uno de los empleados y su grupo familiar.',
				'Liquidación y verificación de la conveniencia de los procedimientos de Retención en la Fuente para cada uno de los empleados.',
				'Liquidación de la nómina mensual o quincenal, de acuerdo con la política de pagos de la compañía.',
				'Liquidación mensual de aportes a la seguridad social y parafiscales a través de la Planilla Integrada de Liquidación de Aportes PILA.',
				'Liquidación por retiro de empleados.'
			]
		}
	},
	'cambiarios': {
		title : 'Cambiarios y de importaciones',
		sub   : '',
		text  : 'Ofrecemos un sólido respaldo a nuestros clientes, abordando con profesionalismo y dedicación diversas áreas clave como la inversión extranjera, los procesos intrincados de importación y exportación, así como los complejos procedimientos de endeudamiento externo.\n\nEntendemos que cada cliente tiene necesidades únicas, y es por ello que nos comprometemos a brindar un servicio constante y adaptado, asegurándonos de que cada paso del camino esté marcado por la atención detallada a las necesidades particulares de cada uno. Mantenemos una asociación a largo plazo enfocada en el éxito y la prosperidad de tu empresa en el ámbito internacional.',
		img: {
			url   : '../images/services/services-cambiarios-img.webp',
			pos   : ['none', '4rem', '4rem', 'none'],
			txt   : '',
			list  : [],
			title : '',
			grad  : '',
			align : 'right',
			grdstp: '%',
			grdend: '%',			
			aspect: '58.13%',
		},
		list  : {
			title: '',
			ncols: 1,
			marker: true,
			text : '',
			items: []
		},
		list2 : [
			{
				title: 'Trámites de importaciones y exportaciones',
				text : 'Cumplimos con las normativas aduaneras, gestionamos aranceles y simplificamos la documentación para asegurar transacciones internacionales sin complicaciones.',
				img  : '../images/icons/world.svg'
			},
			{
				title: 'Inversión extranjera',
				text : 'Cumplimiento riguroso de requisitos legales y una gestión eficiente de la documentación. Enfoque técnico para garantizar ejecución eficaz y facilitar la entrada de inversiones internacionales.',
				img  : '../images/icons/card.svg'
			},
			{
				title: 'Consultoría en comercio exterior',
				text : 'Reporte de pagos a proveedores del exterior o en su defecto registro de endeudamiento externo ante el Banco de la República.',
				img  : '../images/icons/money.svg'
			},
			{
				title: 'Endeudamiento externo',
				text : 'Asesoramiento en el endeudamiento externo en Colombia mediante un enfoque técnico riguroso, asegurando el cumplimiento normativo y una gestión documental eficiente para un proceso seguro y conforme con regulaciones.',
				img  : '../images/icons/debt.svg'
			}
		]
	},
	'auditoria': {
		title : 'Auditoría y aseguramiento',
		sub   : '',
		text  : 'Enfocamos nuestros servicios de Auditoría y Revisoría Fiscal desde principio de independencia, contribuyendo con nuestros clientes en la identificación preventiva y proactiva de diferentes controles en sus procesos y operación: realizando procesos de verificación para el cumplimiento de disposiciones legales, cumplimiento normativo interno y externo; analizando permanentemente los aspectos que permitan identificar el grado de eficiencia y eficacia en el logro de los objetivos de la Organización y opinando sobre si sus estados financieros están libres de errores, identificando impacto de riesgo en la operación.',
		img: {
			url   : '../images/services/services-auditoria-img.webp',
			pos   : ['4rem', 'none', 'none', '4rem'],
			txt   : '',
			list  : [
			'Revisoría Fiscal',
			'Auditoría financiera',
			'Auditoría de cumplimiento',
			'Auditoría de gestión',
			'Auditoría de control interno',
			],
			title : 'Conoce nuestros servicios de auditoría y aseguramiento',
			grad  : '#262121dd',
			align : 'left',
			grdstp: '40%',
			grdend: '100%',			
			aspect: '48.61%',
		},
		list  : {
			title: '',
			ncols: 1,
			marker: true,
			text : '',
			items: []
		},
	},
	'backoffice': {
		title : 'Back Office',
		sub   : '',
		text  : 'Generamos soluciones a la medida para cada organización desde la gestión administrativa en temas claves tales como tesorería, facturación electrónica, control de cartera, nómina y trámite y custodia documental.\nBuscamos ser un aliado estratégico para apoyar efectivamente a las compañías en garantizar el desarrollo normal y eficiente de su operación.',
		img: {
			url   : '../images/services/services-backoffice-img.webp',
			pos   : [],
			txt   : '',
			list  : [],
			title : '',
			grad  : '',
			align : '',
			grdstp: '%',
			grdend: '%',			
			aspect: '48.61%',
		},
		list  : {
			title: '¿Cómo podemos ayudarte? ',
			ncols: 3,
			marker: false,
			text : '',
			items: [
				'<span>Control de cartera</span>',
				'<span>Recursos humanos</span>',
				'<span>Trámite y custodia documental</span>',
				'<span>Facturación electrónica</span>',
				'<span>Tesorería</span>'
			]
		},
	}
}

const servicesEN = {
	'niif': {
		title : 'Accounting & IFRS',
		sub   : 'Strategies adapted to financial information',
		text  : "At NDR, we create customized accounting processes tailored to the unique needs of each company. Our model is built upon a deep understanding of our clients' requirements, allowing us to comprehend and adapt our accounting and financial processes to support them in managing their operations.\n\nOur guidance is based on the latest trends, and we generate high-value reports to steer strategic decisions. We are a dedicated accounting partner committed to business success.",
		img: {
			url   : '../images/services/services-niif-img.webp',
			pos   : ['none', '4rem', '4rem', 'none'],
			txt   : '',
			list  : [],
			title : 'Audit of the IFRS implementation process',
			grad  : '',
			align : 'right',
			grdstp: '%',
			grdend: '100%',			
			aspect: '54.38%',
		},
		list  : {
			title: '',
			ncols: 2,
			marker: true,
			text : 'We have Specialists in International Financial Accounting, who will carry out the verification and convergence processes to International Financial Reporting Standards (IFRS – IFRS) in accordance with the standards and schedules issued by the National Government. Based on what has already been mentioned, we structured the service proposal emphasizing a Post Implementation phase as described below:',
			items: [
			]
		},
		procs : [
			{
				label : 'Accounting Consultancy',
				img   : '../images/icons/money.svg'
			},
			{
				label : 'Accounting Outsourcing',
				img   : '../images/icons/outsource.svg'				
			},
			{
				label : 'IFRS Consultancy',
				img   : '../images/icons/niif.svg'				
			},
			{
				label : 'Reports to surveillance and control entities',
				img   : '../images/icons/reports.svg'				
			}
		]
	},
	'tributarios': {
		title : 'Taxation',
		sub   : 'Strategies adapted to financial information',
		text  : 'We are committed to providing strong support in the tax process for our clients; from planning and preparing declarations, analyzing options and alternatives for efficient year-end closing, to complementing the corporate tax scheme with that of the partners as individuals.\n\nOur methodology focuses on minimizing the risks of penalties or fines while simultaneously seeking to maximize benefits. We ensure effective tax management and optimal compliance with fiscal responsibilities to drive the growth and financial success of our clients.',
		img: {
			url   : '../images/services/services-tributarios-img.webp',
			pos   : [],
			txt   : '',
			list  : [],
			title : '',
			grad  : '',
			align : '',
			grdstp: '%',
			grdend: '%',			
			aspect: '52.57%',
		},
		list  : {
			title: '¿What can NDR do for you?',
			ncols: 1,
			marker: true,
			text : '',
			items: [
				'Tax Compliance Consultancy and Advisory',
				'Preparation and filing of all municipal and national tax declarations',
				'Preparation of reports for oversight and fiscal control entities',
				'Refund and/or offsetting of favorable balances procedures',
				'Tax services for individuals',
				'Tax planning'
			]
		},
		list2 : {
			title: '',
			ncols: 3,
			marker: false,
			text : '',
			items: [
			]
		}
	},
	'laborales': {
		title : 'Labor',
		sub   : '',
		text  : 'We guide our clients through the entire process of labor incorporation necessary for the development of their operations in Colombia; from affiliations, payroll processing, and monthly forms, to contract terminations.\nWe are known for exercising due diligence in labor processes, minimizing the labor risks our clients might face.',
		img: {
			url   : '../images/services/services-laborales-img.webp',
			pos   : ['4rem', 'none', '4rem', '4rem'],
			txt   : '“We support our operation with a system that allows us to fulfill the promises made to our clients”',
			list  : [],
			grad  : '#0096A3',
			title : '',
			align : 'left',
			grdstp: '40%',
			grdend: '100%',			
			aspect: '48.54%',
		},
		list  : {
			title: '¿Why NDR?',
			ncols: 1,
			marker: true,
			text : 'We support our operation with a system that allows us to fulfill the promises made to our clients, leveraging ourselves with internal audit processes that supervise that the pillars and values that we promise are fulfilled.',
			items: [
				'Labor Consultancy and Advisory',
				'Corporate affiliation with Health Promotion Companies (EPS), Mandatory Pension Funds, Professional Risks (ARP), and Family Compensation Funds',
				'Affiliations for each employee and their family members to each social security entity',
				'Calculation and assessment of the appropriateness of Withholding Tax procedures for each employee',
				"Monthly or bi-weekly payroll processing according to the company's payment policy",
				'Monthly calculation of social security contributions and parafiscal payments through the Integrated Payment Statement for Social Security Contributions (PILA)',
				'Employee termination settlement'
			]
		}
	},
	'cambiarios': {
		title : 'Exchange and export',
		sub   : '',
		text  : "We provide support to our clients in foreign investment matters, import and export processes, and external borrowing procedures. We offer continuous and personalized guidance with specialists in each of these fields for our clients.",
		img: {
			url   : '../images/services/services-cambiarios-img.webp',
			pos   : ['none', '4rem', '4rem', 'none'],
			txt   : '',
			list  : [],
			title : 'Audit of the NIIF implementation process',
			grad  : '',
			align : 'right',
			grdstp: '%',
			grdend: '%',			
			aspect: '58.13%',
		},
		list  : {
			title: '',
			ncols: 1,
			marker: true,
			text : '',
			items: []
		},
		list2 : [
			{
				title: 'Import and export procedures',
				text : 'We comply with customs regulations, manage tariffs, and simplify documentation to ensure smooth international transactions.',
				img  : '../images/icons/world.svg'
			},
			{
				title: 'Foreign investment',
				text : 'Rigorous compliance with legal requirements and efficient management of documentation. Technical focus to ensure effective execution and facilitate the entry of international investments.',
				img  : '../images/icons/card.svg'
			},
			{
				title: 'Foreign trade consulting',
				text : 'Report of payments to foreign suppliers or, failing that, registration of external debt before the Bank of the Republic.',
				img  : '../images/icons/money.svg'
			},
			{
				title: 'External debt',
				text : ' Advisory on external borrowing in Colombia through a rigorous technical approach, ensuring regulatory compliance and efficient document management for a secure process in accordance with regulations.',
				img  : '../images/icons/debt.svg'
			}
		]
	},
	'auditoria': {
		title : 'Audit and assurance',
		sub   : '',
		text  : "We focus our Audit and Fiscal Review services from the beginning of independence, contributing to our clients in the preventive and proactive identification of various controls in their processes and operation: carrying out verification processes for compliance with legal provisions, internal and external regulatory compliance; continuously analyzing aspects that allow identifying the degree of efficiency and effectiveness in achieving the Organization's objectives and providing an opinion on whether their financial statements are free from errors, identifying the impact of risk on operations.",
		img: {
			url   : '../images/services/services-auditoria-img.webp',
			pos   : ['4rem', 'none', 'none', '4rem'],
			txt   : '',
			list  : [
			'Tax inspection',
			'Financial audit',
			'Compliance audit',
			'Management audit',
			'Internal control audit'
			],
			title : 'Learn about our audit and assurance services',
			grad  : '#262121dd',
			align : 'left',
			grdstp: '40%',
			grdend: '100%',			
			aspect: '48.61%',
		},
		list  : {
			title: '',
			ncols: 1,
			marker: true,
			text : '',
			items: []
		},
	},
	'backoffice': {
		title : 'Back Office',
		sub   : '',
		text  : 'We generate tailored solutions for each organization, addressing key administrative management areas such as treasury, electronic invoicing, accounts receivable management, payroll, and document processing and custody.\n\nWe aim to be a strategic ally to effectively support companies in ensuring the smooth and efficient development of their operations.',
		img: {
			url   : '../images/services/services-backoffice-img.webp',
			pos   : [],
			txt   : '',
			list  : [],
			title : '',
			grad  : '',
			align : '',
			grdstp: '%',
			grdend: '%',			
			aspect: '48.61%',
		},
		list  : {
			title: '¿How can we help you? ',
			ncols: 3,
			marker: false,
			text : '',
			items: [
				'<span>Treasury</span>',
				'<span>Electronic invoicing</span>',
				'<span>Accounts receivable management</span>',
				'<span>Payroll</span>',
				'<span>Document processing and custody</span>'
			]
		},
	}
}

const data = {}

export { 
	servicesEN,
	servicesES,
	data
}