
// Components
import TeamCard from '../components/TeamCard'
import Timeline from '../components/Timeline'
import AboutUsHero from '../components/AboutUsHero'

// Styles
import { StyledAboutUs } from './styles/AboutUs.styled'

// Contexts 
import { useTeamContext } from '../contexts/team/TeamState'
import { useLanguageContext } from '../contexts/language/LanguageState'

// Thirdparty
import { useState } from 'react'
import Fade from 'react-reveal/Fade'

export default function AboutUs({ links }) {

	const { team } = useTeamContext()
	const [ teamCut, setTeamCut ] = useState(3)
	const { lang, dates, about, ahero } = useLanguageContext()

	return (
		<StyledAboutUs>
			<AboutUsHero data={ahero} />
			<div className='history-div'>
				<h4>{about.hist.title}</h4>
				<p>{about.hist.text}</p>
			</div>
			<Timeline dates={dates} />
			<div className='team-head'>
				<p>{about.team.title}</p>
				<h1>{about.team.subtl}</h1>
			</div>
			<Fade>
			<div className='team-div'>
				{team
					.slice(0, teamCut)
					.map((item, idx)=>
						<TeamCard key={idx} {...item} />
					)
				}
				<div
					style={{
						display: teamCut>team.length?'none':'auto'
					}}
					className='team-div--more'
					onClick={e=>setTeamCut(prev=>prev+3)} 
				>
					Ver más
				</div>
			</div>
			</Fade>
		</StyledAboutUs>
	)
}

