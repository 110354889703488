
import { 
	HeadText, 
	CustomList,
	ImageBanner
} from '../components/ServicesComponents'
import NavLink from '../components/NavLink'

// Styles 
import { StyledBackOffice } from './styles/BackOffice.Styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'


export default function BackOffice() {

	const { lang, servs } = useLanguageContext()

	return (
		<StyledBackOffice>
			<HeadText {...servs.backoffice} />
			<ImageBanner {...servs.backoffice.img} />
			<CustomList {...servs.backoffice.list} />
			<div className='backoffice-contact'>
			<NavLink to='/contacto'>
				{lang==='ES'?'Contactanos':'Contact us'}
				<br />
				<i className="fa-regular fa-envelope"></i>
			</NavLink>
			</div>
		</StyledBackOffice>
	)
}

