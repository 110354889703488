

// Components
import Hero from '../components/Hero'
import Stats from '../components/Stats'
import HoverGrid from '../components/HoverGrid'
import ArticleCard from '../components/ArticleCard'
import SectionCard from '../components/SectionCard'
import QuotesSlider from '../components/QuotesSlider'
import CustomSlider from '../components/CustomSlider'
import ContactSection from '../components/ContactSection'

// Styles
import { StyledHome } from './styles/Home.styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'
import { useArticlesContext } from '../contexts/articles/ArticlesState'

// Thirdparty
import { useEffect } from 'react'

export default function Home() {

	const { articles } = useArticlesContext()
	const { 
		lang, 
		sects, 
		hcard, 
		stats, 
		hhero, 
		cntct, 
		quots 
	} = useLanguageContext()

	return (
		<StyledHome>
			<Hero data={hhero} />
			<QuotesSlider quotes={quots} />
			<CustomSlider>
				{articles.filter(item=>item.lang===lang)
					.map((item, idx)=> 
					<ArticleCard 
						key={idx} 
						{...item} 
					/>
				)}
			</CustomSlider>
			{sects.map((item,idx)=>
				<SectionCard 
					key={idx} 
					{...item} 
					pos={idx%2}
				/>
			)}
			<div className='servicios-banner'>
				<p>
					{lang==='ES'?'SERVICIOS':'SERVICES'}
				</p>
				{lang==='ES'&&
					<h3>
					Aprovechamos las relaciones con expertos<br />
					para ofrecer los mejores servicios
 					</h3>
				}
				{lang==='EN'&&
					<h3>
					We leverage relationships with experts<br /> 
					to offer the best services
 					</h3>
				}
			</div>
			<HoverGrid cards={hcard} />
			<Stats data={stats} />
			<ContactSection data={cntct} />
		</StyledHome>
	)

}