
import * as THREE from 'three'
import ThreeGlobe from 'three-globe'
import { extend, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import map from './map.json'
import lines from './lines.json'
import countries from './custom.geo.json'
import { useLocation } from 'react-router-dom'


import { useRef, useEffect, useState, useLayoutEffect, Suspense } from 'react'

import HtmlMarker from './HtmlMarker'

extend({ ThreeGlobe })
extend({ OrbitControls })

export default function Globe({ onLoad = null, hoverOn = null }) {

	const domRef = useRef()
	const control = useRef()
	const globeRef = useRef()
	const groupRef = useRef()
	const { camera, gl } = useThree()
	const path = useLocation().pathname
	const aspect = useRef(window.innerWidth / window.innerHeight)
	const cursor = useRef({
		x: 0, 
		y: 0, 
		leftX: 0, 
		leftY: 0})
	const sizes = useRef({
		width: window.innerWidth, 
		height: window.innerHeight
	})

	useEffect(()=>{
		domRef.current = document.querySelector('.webgl')
		const updateAspect = (e) => {
			sizes.current = {
				width: domRef.current.clientWidth, 
				height: domRef.current.clientHeight
			}
			aspect.current = sizes.current.width / aspect.current.height		
		}
		addEventListener('resize', updateAspect)
    if (('ontouchstart' in window) ||
   		(navigator.maxTouchPoints > 0) ||
   		(navigator.msMaxTouchPoints > 0)) {
    	addEventListener('touchstart', setTouchEvents)
    }
    restoreContext()
		groupRef.current.position.x = 100
		loadGlobeParams()
		onLoad()
		return ()=> {
			removeEventListener('resize', updateAspect)
			removeEventListener('ontouchstart', setTouchEvents)
		}

	}), []

	useFrame((state, delta)=>{
		if (!path.includes('clientes')) return
		updateLabels()
		cursor.current.leftX -= cursor.current.leftX * 0.1
		groupRef.current.rotation.y += cursor.current.leftX
		if (hoverOn!==null) return 
		groupRef.current.rotation.y -= delta * 0.1
		control.current.update()
	})

 	// useLayoutEffect(() => {
 	// 	loadGlobeParams()
  // }, [])

  const loadGlobeParams = async () => {

 		const globeMaterial = globeRef.current.globeMaterial()

	 	// globe material
		globeMaterial.shininess = 0.7   
		globeMaterial.emissiveIntensity = 1
	  globeMaterial.color = new THREE.Color('#fff')
	 	globeMaterial.emissive = new THREE.Color('#fff')

	 	//Mobile/Desktop set up
	 	let resolution = await isMobile()?2:3

	 	// map polygons
		globeRef.current.hexPolygonMargin(0.7)
		globeRef.current.hexPolygonColor('#dfdfe166')
		globeRef.current.hexPolygonResolution(resolution)
		globeRef.current.hexPolygonsData(countries.features)

		// globe atmosphere
		globeRef.current.showAtmosphere(true)
		globeRef.current.atmosphereAltitude(0.05)
		globeRef.current.atmosphereColor("#efefef")

		const arcColors = ['#fd7e01', '#24cae2', '#148c9d']

		// setting map arc effect
		setTimeout(()=>{
			globeRef.current.arcsData(lines.pulls)
			globeRef.current.arcColor((e)=>{
				return arcColors.at(Math.floor(Math.random() * 3))
			})
			globeRef.current.arcAltitude((e)=>{
				return e.arcAlt
			})
			globeRef.current.arcStroke((e)=>{
				return e.status?1.5:0.75
			})
			globeRef.current.arcDashGap(4)
			globeRef.current.arcDashLength(0.9)
			globeRef.current.arcDashAnimateTime(e=>e.dur)
			globeRef.current.arcsTransitionDuration(1000)
			globeRef.current.arcDashInitialGap(e=>e.order*1)

			if (onLoad) onLoad()

		}, 0)
  }

  const isMobile = async () => {
  	if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  		return true
		} else {
		  return false
		}
  }

  function updateLabels() {
		let ry = groupRef.current.rotation.y
		let labels = document.querySelectorAll('.label') 

		// if (!showGlobe) return
		map.maps.map((marker,idx)=>{
			let ryDeg = parseFloat(marker.lng) + ry * (180) / Math.PI
			let { x, y, z } = globeRef.current.getCoords(marker.lat, ryDeg)

			labels[idx].style.transform = `translateX(${(x + 100) * 2.85}px) translateY(${-y * 2.85}px)`
			labels[idx].style.opacity = 0
			if (z >= 0 && (hoverOn===null || hoverOn===idx)) {
				labels[idx].style.opacity = 1
			}
			labels[idx].classList.remove('is-hover')
			if (hoverOn===idx) {
				labels[idx].classList.add('is-hover')
			}
		})
  }

  function restoreContext() {
    const canvas = gl.domElement;
    canvas.addEventListener(
      'webglcontextlost',
      function (event) {
        event.preventDefault();
        setTimeout(function () {
          gl.forceContextRestore();
        }, 1);
      },
      false
    );
  }

	const onMouseMove = (e) => {
		const [leftX, leftY] = updateCursor(e)
		cursor.current.leftX += leftX
		cursor.current.leftY += leftY
	}

  const setMoveEvents = (e) => {
  	updateCursor(e)
			addEventListener('mousemove', onMouseMove)
			addEventListener('mouseup', e=>{
				removeEventListener('mousemove', onMouseMove)
			})
  }

  const setTouchEvents = (e) => {
  	updateCursor(e.targetTouches[0])
			addEventListener('touchmove', e=>
				onMouseMove(e.targetTouches[0]))
			addEventListener('touchend', e=>{
				removeEventListener('touchmove', e=>
					onMouseMove(e.targetTouches[0]))
			})  	
  }

  const updateCursor = (e) => {
		let posX =  (e.clientX - 250) / sizes.current.width - 0.5
		let posY =  (e.clientY) / sizes.current.height - 0.5
		let leftX = (posX - cursor.current.x)
		let leftY = (posY - cursor.current.Y)
    cursor.current.x = posX 
    cursor.current.y = posY 
  	return [leftX, leftY]
  }

	return (
		<>
			<orbitControls 
				ref={control} 
				enabled={false}
				args={[camera, gl.domElement]} 
			/>
			<ambientLight color={0xbbbbbb} intensity={0.5}/>
			<group 
				ref={groupRef} 
				onPointerDown={setMoveEvents}
				rotation={[0, Math.PI*(5/12), 0]} 
			>
				<threeGlobe ref={globeRef} />
			</group>
		</>
	)
}
