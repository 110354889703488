
// Utils
import queryReducer from '../Utils/query-reducer'
import { queryInit, queryWrapper } from '../Utils/query-reducer'
// Styles
import { StyledTeamMenu } from './styles/TeamMenu.styled'
// Thirdparty
import { useRef, useState, useReducer } from 'react'

const initMember = {
	name      : '',
	charge    : '',
	profile   : '',
	email     : '',
	linkedin  : ''
}

export default function TeamMenu({init, saveCB, closeCB}) {

	const imgInput = useRef()
	const [ info, setInfo ] = useState('')
	const [ lang, setLang ] = useState('ES')
	const [ data, setData ] = useState(init??initMember)
	const [ query, dispatch ] = useReducer(queryReducer, queryInit)

	const handleChange = (e) => {
		let { type, value, name } = e.target
		setData(prev=>({
			...prev,
			[name]: value
		}))
	}

	const callSave = queryWrapper(async (e) => {
		e.preventDefault()
		if (!saveCB) return
		const imageFile = imgInput.current.files[0]
		let res = await saveCB(data, imageFile, init?._id??null)
		if (!res.ok) return res
		// res.callb = closeCB
		return res
	}, dispatch)

	const callClose = (e) => {
		e.preventDefault()
		closeCB()
	}

	return (
		<StyledTeamMenu className='team-menu'>
			<label htmlFor='title'>Nombre</label>
			<input 
				id='name'
				type='text' 
				name='name'
				placeholder='Nombre*'
				onChange={handleChange}
				value={data.name}
			/>
			<label htmlFor='charge'>Cargo</label>
			<input 
				id='charge'
				type='text' 
				name='charge'
				placeholder='Cargo*'
				onChange={handleChange}
				value={data.charge}
			/>	
			<label htmlFor='profile'>Perfil</label>
			<input 
				id='profile'
				type='text' 
				name='profile'
				placeholder='Perfil*'
				onChange={handleChange}
				value={data.profile}
			/>	
			<label htmlFor='email'>Email</label>
			<input 
				id='email'
				type='email' 
				name='email'
				placeholder='Email*'
				onChange={handleChange}
				value={data.email}
			/>	
			<label htmlFor='linkedin'>Linkedin</label>
			<input 
				id='linkedin'
				type='text' 
				name='linkedin'
				placeholder='linkedin*'
				onChange={handleChange}
				value={data.linkedin}
			/>	
			<label htmlFor='image'>Imagen</label>
			<input 
				ref={imgInput}
				type="file" 
				id="image" 
				accept="image/*"
			/>
			<button
				id='cancel' 
				onClick={callClose}
				disabled={query.waiting}
			>
				Cancelar
			</button>
			<button 
				id='save'
				onClick={callSave}
				disabled={query.waiting}
			>
				{!query.waiting && 
					<p>Guardar</p>
				}
				{query.waiting && 
					<i className="fa-solid fa-spinner"></i>
				}
			</button>
			<div title={query.info} className='info'>
				{query.info}
			</div>
		</StyledTeamMenu>
	)
}
