
import * as THREE from 'three'
import styled from 'styled-components'
import { useRef, useState, useEffect } from 'react'
import { Html } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function HtmlMarker(props) {

	const [ pos, setPos ] = useState([0, 0, 0])

	useEffect(()=>{
		let d = Math.sqrt(props.x**2+props.y**2+props.z**2)
		let cx = props.x / d
		let cz = props.z / d
		let cy = props.y / d
		let offset = props.offset??2
		setPos([
			(d + offset) * cx,
			(d + offset) * cy,
			(d + offset) * cz,
		])		
	}, [])

	return (
		<Marker 
			position={pos}>
			<StyledHtmlMarker
				$isVisible={props.isVisible??true}
        onMouseEnter={
        	e=>props.hovercb?props.hovercb(idx):null
        }
				onMouseLeave={
					e=>props.hovercb?props.hovercb(null):null
				}>
				<div
					className='marker-label'>
					{props.label??''}
				</div>
			</StyledHtmlMarker>
		</Marker>
	)

}


function Marker({ children, ...props }) {
  const ref = useRef()
  const [isOccluded, setOccluded] = useState()
  const [isInRange, setInRange] = useState()
  const isVisible = isInRange && !isOccluded

  const vec = new THREE.Vector3()
  useFrame((state) => {
    const range = state.camera.position.distanceTo(ref.current.getWorldPosition(vec)) <= 400
    if (range !== isInRange) setInRange(range)
  })
  return (
    <group ref={ref}>
      <Html
        occlude
        center={true}
        onOcclude={setOccluded}
        style={{ 
        	position: 'relative', 
        	transition: 'all 0.2s', 
        	opacity: isVisible ? 1 : 0, 
        	transform: `scale(${isVisible ? 1 : 0.25})` 
        }}
        {...props}>
        {children}
      </Html>
    </group>
  )
}

const StyledHtmlMarker = styled.div`


	z-index: -1;
	width: 16px;
	aspect-ratio: 1;
	--dotSize: 16px;
	--chevSize: 10px;
	border-radius: 50%;
	position: absolute;
	transition: all 1s; 
	background: #21cde7;
	transform: translate(-50%, -50%);

	&:hover {
		background: #148c9d;
		width: 32px;
	}

	&:hover > .marker-label {
		display: block;
		transform: translateX(calc(-50% + 16px)) translateY(calc(-100% - var(--chevSize)));
	}

	& > .marker-label {
		z-index: 20;
		color: #000;
		display: none;
		background: #fff;
		position: absolute;
		width: max-content;
		padding: 10px 30px;
		border-radius: 6px;
		white-space: pre-wrap;
		box-shadow: 0 4px 10px 4px #0004;
		transform: translateX(calc(-50% + 8px)) translateY(calc(-100% - var(--chevSize))) translateZ(10000px);
	}

	& > .marker-label:before {
		left: 0;
		right: 0;
		content: '';
		margin: auto;
		position: absolute;
		width: min-content;
		border: var(--chevSize) solid transparent;
		border-top: var(--chevSize) solid #fff;
		bottom: calc(var(--chevSize) * -2 + 1px);
	}

`