
import { useState, useEffect } from 'react'
import styled from 'styled-components'

export default function TestimonialCard(props) {

	const [ more, setMore ] = useState(false)
	const [ imgSrc, setImgSrc ] = useState('./images/default.jpg') 

	useEffect(()=>{
		getImg()
	}, [])

	const getImg = async () => {
		try {
			let res = await fetch(props.img, {
					cache: 'reload',
					mode: 'no-cors'
				})
			if (!res.ok) return
			let blob = await res.blob()
			if (!blob.type.includes('image')) return
			let src  = URL.createObjectURL(blob) 
			setImgSrc(src)
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<StyledTestimonialCard 
			className={`${more?'':'folded'}`}
			key={props.name}
		>
			<img src={imgSrc} />
			<h5>{props.name}</h5>
			<h6>{props.from}</h6>
			<p>{props.text}</p>
			<a 
				onClick={
					e=>setMore(prev=>!prev)
				}
			>
				Mostrar {more?'menos':'más'}
			</a>
		</StyledTestimonialCard>
	)
}

export const StyledTestimonialCard = styled.div`
	
	display: grid;
	overflow: hidden;
	text-align: left;
	column-gap: 1rem;
	padding: 2rem 1rem 1rem;
	border-radius: 2rem;
	align-content: flex-start;
	box-shadow: 0 4px 5px 0px #0001;
	grid-template-columns: min-content 1fr;
	background: ${({theme})=>theme.text.color.scheme1};

	& > img {
		width: 3.75rem;
		aspect-ratio: 1;
		overflow: hidden;
		border-radius: 50%;
		grid-row: 1 / span 2;
	}

	& > h5 {
		align-self: end;
	}

	& > h6 {
		font-weight: 300;
		align-self: flex-start;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	&  > p {
		overflow: hidden;
		font-size: 0.8rem;
		grid-column: 1 / span 2;
		margin: 1rem 0.5rem 1.2rem;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	&.folded p {
		overflow: hidden;
		min-height: 4rem;
		display: -webkit-box;
		-webkit-line-clamp: 5;
				line-clamp: 5;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
	}

	& a {
		margin: 0 0.5rem;
		font-size: 0.8rem;
		width: max-content;
		grid-column: 1 / span 2;
		color: ${({theme})=>theme.text.color.scheme4};
	}

	& a:hover {
		color: ${({theme})=>theme.text.color.scheme3};
	}

`