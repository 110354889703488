
// Components
import Stats from '../components/Stats'
import NavLink from '../components/NavLink'
import CustomHero from '../components/CustomHero'
import CustomSlider from '../components/CustomSlider'
import TestimonialCard from '../components/TestimonialCard'

// styles 
import { StyledClients } from './styles/Clients.styled'

// Thirdparty
import Fade from 'react-reveal/Fade'
import { Outlet, useLocation } from 'react-router-dom'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'


export default function Clients() {

	const path = useLocation().pathname.split('/').at(-1)
	const { lang, stats, tests, cases } = useLanguageContext()

	return (
		<StyledClients>
		<CustomHero 
			title ={lang==='ES'?'Clientes':'Clients'}
			label1={lang==='ES'?'Inicio':'Home'}
			label2={lang==='ES'?'Clientes':'Clients'}/>
		<div className='clients-scene'>
			<Fade>
			<div className='clients-info'>
				<h1>
					{
						lang==='ES'?
						'Nuestros clientes':
						'Our clients'
					}
				</h1>
				<p>
					{
						lang==='ES'?
						'Hemos desarrollado relaciones importantes con diferentes expertos del mercado, lo que nos permite aprovechar esa red construida y ponerla en servicio de nuestros clientes.':
						'We have developed important relationships with different market experts, which allows us to take advantage of this built network and put it at the service of our clients.'
					}
				</p>
			</div>
			</Fade>
		</div> 
		{path==='clientes' &&
			<>
			<div className='clients-testimonials'>
				<Fade>
				<small>
					{
						lang==='ES'?
						'NUESTROS CLIENTES':
						'OUR CLIENTS'
					}
				</small>
				<h1>
					{
						lang==='ES'?
						'Testimonios':
						'Testimonials'
					}				
				</h1>
				</Fade>
				<CustomSlider>
					{tests.map((item, idx)=> 
						<TestimonialCard key={idx} {...item} />
					)}
				</CustomSlider>
			</div>
			<div className='clients-cases'>
				<Fade>
				<h4>
					{
						lang==='ES'?
						'CASOS DE ÉXITO':
						'SUCCESS CASES'
					}					
				</h4>
				<div>
					{cases.map((item, idx)=>
						<NavLink key={idx} to={`./${idx}`}>
							{item.title}
						</NavLink>				
					)}
				</div>
				</Fade>
			</div>
			<Stats data={stats} clearBg={true} />
			</>
		}
		<Outlet />
		</StyledClients>
	)
}

