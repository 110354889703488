

import MarkerLabel from './MarkerLabel'
import { createRoot } from "react-dom/client"
import { useState, useEffect, useRef } from 'react'

const mapOptions = {
  mapId: '8113bef1a28631a2',
  center: { 
  	lat: 4.669599794265399, 
  	lng: -74.05842528941439 
  },
  zoom: 20,
  disableDefaultUI: true,
};

export default function MyMap() {
  const [map, setMap] = useState();
  const ref = useRef();

  useEffect(() => {
    setMap(new window.google.maps.Map(ref.current, mapOptions));
  }, []);

  function gotoMaps() {
  	window.open('https://www.google.com/maps/place/Edificio+Country+Office/@4.6695866,-74.0609342,17z/data=!3m1!4b1!4m6!3m5!1s0x8e3f9af43bcb41a1:0x5bef8a8bb5f59134!8m2!3d4.6695813!4d-74.0583593!16s%2Fg%2F11g9nrbn5q?hl=es&entry=ttu')
  }

  return (
    <>
      <div ref={ref} id="map" />
      {map && map.mapId && 
      	<Marker 
      		map={map}
      		onClick={gotoMaps}
      		position={{ 
      			lat: 4.669599794265399, 
      			lng: -74.05842528941439 
      		}}
      	>
      		<MarkerLabel />
      	</Marker>
      	}
    </>
  );
}

function Marker({ map, position, children, onClick }) {
  const rootRef = useRef();
  const markerRef = useRef();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement("div");
      rootRef.current = createRoot(container);

      markerRef.current = new google.maps.marker.AdvancedMarkerView({
        position,
        content: container,
      });
    }

    return () => (markerRef.current.map = null);
  }, []);

  useEffect(() => {
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
    markerRef.current.addEventListener("gmp-click", onClick);
    return () => markerRef.current.removeEventListener("gmp-click", onClick);
  }, [map, position, children, onClick]);
}
