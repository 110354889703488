
import { useContext, createContext } from 'react'
import { useReducer, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const UsersContext = createContext()

const useUsersContext = () => {
	return useContext(UsersContext)
}

const userReducer = (state, action) => {

	let { type, value } = action

	switch (type) {
		case 'SET_USER': 
			return ({
				...state,
				...value
			})
		default: return state
	}
}

const initState = {
	username: '',
	userId: null,
	email: '',
}

const apiPath = '/api/v1/users'

const queryWrapper = (fn) => {
	return async (...args) => {
		try {
			let res = await fn(...args)
			return {ok: true, ...res}
		} catch(err) {
			console.log(err)
			let msg = err.response?.data?.msg ?? err.message ?? 'Bad Request.'
			console.log(msg)
			return {ok: false, msg}
		}
	}
}

export default function UsersState({children}) {

	const [ user, dispatch ] = useReducer(userReducer, initState)
	const [ token, setToken ] = useState(()=>localStorage.getItem('token')??null)
	const navigate = useNavigate()

	useEffect(()=>{
		if (token && !user.userId) getUser()
	}, [token])

	const getUser = queryWrapper( async() => {
		let res = await axios.get(apiPath + '/', {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
		await dispatch({type: 'SET_USER', value: res.data.user})
	})

	const getUsers = queryWrapper( async() => {
		let res = await axios.get(apiPath + '/users', {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
		return res
	})

	const login = queryWrapper( async(userInput) => {
		let res = await axios.post(apiPath + '/login', userInput)
		await dispatch({type: 'SET_USER', value: res.data.user})
		await setToken(res.data.token)
		await localStorage.setItem('token', res.data.token)
	})

	async function logout(path) {
		await dispatch({type: 'SET_USER', value: initState})
		await localStorage.clear()
		navigate(path)
	}

	const register = queryWrapper( async(userInput) => {
		let res = await axios.post(apiPath + '/reg', userInput)
		await setToken(res.data.token)
	})

	const changePass = queryWrapper( async(body) => {
		let res = await axios.put(apiPath + '/changepass', body, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})
		return {msg: 'Cambio exitoso.'}
	})

	const setAdmin = queryWrapper( async(body) => {
		let res = await axios.put(apiPath + '/setadmin', body, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})
		return res
	}) 

	const delUser = queryWrapper( async(id) => {
		let res = await axios.delete(apiPath + `/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})
		return res
	}) 

	return (
		<UsersContext.Provider value={{
			user,
			token,
			login, 
			logout,
			delUser,
			setAdmin,
			register,
			getUsers,
			changePass,
		}}>
			{children}
		</UsersContext.Provider>
	)

}

export {
	useUsersContext
}