

// Thirdparty
import axios from 'axios'
import { useContext, createContext } from 'react'
import { useReducer, useState, useEffect } from 'react'

// Data 
import { team as teamStatic } from '../../data/AboutUsData'

// Contexts
import { useUsersContext } from '../users/UsersState'

const TeamContext = createContext()

const useTeamContext = () => {
	return useContext(TeamContext)
}



const apiPath = '/api/v1/team'

const teamReducer = (state, action) => {

	let { type, value } = action

	switch (type) {
		case 'SET_TEAM': 
			return value
		case 'RMV_MEMBER':
			return (
				state.filter(member=>
					member._id!==value
				)
			)
		case 'UPD_MEMBER':
			return (
				state.map(member=>
					member._id===value._id ?
					value :
					member
				)
			)
		case 'ADD_MEMBER':
			return (
				[...state, value]
			)
		default: return state
	}
}

const queryWrapper = (fn) => {
	return async (...args) => {
		try {
			let res = await fn(...args)
			return {ok: true, ...res}
		} catch(err) {
			console.log(err)
			let msg = err.response?.data?.msg ?? err.message ?? 'Bad Request.'
			console.log(msg)
			return {ok: false, msg}
		}
	}
}

export default function TeamState({children}) {

	const { token } = useUsersContext()
	const [ team, dispatch ] = useReducer(teamReducer, teamStatic)

	useEffect(()=>{
		getTeam()
	}, [])

	const getTeam = queryWrapper( async() => {
		let res = await axios.get(apiPath + '/all')
		if (res.data.members.length===0) return res
		await dispatch({type: 'SET_TEAM', value: res.data.members})
		return res
	})

	const getMember = queryWrapper( async(id) => {
		let res = await axios.get(apiPath + `/${id}`)
		return res
	})

	const deleteMember = queryWrapper( async(id) => {
		let res = await axios.delete(apiPath + `/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})
		await dispatch({type: 'RMV_MEMBER', value: res.data.member._id})
		return res
	})

	const putMember = queryWrapper( async(data, image, id) => {
		let res = await axios.put(apiPath + `/${id}`, data, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})

		if (!res.data.ok) return res
		if (!image) {
			await dispatch({type: 'UPD_MEMBER', value: res.data.member})
			return res
		}
 		const formData = new FormData();
 		formData.append('image', image)

		let imgRes = await axios.put(apiPath + `/image/${id}?prevImg=${res.data.member.image}`, formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data'
			}		
		})

		if (!imgRes.data.ok) return imgRes
		await dispatch({type: 'UPD_MEMBER', value: imgRes.data.member})
		return res		
	})

	const postMember = queryWrapper( async(data, image) => {
		let res = await axios.post(apiPath + '/', data, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})

		if (!res.data.ok) return res
 		const formData = new FormData();
 		formData.append('image', image)

		let imgRes = await axios.put(apiPath + `/image/${res.data.member._id}?clean=true`, formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data'
			}		
		})

		if (!imgRes.data.ok) return imgRes

		await dispatch({type: 'ADD_MEMBER', value: imgRes.data.member})
		return res	
	})


	return (
		<TeamContext.Provider value={{
			team,
			getTeam,
			getMember,
			putMember,
			postMember,
			deleteMember
		}}>
			{children}
		</TeamContext.Provider>
	)

}

export {
	useTeamContext
}