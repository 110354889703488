
import styled from 'styled-components'

export const StyledArticles = styled.div`

	gap: 1rem;
	width: 100%;
	display: grid;	
	padding-top: 2rem;
	position: relative;
	grid-template-columns: repeat(auto-fill, minmax(250px, max-content));

	& .info {
		width: 100%;
		color: salmon;
		position: absolute;
		text-align: center;
		top: calc(80px + 1rem);
	}

	& > i {
		z-index: 3;
		width: 2rem;
		right: 2rem;
		top: -1.5rem;
		height: 2rem;
		display: grid;
		cursor: pointer;
		position: absolute;
		border-radius: 50%;
		place-content: center;
		color: ${({theme})=>theme.text.color.scheme1};
		background: ${({theme})=>theme.text.color.scheme4};
	}

	& > i:hover {
		opacity: 0.6;
		background: ${({theme})=>theme.text.color.scheme4};
	}

`