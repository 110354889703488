
import { useState, useReducer } from 'react'
import Fade from 'react-reveal/Fade'
import { StyledContactSection } from './styles/ContactSection.styled'

// Utils
import queryReducer from '../Utils/query-reducer'
import { queryInit, queryWrapper } from '../Utils/query-reducer'

export default function ContactSection({ data }) {


	const [ query, dispatch ] = useReducer(queryReducer, queryInit)
	const [ form, setForm ] = useState({
		name: '',
		email: '',
		subject: '',
		phone: '',
		message: '',
	})

	function updateInputs(e) {
		const { name, value } = e.target
		setForm(prev=>({
			...prev,
			[name]: value
		}))
	}

	function validate(formData) {
		var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (!formData.get('name')) return 'Nombre'
		if (!formData.get('email').match(emailRegex)) return 'Email'
		if (!formData.get('subject')) return 'Asunto'
		if (!formData.get('phone')) return 'Teléfono'
		if (!formData.get('message')) return 'Mensaje'
		return null

	}

	const submitForm = queryWrapper(async (e) => {
		e.preventDefault()
        const formData = new FormData(e.target)
        let formCheck = validate(formData)

        if (formCheck) return {
        	ok: false, 
        	msg: 'Verificar ' + formCheck
        }

		let res = await fetchForm(formData)
		if (!res.ok) return res
		return res
	}, dispatch)

	async function fetchForm(formData) {
		let res = await fetch("https://formsubmit.co/ajax/contacto@ndrasesorias.net", {
		    method: "POST",
		    headers: { 
		        'Content-Type': 'application/json',
		        'Accept': 'application/json'
		    },
		    body: JSON.stringify({
        		name: formData.get('name'),
        		email: formData.get('email'),
        		subject: formData.get('subject'),
        		phone: formData.get('phone'),
        		message: formData.get('message')
    		})
		})
		if (!res.ok) return {ok: false, msg: 'Intentar nuevamente'}
      	return {ok: true, msg: 'Mensaje enviado.'}
        
   	}

	return (
		<StyledContactSection>
			<Fade>
			<div className='contact-section--div'>
				<h1>{data.title}</h1>
				<p>{data.text}</p>
			</div>
			</Fade>
			<Fade>
			<form  
				method="POST"  
				onSubmit={submitForm}>
				<input 
					name='name'
					value={form.name}
					onChange={updateInputs}
					className='contact-form--name'
					placeholder={data.placeholders.name} />
				<input
					type='email'
					name='email'
					value={form.email}
					onChange={updateInputs}
					className='contact-form--email'
					placeholder={data.placeholders.email} />
				<input 
					name='subject'
					value={form.subject}
					onChange={updateInputs}
					className='contact-form--subject'
					placeholder={data.placeholders.subject} />
				<input
					type='tel'
					name='phone'
					value={form.phone}
					onChange={updateInputs}
					className='contact-form--phone'
					placeholder={data.placeholders.phone} />
				<textarea
					name='message'
					value={form.text}
					onChange={updateInputs}
					className='contact-form--mssg'
					placeholder={data.placeholders.text} />		
				<button
					disabled={query.waiting || query.succeed}
					className={query.waiting?'spin':''}
					style={{
						cursor: !query.waiting && !query.succeed?'pointer':'default'
					}}
					>
					{query.succeed && <i className="fa-solid fa-check check"></i>}
					{!query.waiting && !query.succeed && data.placeholders.button}
					{query.waiting && <i className="fa-solid fa-spinner loader"></i>}

				</button>	
				<p className='contact-form--queryinfo'>
					{query.info}
				</p>
			</form>
			</Fade>
		</StyledContactSection>
	)
}