
import styled from 'styled-components'

export const StyledNIIF = styled.div`
	
	gap: 5rem;
	margin: auto;
	display: grid;
	padding: 7rem 0rem;
	width: min(calc(100% - 1rem), 1200px);

	.niif-title2 {
		max-width: 100%;
		font-weight: 600;
		padding: 0rem 4rem;
		font-size: 3.75rem;
	}

	.niif-procesos h2 {
		font-size: 2.25rem;
	}

	.niif-procesos p {
		gap: 1rem;
		display: flex;
		font-weight: 400;
		align-items: center;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	.niif-procesos p img {
		width: 3.75rem;
		height: 3.75rem;
	}

	& .niif-procesos {
		gap: 2rem;
		display: grid;
		padding: 0rem 4rem;
		align-items: center;
		justify-content: center;
		grid-template-columns: repeat(3, 1fr);
	}

	& .niif-procesos > *:first-child {
		grid-row: 1 / span 3
	}	

	& .niif-procesos > *:not(first-child) {
		width: 80%
	}

	@media screen and (max-width: 900px) {

		.niif-procesos {
			padding: 0rem 2rem;
			grid-template-columns: repeat(2, 1fr);
		}

		.niif-procesos p {
			grid-column: 2 / span 1;
		}

	 	& .niif-procesos > *:first-child {
			grid-row: 1 / span 5;
		}

		.niif-title2 {
			font-size: 2.5rem;
			padding: 0rem 2rem;
		}

	}

	@media screen and (max-width: 500px) {

		.niif-title2 {
			font-size: 2rem;
		}

		.niif-procesos {
			grid-template-columns: repeat(1, 1fr);
		}

		.niif-procesos p {
			grid-column: 1 / span 1;
		}

	 	& .niif-procesos > *:first-child {
			grid-row: 1 / span 1;
	 		grid-column: 1 / span 1;
		}

	}

`