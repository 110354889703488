

const datesES = [
	{
		year  : 1998,
		title : 'Consolidación',
		text  : 'NDR se consolida como una sociedad de contadores públicos ante la Junta Central de Contadores'
	},
	{
		year  : 2012 ,
		title : 'Enfoque empresarial',
		text  : 'Realizamos un nuevo enfoque de proyección empresarial'
	},
	{
		year  : 2015,
		title : 'Esquema contable NIIF',
		text  : 'Nos preparamos para la implementación del nuevo esquema contable en Colombia (NIIF)'
	},
	{
		year  : 2018,
		title : 'Pico de facturación',
		text  : 'Alcanzamos el pico más alto de facturación en nuestra historia'
	},
	{
		year  : 2020,
		title : 'Optimización tecnológica',
		text  : 'NDR hace los esfuerzos necesarios para optimizar los recursos tecnológicos en beneficio de los clientes'
	},
	{
		year  : 2023,
		title : '25 años en el negocio',
		text  : 'Cumplimos 25 años en el negocio, con un equipo de más de 18 personas y 200 clientes de revisoría fiscal, contaduría, auditoría, consultoría y personas naturales'
	}
]

const datesEN = [
	{
		year  : 1998,
		title : 'Consolidation',
		text  : 'NDR establishes itself as a public accounting firm recognized by the Central Board of Accountants'
	},
	{
		year  : 2012 ,
		title : 'Business approach',
		text  : 'We undertake a new approach to business projection'
	},
	{
		year  : 2015,
		title : 'IFRS accounting framework',
		text  : 'We prepare for the implementation of the new accounting framework in Colombia (IFRS)'
	},
	{
		year  : 2018,
		title : 'Revenue peak',
		text  : 'We reach the highest peak of revenue in our history'
	},
	{
		year  : 2020,
		title : 'Technological optimization',
		text  : 'NDR makes necessary efforts to optimize technological resources for the benefit of clients'
	},
	{
		year  : 2023,
		title : '25 years in business',
		text  : 'We celebrate 25 years in business, with a team of over 18 individuals and 200 clients in tax review, accounting, auditing, consultancy, and individual services'
	}
]

const aboutES = {
	hist: {
		title: 'NUESTRA HISTORIA',
		text : '25 años dando respuesta a las necesidades en materia contable\npara pequeñas y medianas empresas.'
	},
	team: {
		title: 'CONOCE',
		subtl: 'NUESTRO EQUIPO'
	}
}

const aboutEN = {
	hist: {
		title: 'OUR HISTORY',
		text : '25 years responding to accounting needs\nfor small and medium-sized companies.'
	},
	team: {
		title: 'MEET',
		subtl: 'OUR TEAM'
	}
}

const team = [
	{
		image : '../images/team/nayda-dorelly-ruiz.png',
		name: 'Nayda Dorelly Ruiz',
		charge: 'Gerente General',
		profile: 'perfil Nayda',
		email: 'coquito@gmail.com',
		linkedin: 'usuario123'
		
	},
	{
		image : '../images/team/yolima-garcia-gomez.png',
		name: 'Yolima García Gomez',
		charge: 'Gerente gestión contable',
		profile: 'perfil Yolima',
		email: 'coquito@gmail.com',
		linkedin: 'usuario123'
	},
	{
		image : '../images/team/camilo-ruiz-diaz.png',
		name: 'Camilo Ruiz Díaz',
		charge: 'Gerente Proyectos NIIF',
		profile: 'perfil Camilo',
		email: 'coquito@gmail.com',
		linkedin: 'usuario123'
	},
	{
		image : '../images/team/carlos-alberto-chavez.png',
		name: 'Carlos Alberto Chávez',
		charge: 'Gerente auditoría',
		profile: 'perfil Camilo',
		email: 'coquito@gmail.com',
		linkedin: 'usuario123'
	}
]

const aheroES = {
	path1 : 'Inicio',
	path2 : 'Nosotros',
	title1: 'Quiénes somos',
	text1 : '25 años dando respuesta a las necesidades en materia contable\npara pequeñas y medianas empresas.',
	title2: 'Nuestro propósito',
	text2 : 'Somos un equipo de profesionales especialistas en asesoramiento contable, financiero, tributario, laboral y de gestión, comprometido con nuestros clientes a ser su asesor estratégico y confiable, con los más altos estándares de calidad.\nBuscamos brindar un servicio integral que supere las expectativas de nuestros clientes, permitiéndonos atender sus necesidades acorde a su modelo de negocio.\n\nTrabajamos para empoderar a los pequeños y medianos empresarios a través de soluciones contables de alto impacto. Generando competitividad a través de información en tiempo real y atrayendo inversión extranjera para ayudar con el desarrollo del país y la región.'
}

const aheroEN = {
	path1 : 'Home',
	path2 : 'About us',
	title1: 'ABOUT US',
	text1 : '25 years responding to accounting needs\nfor small and medium-sized companies.',
	title2: 'Our purpose',
	text2 : "We are a team of specialized professionals in accounting, finance, taxation, labor, and management consulting, committed to being our clients' strategic and reliable advisors, upholding the highest quality standards.\nWe aim to provide a comprehensive service that exceeds our clients' expectations, allowing us to address their needs according to their business model.\n\nWe work to empower small and medium-sized entrepreneurs through high-impact accounting solutions. Creating competitiveness through real-time information and attracting foreign investment to contribute to the country and the region's development."
}

export {
	team,
	datesES,
	datesEN,
	aboutES,
	aboutEN,
	aheroEN,
	aheroES
}