
// App
import App from './App'

// thirdparty
import reactDOM from 'react-dom/client' 
import { BrowserRouter } from 'react-router-dom'

// Contexts
import TeamState from './contexts/team/TeamState'
import UsersState from './contexts/users/UsersState'
import LanguageState from './contexts/language/LanguageState'
import ArticlesState from './contexts/articles/ArticlesState'

const root = reactDOM.createRoot(document.querySelector('#root'))

root.render(
	<BrowserRouter>
	<UsersState>
	<LanguageState>
	<ArticlesState>
	<TeamState>
		<App />
	</TeamState>
	</ArticlesState>
	</LanguageState>
	</UsersState>
	</BrowserRouter>
)