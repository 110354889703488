import styled from 'styled-components'

export const StyledHero = styled.div`
	
	width: 100%;
	position: relative;

	& > div {
		margin: auto;
		display: grid;
		grid-gap: 15px;
		padding: 11rem 4rem;	
		width: min(calc(100% - 1rem), 1200px);	
	}

	&::before {
		inset: 0;
		content: '';
		z-index: -1;
		position: absolute;
		background: #26212155;
	}

	& > img {
		top:0;
		z-index: -2;
		width: 100%;
		height: 100%; 
		object-fit: cover;
		position: absolute;
	}

	.home-hero--title {
		font-weight: 700;
		line-height: 3rem;
		font-size: 2.25rem;
		white-space: pre-wrap;
		font-family: 'Jost', sans-serif;
		color: ${({theme}) => theme.text.color.scheme1}
	}

	.home-hero--text {
		font-weight: 100;
		line-height: 2rem;
		white-space: pre-wrap;
		font-family: 'Nunito', sans-serif;
		color: ${({theme}) => theme.text.color.scheme1}
	}

	.home-hero--more {
		width: max-content !important;
		border-radius: 20px !important;
		padding: 12.5px 25px !important;
		font-family: 'Nunito', sans-serif !important;
		background: ${({theme}) => theme.text.color.scheme4} !important
	}

	.home-hero--link i {
		color: ${({theme})=>theme.text.color.scheme4}
	}

`