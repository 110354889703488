
import { StyledDots } from './styles/SliderDots.styled'
import { useState, useRef, useEffect } from 'react'

export default function SliderDots({ nItems, move, cb, w, h, aw, current, auto=true }) {

	const timeout = useRef(null)
	const dx0 = useRef(Math.floor(nItems / 2) * (w??10 + 10))
	const [ at, setAt ] = useState(()=>Math.ceil(nItems / 2) - 1)

	useEffect(()=>{
		cb?cb(at):null
		if (timeout.current) clearTimeout(timeout.current)
		if (auto) setChange()
	}, [at])

	useEffect(()=>{
		setAt(Math.ceil(nItems / 2) - 1)
		dx0.current = Math.floor(nItems / 2) * (w??10 + 10) 
	}, [nItems])

	useEffect(()=>{
		if (current===null||current===undefined) return
		setAt(current)
	}, [current])

	function setChange() {
		timeout.current = setTimeout(()=>
			setAt(prev=>
				at<nItems-1 ?
				parseInt(prev) + 1 :
				0), 5000)
	}

	return (
		<StyledDots 
			$h={h??10} 
			$w={w??10}
			$aw={aw??40}
			$dx={move?at * (w??10 + 10) - dx0.current:0}>
			<div>
			{Object.keys(Array(nItems).fill(null)).map(idx=>
				<div
					key={idx}
					onClick={e=>setAt(idx)} 
					className={`dot ${idx==at}`}>	
				</div>
			)}
			</div>
		</StyledDots>		
	)
}