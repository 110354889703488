
import styled from 'styled-components'

export default function MarkerLabel() {
	return (
		<StyledMarkerLabel>
			<div>
			<i className="fa-solid fa-location-pin"></i>
			<img src='./images/headquarters.webp' />
			<p>Carrera 19 # 82 85</p>
			<p>Edificio Country Office</p>
			<p>Oficina 603</p>
			</div>
		</StyledMarkerLabel>
	)
}

const StyledMarkerLabel = styled.div`

	--chevSize: 10px;
	display: grid;
	background: #fff;
	transform: scale(1);	
	transition: all 0.5s;
	border-radius: 0.75rem;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	box-shadow: 0 4px 10px 4px #0004;

	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 4px 10px 4px #0004, 0 0 0 0 rgba(0, 0, 0, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 4px 10px 4px #0004, 0 0 0 10px rgba(0, 0, 0, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 4px 10px 4px #0004, 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}

	& div {
		overflow: hidden;
		padding-bottom: 1rem;
	}

	&:before {
		left: 0;
		right: 0;
		content: '';
		margin: auto;
		position: absolute;
		width: min-content;
		border: var(--chevSize) solid transparent;
		border-top: var(--chevSize) solid #fff;
		bottom: calc(var(--chevSize) * -2 + 2px);
	}

	& div > *:not(img) {
		padding: 0.5rem 1rem;
	}

	& div img {
		max-height: 200px;
		margin-bottom: 1rem;
		border-radius: 0.75rem 0.75rem 0 0;
	}

	& div i {
		inset: 0;
		opacity: 0;
		margin: auto;
		display: grid;
		color: #0096A3;
		font-size: 2rem;
		position: absolute;
		place-content: center;
		transition: opacity 0.2s;
	}

	& div > *:not(i) {
		transition: opacity 0s;
	}


	@media (hover: hover) {

		
		border-radius: 50%;
		grid-template-rows: 0.2fr;
		grid-template-columns: 0.3fr;
		animation: pulse 2s infinite;
		
		& div > *:not(i) {
			opacity: 0;
		}

		& div i {
			opacity: 1;
		}

		&:hover i {
			opacity: 0;
		}

		&:hover {
			border-radius: 0.75rem;
			grid-template-rows: 1fr;
			grid-template-columns: 1fr;
			animation: none;
		}

		&:hover div > *:not(i) {
			opacity: 1;
			transition: opacity 0.5s 0.5s;
		}

	}

`