

import { useState, useEffect } from 'react'
import { useContext, createContext } from 'react'

import { 
	linksES, 
	linksEN,
	mochuES,
	mochuEN,
	statsES,
	statsEN,
	footerEN,
	footerES,
	contactES,
	contactEN,
	privacyES,
	privacyEN
} from '../../data/AppData'
import { 
	heroES,
	heroEN,
	quotesES,
	quotesEN,
	sectionsES, 
	sectionsEN,
	hoverCardsEN,
	hoverCardsES
 } from '../../data/HomeData'
 import { 
	servicesEN,
	servicesES
 } from '../../data/ServicesData'
 import {
 	datesES,
 	datesEN,
 	aboutES,
 	aboutEN,
 	aheroEN,
 	aheroES
 } from '../../data/AboutUsData'
 import {
 	netwsEN,
 	netwsES
 } from '../../data/ContactData'
 import {
 	testsEN,
 	testsES,
 	casesEN,
 	casesES
 } from '../../data/ClientsData'

const LanguageContext = createContext()

const useLanguageContext = () => {
	return useContext(LanguageContext)
}

const data = {
	links: {
		EN: linksEN,
		ES: linksES
	},
	sects: {
		EN: sectionsEN,
		ES: sectionsES
	},
	hcard: {
		EN: hoverCardsEN,
		ES: hoverCardsES
	},
	stats: {
		EN: statsEN,
		ES: statsES
	},
	hhero: {
		EN: heroEN,
		ES: heroES
	},
	cntct: {
		EN: contactEN,
		ES: contactES
	},
	prvcy: {
		EN: privacyEN,
		ES: privacyES
	},
	footr: {
		EN: footerEN,
		ES: footerES
	},
	servs: {
		EN: servicesEN,
		ES: servicesES
	},
	dates: {
		EN: datesEN,
		ES: datesES
	},
	about: {
		EN: aboutEN,
		ES: aboutES
	},
	ahero: {
		EN: aheroEN,
		ES: aheroES
	},
	netws: {
		EN: netwsEN,
		ES: netwsES
	},
	mochu: {
		EN: mochuEN,
		ES: mochuES
	},
	quots: {
		EN: quotesEN,
		ES: quotesES
	},
	tests: {
		EN: testsEN,
		ES: testsES
	},
	cases: {
		EN: casesEN,
		ES: casesES
	}
}

export default function LanguageState({children}) {{

	const [ lang, setLang ] = useState('ES')
	const [ hhero, setHhero ] = useState(heroES)
	const [ links, setLinks ] = useState(linksES)
	const [ stats, setStats ] = useState(statsES)
	const [ dates, setDates ] = useState(datesES)
	const [ about, setAbout ] = useState(aboutES)
	const [ ahero, setAhero ] = useState(aheroES)
	const [ netws, setNetws ] = useState(netwsES)
	const [ mochu, setMochu ] = useState(mochuES)
	const [ tests, setTests ] = useState(testsES)
	const [ cases, setCases ] = useState(casesES)
	const [ footr, setFootr ] = useState(footerES)
	const [ quots, setQuots ] = useState(quotesES)
	const [ cntct, setCntct ] = useState(contactES)
	const [ prvcy, setPrvcy ] = useState(privacyES)
	const [ sects, setSects ] = useState(sectionsES)
	const [ servs, setServs ] = useState(servicesES)
	const [ hcard, setHcard ] = useState(hoverCardsES)

	useEffect(()=>{

		setLinks(data.links[lang])
		setSects(data.sects[lang])
		setHcard(data.hcard[lang])
		setStats(data.stats[lang])
		setHhero(data.hhero[lang])
		setCntct(data.cntct[lang])
		setFootr(data.footr[lang])
		setServs(data.servs[lang])
		setDates(data.dates[lang])
		setAbout(data.about[lang])
		setAhero(data.ahero[lang])
		setNetws(data.netws[lang])
		setMochu(data.mochu[lang])
		setQuots(data.quots[lang])
		setTests(data.tests[lang])
		setCases(data.cases[lang])
		setPrvcy(data.prvcy[lang])

	}, [lang])

	return (
		<LanguageContext.Provider
			value={{
				lang,
				links,
				sects,
				hcard,
				stats,
				hhero,
				cntct,
				footr,
				servs,
				dates,
				about,
				ahero,
				netws,
				mochu,
				quots,
				tests,
				cases,
				prvcy,
				setLang
			}}
		>
			{children}
		</LanguageContext.Provider>
	)

}}

export {
	useLanguageContext
}