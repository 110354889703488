
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'

export function HeadText(props) {
	return (
		<StyledHeadText>
			<Fade>
			<h1>{props.title}</h1>
			{props.sub && <h4>{props.sub}</h4>}
			<p>{props.text}</p>
			</Fade>
		</StyledHeadText>
	)
}

const StyledHeadText = styled.div`
	
	display: grid;
	padding: 0 4rem;
	grid-gap: 0.5rem;

	& > h1 {
		font-weight: 600;
		font-size: 3.125rem;
	}

	& h4 { 
		font-weight: 400;
		margin-bottom: 2rem;
		letter-spacing: 0.2rem;
		text-transform: uppercase;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	& p {
		font-weight: 400;
		white-space: pre-wrap;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	@media screen and (max-width: 900px) {

		padding: 0 2rem;

		& > h1 {
			font-size: 2.4rem;
		}

	}

	@media screen and (max-width: 500px) {

		& > h1 {
			font-size: 1.9rem;
		}

	}

`

export function ImageBanner(props) {
	return(
		<StyledImageBanner className='img-baner' $props={props}>
			<Fade>
			<img src={props.url} />
			<div>
				<h3>{props.title}</h3>
				<p>{props.txt}</p>
				<ul>
					{props.list.map((item, idx)=>
						<li key={idx}>{item}</li>
					)}
				</ul>
			</div>
			</Fade>
		</StyledImageBanner>
	)
}


const StyledImageBanner = styled.div`
	
    height: 0;
    width: 100%;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: ${({$props})=>$props.aspect};
	// background-image: url(${({$props})=>$props.url});

	& img {
		position: absolute;
		top: 0;
		left: 0;
		inset: 0;
		z-index: -1;
	}

	&:before {
		inset: 0;
		content: '';
		position: absolute;
		background: linear-gradient(to right, ${({$props})=>$props.grad} 0%, ${({$props})=>$props.grad} ${({$props})=>$props.grdstp}, #0000 ${({$props})=>$props.grdend});
	}

	& > div {
		gap: 1.5rem;
		margin: auto;
		display: grid;
		position: absolute;
		height: max-content;
		max-width: min(50%, 450px);
		top: ${({$props})=>$props.pos[0]};
		left: ${({$props})=>$props.pos[3]};
		right: ${({$props})=>$props.pos[1]};
		bottom: ${({$props})=>$props.pos[2]};
		text-align: ${({$props})=>$props.align};
		color: ${({theme})=>theme.text.color.scheme1};
	}

	& > div h3 {
		font-weight: 600;
		font-size: 1.6rem;
		text-transform: uppercase;
	}

	& > div p {
		font-weight: 300;
		font-size: 1.4rem;
	}

	& ul {
		gap: 1rem;
		display: grid;
	}

	& ul li {
		list-style-type: none
	}

	@media screen and (max-width: 900px) {

		& > div{
			gap: 1rem;
		}

		& ul {
			gap: 0.5rem;
		}

	}

	@media screen and (max-width: 580px) {

		& ul {
			display: none;
		}

		& > div {
			top: 0;
			bottom: 0;
			left: 1rem;
			max-width: min(80%, 600px);
		}

	}

`

export function CustomList(props) {
	return(
		<StyledCustomList 	
			$ncols={props.ncols} 
			$marker={props.marker} >
			<Fade>
			{props.title && 
				<h1 className='list-title'>
					{props.title}
				</h1>
			}
			<p>{props.text}</p>
			<ul>
				{props.items.map((item, idx)=>
					<li  
						key={idx}
						dangerouslySetInnerHTML={{
							__html: 
          					item
          				}}>
					</li>)}
			</ul>
			</Fade>
		</StyledCustomList>
	)
}

const StyledCustomList = styled.div`

	display: grid; 
	padding: 0 4rem;

	& > h1 {
		font-weight: 500;
		margin-bottom: 2rem;
		color: ${({theme})=>theme.text.color.scheme3}
	}

	& > ul {
		display: grid;
		row-gap: 2rem;
		margin-top: 4rem;
		column-gap: min(20%, 8rem);
		padding: 0 ${({$marker})=>$marker?'3rem':'0'};
		grid-template-columns: repeat(${({$ncols})=>$ncols}, 1fr);
	}

	& > ul li {
		position: relative;
		list-style-type: none;
		white-space: pre-wrap;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	& > ul li span {
		font-weight: 600;
		color: ${({theme})=>theme.text.color.scheme4};
	}

	& > ul li::before {
		content: '';
		width: 1rem;
		left: -3rem;
		height: 1rem;
		font-size: 5rem;
		border-radius: 50%;
		position: absolute;
		display: ${({$marker})=>$marker?'auto':'none'};
		background: ${({theme})=>theme.text.color.scheme4};
		border: 0.3rem solid ${({theme})=>theme.text.color.scheme1};
		outline: 0.2rem solid ${({theme})=>theme.text.color.scheme4};
	}

	& p {
		font-weight: 400;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	@media screen and (max-width: 900px) {

		padding: 0 2rem;

		& > ul {
			grid-template-columns: 1fr;
		}

	}

`