
import { useContext, createContext } from 'react'
import { useReducer, useState, useEffect } from 'react'
import axios from 'axios'

import { useUsersContext } from '../users/UsersState'

const ArticlesContext = createContext()

const useArticlesContext = () => {
	return useContext(ArticlesContext)
}

const apiPath = '/api/v1/articles'

const queryWrapper = (fn) => {
	return async (...args) => {
		try {
			let res = await fn(...args)
			return {ok: true, ...res}
		} catch(err) {
			console.log(err)
			let msg = err.response?.data?.msg ?? err.message ?? 'Bad Request.'
			console.log(msg)
			return {ok: false, msg}
		}
	}
}

export default function ArticlesState({children}) {

	const { token } = useUsersContext()
	const [ articles, setArticles ] = useState([])

	useEffect(()=>{
		getArticles()
	}, [])

	const getArticles = queryWrapper( async() => {
		let res = await axios.get(apiPath + '/all')
		setArticles(res.data.articles)
	})

	const getArticle = queryWrapper( async(id) => {
		let res = await axios.get(apiPath + `/${id}`)
		return res
	})

	const deleteArticle = queryWrapper( async(uuid) => {
		let res = await axios.delete(apiPath + `/${uuid}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})
		getArticles()
		return res
	})

	const putArticle = queryWrapper( async(data, image) => {
		let uuid = data.ES.uuid
		let prevImage = data.ES.image
		let prevSmall = data.ES.imagemini
		let res = await axios.put(apiPath + `/${uuid}`, data, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})
		if (!res.data.ok||!image) {
			getArticles()
			return res
		}
 		const formData = new FormData();
 		formData.append('image', image)

		let imgRes = await axios.put(apiPath + `/image/${uuid}?prevImg=${prevImage},${prevSmall}`, formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data'
			}		
		})

		if (!imgRes.data.ok) return imgRes

		getArticles()		
	})

	const postArticle = queryWrapper( async(data, image) => {
		let res = await axios.post(apiPath + '/', data, {
			headers: {
				Authorization: `Bearer ${token}`
			}		
		})

		if (!res.data.ok) return res
 		const formData = new FormData();
 		formData.append('image', image)

		let imgRes = await axios.put(apiPath + `/image/${res.data.uuid}?clean=true`, formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data'
			}		
		})

		if (!imgRes.data.ok) return imgRes

		getArticles()
	})

	return (
		<ArticlesContext.Provider value={{
			articles,
			getArticle,
			putArticle,
			postArticle,
			getArticles,
			deleteArticle
		}}>
			{children}
		</ArticlesContext.Provider>
	)

}

export {
	useArticlesContext
}