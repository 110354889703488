
// Pages
import Home from './pages/Home'
import NIIF from './pages/NIIF'
import Team from './pages/Team'
import Blog from './pages/Blog'
import Users from './pages/Users'
import Login from './pages/Login'
import Privacy from './pages/Privacy'
import AboutUs from './pages/AboutUs'
import Clients from './pages/Clients'
import Contact from './pages/Contact'
import Mochuelo from './pages/Mochuelo'
import Services from './pages/Services'
import Articles from './pages/Articles'
import Dashboard from './pages/Dashboard'
import Auditoria from './pages/Auditoria'
import Laborales from './pages/Laborales'
import Cambiarios from './pages/Cambiarios'
import BackOffice from './pages/BackOffice'
import Tributarios from './pages/Tributarios'

// Components
import Scene from './components/Scene'
import Header from './components/Header'
import Footer from './components/Footer'
import CaseSection from './components/CaseSection'
import GlobalStyles from './components/styles/Global.js'

// ThirdParty
import { ThemeProvider } from 'styled-components'
import { Routes, Route, Navigate } from 'react-router-dom'

// Contexts
import { useUsersContext } from './contexts/users/UsersState'
import { useLanguageContext } from './contexts/language/LanguageState'

// Data
import { theme } from './data/AppData'

const ProtectedRoute = ({children}) => {

	const { user } = useUsersContext()

	if (!user.userId) {
		return <Navigate to='/Login' element={<Login />} />
	} 
	return children 
}

export default function App() {

	const { links, footr } = useLanguageContext()

	return (
		<ThemeProvider theme = {theme} >
		<GlobalStyles />
		<div className='app'>
			<Header links={links} />
			<Scene />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/home' element={<Home />} />
				<Route 
					path='/servicios' 
					element={<Services links={links.at(1).options} />}
				>
					<Route path='laborales' element={<Laborales />} />
					<Route path='contabilidad&niif' element={<NIIF />} />
					<Route path='auditoria&aseguramiento' element={<Auditoria />} />
					<Route path='cambiarios' element={<Cambiarios />} />
					<Route path='backoffice' element={<BackOffice />} />
					<Route path='tributarios' element={<Tributarios />} />
				</Route>
				<Route path='/nosotros' element={<AboutUs />} />
				<Route path='/clientes' element={<Clients />} >
					<Route path=':id' element={<CaseSection />} />
				</Route>
				<Route path='/contacto' element={<Contact />} />
				<Route path='/mochuelo' element={<Mochuelo />} />
				<Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
					<Route path='team' element={<Team />} />
					<Route path='users' element={<Users />} />
					<Route path='articles' element={<Articles />} />
				</Route>
				<Route path='/Login' element={<Login />} />
				<Route path='/blog/:uuid' element={<Blog />} />
				<Route path='/privacy' element={<Privacy />} />
			</Routes>
			<Footer data={footr} />
		</div>
		</ThemeProvider>
	)

}