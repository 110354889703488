
const testsES = [
	{
		name: 'Helena Barón',
		from: 'Gerente Financiera Posse Herrera Ruiz',
		text: 'Hemos contado con el apoyo de NDR Asesorías por más de 20 años durante los cuales su servicio se ha destacado por el profesionalismo, compromiso, calidad e interés permanente en atender las necesidades de sus clientes. Con los cambios permanentes en la normatividad tributaria, NDR Asesorías nos ofrece una consultoría que denota su permanente actualización y dedicado análisis',
		img : './images/testimonials/helena-baron.jpg'
	},
	{
		name: 'Andrés Arboleda',
		from: 'Director General Digital Active',
		text: 'Me complace recomendar a la firma NDR para cualquiera de sus necesidades de contabilidad, asesoría financiera o tributaria. He estado trabajando con NDR durante los últimos nueve años y siempre he estado satisfecho por su profesionalismo, atención al detalle y compromiso con el servicio al cliente. NDR tiene un equipo altamente cualificado de contadores, asesores financieros y tributaristas que tienen una amplia experiencia en una variedad de industrias. Siempre están dispuestos a tomarse el tiempo para explicar los conceptos complejos de manera clara y concisa, y siempre están dispuestos a ir más allá para asegurarse de que comprendo mis opciones y tome la mejor decisión para mi negocio',
		img : './images/testimonials/andres-arboleda.jpg'
	},
	{
		name: 'José Antonio Ocampo',
		from: 'Ex ministro de Hacienda - Profesor Universitario',
		text: 'He recibido a lo largo de muchos años servicios de NDR Asesorías de elaboración de formularios de mis impuestos de renta, contactos con la DIAN y facturación electrónica. Doy testimonio de la alta calidad y oportunidad de los servicios de la empresa, pero además de la amabilidad de su gerente y todo su equipo',
		img : './images/testimonials/jose-antonio-ocampo.jpg'
	},
	{
		name: 'José Carlos Parra',
		from: 'Gerente General Asmi Química de Colombia S.A.S',
		text: 'Lo más difícil para cruzar a otro país es tener un equipo local y de confianza, estos últimos 6 años NDR supero todas mis efectivas a nivel DIAN y todo lo administrativo fue delegado a su equipo. Sin NDR, ASMI Química de Colombia SAS, no hubiera sido igual de exitoso. NDR es un 10/10 como socio integral administrativo',
		img : './images/testimonials/jose-carlos-parra.jpg'
	},
	{
		name: 'Fernando Aldana',
		from: 'Gerente General Recordati Rare Diseases',
		text: 'Trabajar con NDR Asesorías ha sido muy constructivo. La integralidad, transparencia y compromiso del equipo liderado por Nayda son claves para asegurar la viabilidad de nuestro negocio con los estándares de cumplimiento exigidos por nuestra corporación a nivel global',
		img : null
	}
]

const testsEN = [
	{
		name: 'Helena Barón',
		from: 'Financial Manager at Posse Herrera Ruiz',
		text: 'We have had the support of NDR Asesorías for over 20 years, during which their service has stood out for its professionalism, commitment, quality, and ongoing interest in meeting the needs of their clients. With the constant changes in tax regulations, NDR Asesorías provides us with consultancy that reflects their continuous updating and dedicated analysis',
		img : './images/testimonials/helena-baron.jpg'
	},
	{
		name: 'Andrés Arboleda',
		from: 'CEO at Digital Active',
		text: 'I am pleased to recommend NDR firm for any of your accounting, financial, or tax advisory needs. I have been working with NDR for the past nine years and have always been satisfied with their professionalism, attention to detail, and commitment to customer service. NDR has a highly qualified team of accountants, financial advisors, and tax experts with extensive experience across various industries. They are always willing to take the time to explain complex concepts clearly and concisely, and they consistently go the extra mile to ensure I understand my options and make the best decision for my business',
		img : './images/testimonials/andres-arboleda.jpg'
	},
	{
		name: 'José Antonio Ocampo',
		from: 'Former Minister of Finance - College Professor',
		text: "Over the course of many years, I have received services from NDR Asesorías for the preparation of my income tax forms, interactions with the tax authority (DIAN), and electronic invoicing. I attest to the high quality and timeliness of the company's services, as well as the kindness of its manager and entire team",
		img : './images/testimonials/jose-antonio-ocampo.jpg'
	},
	{
		name: 'José Carlos Parra',
		from: 'CEO at Asmi Química de Colombia S.A.S',
		text: "The most difficult thing about moving to another country is having a local and trustworthy team. In the last 6 years, NDR exceeded all my expectations at the DIAN level, and all administrative tasks were delegated to their team. Without NDR, ASMI Química de Colombia SAS wouldn't have been as successful. NDR is a 10/10 as a comprehensive administrative partne",
		img : './images/testimonials/jose-carlos-parra.jpg'
	},
	{
		name: 'Fernando Aldana',
		from: 'CEO at Recordati Rare Diseases',
		text: 'Working with NDR Asesorías has been highly constructive. The comprehensiveness, transparency, and commitment of the team led by Nayda are essential to ensure the viability of our business within the compliance standards required by our global corporation',
		img : null
	}
]

const casesES = [
	{
		title: 'Consultoría y acompañamiento empresarial',
		text : 'Geotec, reconocida empresa internacional, expandió sus operaciones a Chile, enfrentando desafíos contables, tributarios, laborales y administrativos. Adaptarse a la legislación chilena, gestionar impuestos, cambio de divisas, contratación de personal en Colombia y visados, fue fundamental.\n\nBrindamos un apoyo integral desde el principio, asegurando que cumplieran con las regulaciones chilenas, gestionando sus obligaciones fiscales, optimizando el proceso de cambio de divisas, y facilitando la contratación de personal en Colombia y trámites de visados.\n\nGracias a nuestra asesoría, Geotec estableció y expandió con éxito sus operaciones en Chile, logrando cumplimiento fiscal y contable, eficiencia en operaciones de cambio de divisas, y una contratación de personal sin problemas en Colombia. Nuestro enfoque integral es clave para el éxito de las empresas en nuevos mercados.',
		img  : '../images/cases/Geotec.png'
	},
	{
		title: 'Fi Group',
		text : 'Fi Group confió en nuestro servicio de asesoría para acompañar a sus clientes en la obtención de beneficios fiscales en proyectos de innovación, investigación, ciencia y tecnología.\n\nNuestra asesoría especializada permitió a Fi Group brindar un apoyo integral a sus clientes, maximizando los beneficios fiscales disponibles. Trabajamos en estrecha colaboración para identificar y aprovechar oportunidades fiscales, garantizando un impacto positivo en sus proyectos de innovación.\n\nGracias a nuestra asociación, Fi Group ha logrado ayudar a sus clientes a optimizar sus inversiones en I+D y proyectos tecnológicos, impulsando el crecimiento y la competitividad en el mercado. Estamos comprometidos a continuar brindando asesoría experta en beneficios fiscales y aportando al éxito de proyectos innovadores.',
		img  : '../images/cases/Fi-groupe.jpg'
	},
	{
		title: 'Caso de auditoría',
		text : 'Nuestra empresa ha implementado procesos de auditoría en conformidad con las normas internacionales en la parte financiera de Posse Herrera Ruiz.\n\nNuestra sólida experiencia en auditoría y conformidad con las normativas internacionales ha permitido a Posse Herrera Ruiz mantener una transparencia financiera sólida y cumplir con los estándares globales en sus operaciones. Este caso demuestra nuestro compromiso de brindar servicios de auditoría de alta calidad y contribuir a la confianza y solidez de nuestros clientes. Estamos comprometidos a continuar siendo un socio confiable en auditoría financiera.',
		img  : '../images/cases/Posse.jpg'
	},	
]

const casesEN = [
	{
		title: 'Consulting and business support',
		text : 'Geotec, reconocida empresa internacional, expandió sus operaciones a Chile, enfrentando desafíos contables, tributarios, laborales y administrativos. Adaptarse a la legislación chilena, gestionar impuestos, cambio de divisas, contratación de personal en Colombia y visados, fue fundamental.\n\nBrindamos un apoyo integral desde el principio, asegurando que cumplieran con las regulaciones chilenas, gestionando sus obligaciones fiscales, optimizando el proceso de cambio de divisas, y facilitando la contratación de personal en Colombia y trámites de visados.\n\nGracias a nuestra asesoría, Geotec estableció y expandió con éxito sus operaciones en Chile, logrando cumplimiento fiscal y contable, eficiencia en operaciones de cambio de divisas, y una contratación de personal sin problemas en Colombia. Nuestro enfoque integral es clave para el éxito de las empresas en nuevos mercados.',
		img  : '../images/cases/Geotec.png'
	},
	{
		title: 'Fi Group case',
		text : 'Fi Group confió en nuestro servicio de asesoría para acompañar a sus clientes en la obtención de beneficios fiscales en proyectos de innovación, investigación, ciencia y tecnología.\n\nNuestra asesoría especializada permitió a Fi Group brindar un apoyo integral a sus clientes, maximizando los beneficios fiscales disponibles. Trabajamos en estrecha colaboración para identificar y aprovechar oportunidades fiscales, garantizando un impacto positivo en sus proyectos de innovación.\n\nGracias a nuestra asociación, Fi Group ha logrado ayudar a sus clientes a optimizar sus inversiones en I+D y proyectos tecnológicos, impulsando el crecimiento y la competitividad en el mercado. Estamos comprometidos a continuar brindando asesoría experta en beneficios fiscales y aportando al éxito de proyectos innovadores.',
		img  : '../images/cases/Fi-groupe.jpg'
	},
	{
		title: 'Audit case',
		text : 'Nuestra empresa ha implementado procesos de auditoría en conformidad con las normas internacionales en la parte financiera de Posse Herrera Ruiz.\n\nNuestra sólida experiencia en auditoría y conformidad con las normativas internacionales ha permitido a Posse Herrera Ruiz mantener una transparencia financiera sólida y cumplir con los estándares globales en sus operaciones. Este caso demuestra nuestro compromiso de brindar servicios de auditoría de alta calidad y contribuir a la confianza y solidez de nuestros clientes. Estamos comprometidos a continuar siendo un socio confiable en auditoría financiera',
		img  : '../images/cases/Posse.jpg'
	},	
]

export {
	casesES,
	casesEN,
	testsES,
	testsEN
}