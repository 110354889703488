
import styled from 'styled-components'

export const StyledDashboard = styled.div`

	body:has(&) .header,
	body:has(&) footer {
		display: none;
	}

	display: grid;
	position: relative;
	grid-template-columns: min-content 1fr;

	.dashboard-header {
		z-index: 3;
		width: 100%;
		height: 80px;
		display: grid;
		padding: 0 2rem;
		position: fixed;
		background: #fff;
		align-items: center;
		justify-items: center;
		box-shadow: 4px 0 10px 0px #0002;
		grid-template-columns: min-content 1fr min-content min-content;
	}

	.dashboard-header img {
		width: 15rem;
		cursor: pointer;
	}

	.dashboard-header .logo-small {
		width: 3rem;
		display: none
	}

	.dashboard-header i {
		cursor: pointer;
	}

	.dashboard-menu {
		top: 0px;
		grid-gap: 1rem;
		padding: 4rem 0;
		position: sticky;
		display: inline-grid;
		max-width: max-content;
		min-height: max-content;
		align-content: flex-start;
		height: calc(100vh - 80px);
		transform: translateY(80px);
		min-width: min(100vw, 300px);
		box-shadow: 4px 4px 10px 0px #0002;
	}

	.dashboard-menu a {
		min-width: 100%;
		padding: 1rem 30px;
		position: relative;
		color: ${({theme})=>theme.text.color.scheme4};
	}

	.dashboard-menu a:hover:not(.is-active) {
		color: ${({theme})=>theme.text.color.scheme3};
	}

	.dashboard-menu a.is-active {
		color: #fff;
		padding-left: 60px;
		background: ${({theme})=>theme.text.color.scheme4};
	}

	.dashboard-menu a.is-active::before {
		top: 0;
		bottom: 0;
		left: 30px;
		width: 10px;
		content: '';
		height: 10px;
		margin: auto;
		background: #fff;
		position: absolute;
		border-radius: 50%;
	}

	.dashboard-content {
		width: 100%;
		padding: 0 2rem;
		position: relative;
		display: inline-block;
		padding-bottom: 2rem;
		padding-top: calc(80px + 3rem);
		grid-column: ${({$showMenu})=>$showMenu?'':'1 / span 2'}

	}


	@media screen and (max-width: 450px) {

		.dashboard-header .logo {
			display: none
		}

		.dashboard-header .logo-small {
			display: inline-block
		}

	}	

	@media screen and (max-width: 750px) {

		.dashboard-menu  {
			z-index: 3;
			background: #fff;
			position: absolute;
		}

		.dashboard-content {
			grid-column: 1 / span 2;
		}

	}
	
`