

import './Mochuelo.css'

// Thirdparty
import Fade from 'react-reveal/Fade'
import { useEffect, useState } from 'react'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'

export default function Mochuelo() {

	const [ imgs, setImgs ] = useState([])
	const { lang, mochu } = useLanguageContext()

	useEffect(()=>{
		getImgs()
		window.scrollTo(0, 0)
	}, [])

	async function getImgs() {

		let imgsArray = []
		for (let i=imgs.length; i<imgs.length + 14; i++) {
			const res = await fetch(`./images/mochuelo/${i+1}.JPG`)
			try {
				if (!res.ok || res.status!==200) continue
				const blob = await res.blob()
				const bmp = await createImageBitmap(blob)
	  			const { width, height } = bmp
	  			const aspect = width / height
	  			imgsArray = [...imgsArray, URL.createObjectURL(blob)]				
			}
			catch {
				continue
			}
		}

		setImgs(prev=>[...prev, ...imgsArray])
	}

	return (
		<div className='mochuelo-page'>
			<Fade>
			<div className='mochuelo-page--hero'>
				<h2>{mochu.hlabel}</h2>
				<img src='./images/hero-banners/mochuelo-hero-img.webp' />
			</div>
			</Fade>
			<Fade>
			<p>{mochu.text}</p>
			</Fade>
			<div className='mochuelo-page--grid'>
				{imgs.map(url=>
					<img 
						key={url}
						src={url} 
						className='mochuelo-img' 
						style={{objectFit: 'cover', height: '100%'}}
					/>
				)}
			</div>
			<div 
				onClick={getImgs}
				className='mochuelo-page--more'>
				{lang==='ES'?'Cargar más':'Load more'}
			</div>
		</div>
	)

}