
import styled from 'styled-components'

export default function CustomSpinner({ size = 250 }) {

	return (
		<StyledCustomSpinner
			$size={size}
		 />
	)
}

const StyledCustomSpinner = styled.div`


opacity: 0.7;
position: relative;
height: calc(${({$size})=>$size} * 1px);

&::before,
&::after {
	content: '';	
	position: absolute;
	inset: 0;
	margin: auto;
	border-radius: 50%;
	border: 5px solid transparent;
}

&::before {
	width: 60px;
	height: 60px;
	animation: animSpinnerDiv 2.5s infinite linear;
	border-top: 5px solid ${({theme})=>theme.text.color.scheme4};
	border-left: 5px solid ${({theme})=>theme.text.color.scheme4}
}

&::after {
	width: 30px;
	height: 30px;
	animation: animSpinnerDiv 4s infinite reverse linear;
	border-right: 5px solid ${({theme})=>theme.text.color.scheme4};
	border-bottom: 5px solid ${({theme})=>theme.text.color.scheme4}
}

@keyframes animSpinnerDiv {

	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}

}

`

