
import styled from 'styled-components'

export const StyledContact = styled.div`

	max-width: 100%;
	background: #F1F1F1;

	.contact-form {
		gap: 2rem;
		margin: auto;
		display: grid;
		background: #fff;
		padding: 0 0 0 2rem;
		grid-template-columns: 1fr 1fr;
	}

	.contact-form > div:first-child {
		padding: 0;
		background: inherit;
		padding-bottom: 4rem ;
		width: 100% !important;
		max-width: auto !important;
	}

	.contact-links {
		gap: 2rem;
		margin: auto;
		display: grid;
		padding: 6rem 3rem;
		justify-items: center;
		width: min(1200px, calc(100% - 4rem));
		grid-template-columns: repeat(4, 1fr);
	}

	.contact-links h4 {;
		margin-bottom: 1.5rem;
	}

	.contact-links h4 i {
		font-size: 1.5rem;
		margin-bottom: 1rem;
		color: ${({theme})=>theme.text.color.scheme4};
	}

	.contact-links div {
		height: 100%;
		margin: auto;
		display: grid;
		text-align: center;
		align-content: flex-start;
	}

	.contact-links a {
		white-space: pre-wrap;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	.contact-links div:first-child a {
		pointer-events: none
	}


	@media screen and (max-width: 900px) {

		.contact-links {
			padding: 2rem;
			grid-template-columns: repeat(2, 1fr);
		}	

		.contact-links div {
			width: 100%;
			justify-items: center;
		}

		.contact-links div a {
			text-align: center
		}

		.contact-links h4 {
			text-align: center;
		}

		.contact-form {
			justify-content: center;
			grid-template-columns: 1fr;
			grid-auto-rows: 1fr;
			padding: 0;
		}

	}


	@media screen and (max-width: 450px) {

		.contact-links {
			grid-template-columns: repeat(1, 1fr);
		}	

	}

	@media screen and (min-width: 1400px) {

		.contact-form {
			justify-content: center;
			grid-template-columns: 700px 700px;
		}

	}

	@media (hover: none) {

		.contact-links div:first-child a {
			pointer-events: auto
		}
	
	}


`