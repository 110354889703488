
import styled from 'styled-components'

export const StyledContactSection = styled.div`

	margin: auto;
	display: grid;
	padding: 5rem 0;
	text-align: center;
	align-items: center;
	background: #FAFAFA;
	justify-items: center;
	justify-content: center;
	max-width: min(1200px, calc(100% - 7rem));

	& > .contact-section--div {
		display: grid;
		padding: 2rem;
		grid-gap: 2rem;
	}

	& > .contact-section--div h1 {
		font-size: 2.5rem;
		font-family: poppins;
		color: ${({theme})=>theme.text.color.scheme4};
	}

	& > .contact-section--div p {
		color: ${({theme})=>theme.text.color.scheme3};
	}

	& > form > * {
		border: none;
		padding: 1rem;
		font-size: 1rem;
		background: #0001;
	}

	& > form {
		max-width: 100%;
		display: grid;
		grid-gap: 1.5rem;
		grid-auto-rows: 1fr;
		grid-template-columns: 1fr 1fr;
	}

	& > form textarea {
		resize: none;
		grid-row: 3 / span 2;
		grid-column: 1 / span 2;
	}

	& > form button {
		// cursor: pointer;
		border-radius: 6px;
		width: max-content;
		align-self: center;
		justify-self: center;
		padding: 0.7rem 2.25rem;
		color: ${({theme})=>theme.text.color.scheme1};
		background: ${({theme})=>theme.text.color.scheme4};
		transition: all 0.5s;
	}

	& > form button.spin {
		width: 40px;
		height: 40px;
		display: grid;
		padding: 0.5rem;
		border-radius: 50%;
		transition: all 0.5s;
		place-content: center;
	}

	& .contact-form--queryinfo {
		background: none;
	}

	.loader {
		animation: spinAnimation 2s linear infinite;
	}

	@keyframes spinAnimation {
		0% {
			transform: rotate(0deg)
		}
		100% {
			transform: rotate(360deg)
		}
	}

	@media screen and (max-width: 400px) {

		& > form > * {
			padding: 0.5rem;
		}

		& > form {
			grid-template-columns: 1fr;
		}

		& > form textarea {
		  resize: none;
		  grid-column: auto;
		  grid-row: 5 / span 2;
		}

		& > form button {
			margin: auto
		}

	}	

	@media screen and (max-width: 900px) {

		grid-template-columns: 1fr;

	}


`