
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

	*  {
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		font-family: poppins, sans-serif;
	}

	body {
		max-width: 100%;
		overflow-x: hidden;
		background: ${({theme}) => theme.body.bg};
	}

	img {
		width: 100%
	}

	a {	
		cursor: pointer;
		text-decoration: none;
		color: ${({theme}) => theme.text.color.scheme1}
	}
	
	a:hover {
		color: ${({theme}) => theme.text.color.scheme4} 
	}

	a.is-active {
		color: ${({theme}) => theme.text.color.scheme4} 
	}

	p {
		font-size: 1rem
	}

	h1 {
		font-size: 2.125 rem
	}

	h2 {
		font-size: 1.875 rem
	}

	h3 {
		font-size: 1.5 rem
	}

	h4 {
		font-size: 1.25 rem
	}

	h5 {
		font-size: 1.125 rem
	}

	h6 {
		font-size: 1 rem
	}


	@media screen and (max-width: 749px) {

		:root {
			font-size: 12.5px
		}

	}
`

export default GlobalStyles