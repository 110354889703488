
import NavLink from './NavLink'
import { useNavigate } from 'react-router-dom'
import { StyledPopupMenu } from './styles/PopupMenu.styled'

export default function PopupMenu({ links, click, show, abs, action }) {

	const navigate = useNavigate()

	const handleClick = (url) => {
		if (click) click()
		navigate((links.url??'') + url)
	}

	return (
		<StyledPopupMenu className={`${show} ${abs?'floating':''}`}>
			{links.options.map(link=>
				<div key={link.label??link}>
				{!action &&
					<a  
						onClick={e=>handleClick(link.url)}
						// to={(links.url??'') + link.url}
					>
						{link.label??link}
					</a>
				}
				{action && 
					<a 
						onClick={e=>action(link.url??link)}
					>
						{link.label??link}
					</a>
				}
				</div>
			)}
		</StyledPopupMenu>
	)
}