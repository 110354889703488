
import styled from 'styled-components'

export const StyledBackOffice = styled.div`
	
	gap: 5rem;
	margin: auto;
	display: grid;
	padding: 7rem 0rem 0;
	position: relative;
	width: min(calc(100% - 1rem), 1200px);

	& h3 {
		font-weight: 400;
		line-height: 2.5rem;
	}

	.backoffice-contact {
		width: 100%;
		height: 20rem;
		display: grid;
		place-content: center;
		background: ${({theme}) => theme.text.color.scheme4}; 
	}

	& a {
		font-size: 2rem;
		color: ${({theme}) => theme.text.color.scheme1} !important;
	}


	& a i {
		margin: auto;
		display: grid;
		width: 3.125rem;
		height: 3.125rem;
		border-radius: 50%;
		place-content: center;
		color: ${({theme}) => theme.text.color.scheme4};
		background: ${({theme}) => theme.text.color.scheme1};
	}

	@media screen and (max-width: 900px) {

		& h3 {
			width: 100%;
			font-weight: 400;
			line-height: 1.5rem;
		}

	}

`