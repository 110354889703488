
import NavLink from './NavLink'
import { useNavigate } from 'react-router-dom'
import { StyledHoverGrid } from './styles/HoverGrid.styled'

export default function HoverGrid({ cards }) {

	const navigate = useNavigate()

	const goto = (url) => {
		navigate(url)
		window.scrollTo(0, 0)
	}

	return (
		<StyledHoverGrid>
			{cards.map((card,idx)=>
				<div key={idx}>
					<div className='content-div'>
						<h3>
							{card.title}
						</h3>
						<small>
							{card.text}
						</small>
						<a onClick={e=>goto(card.link.url)}>
							{card.link.label}&emsp;
							<i className="fa-solid fa-circle-chevron-right"></i>
						</a>
					</div>
					<div className='title-div'>
						<a onClick={e=>goto(card.link.url)}>
							{card.title}&emsp;
							<i className="fa-solid fa-circle-chevron-right"></i>
						</a>					
					</div>
				</div>
			)}
			<img src='./images/home/landing-services-img.png' />
		</StyledHoverGrid>
	)
}
