
// Thirdparty
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

// Components
import NavLink from '../components/NavLink'
import CustomHero from '../components/CustomHero'

// styles 
import { StyledServices } from './styles/Services.styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'

export default function Services({ links }) {

	const location = useLocation()
	const path = location.pathname
	const { lang } = useLanguageContext()

	return (
		<StyledServices>
			<div className='outlet'>
				<CustomHero 
					title ={lang==='ES'?'Servicios':'Services'}
					label1={lang==='ES'?'Servicios':'Services'}
					label2={links.find(item=>
						path.includes(item.url))?.label??''}
				/>
				<div className='services-nav'>
					{links.map((link, idx)=>
						<NavLink 
							key={idx}
							to={'/servicios' + link.url}>
							{link.label}
						</NavLink>)}
				</div>
				<Outlet />
			</div>
		</StyledServices>
	)
}
