
import styled from 'styled-components'

export const StyledDots = styled.div`
	
	left: 0;
	right: 0;
	width: 100%;
	margin: auto;
	max-width: 100%;

	& > div {
		gap: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		transition: transform 0.5s;
		transform: translateX(calc(${({$dx})=>$dx} * -1px));
	}

	& .dot {
		opacity: 0.3;
		cursor: pointer;
		transition: all 0.5s;
		display: inline-block;
		border-radius: max(20px, 50%);
		width: calc(${({$w})=>$w} * 1px);
		height: calc(${({$h})=>$h} * 1px);
		background: ${({theme})=>theme.text.color.scheme4};
	}

	& .dot.true {
		opacity: 1;
		border-radius: 20px;
		width: calc(${({$aw})=>$aw} * 1px);
		background: ${({theme})=>theme.text.color.scheme4};
	}	

`