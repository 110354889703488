

const theme = {
	text: {
		color: {
			scheme1: '#fff',
			scheme2: '#000',
			scheme3: '#6B6B6B',
			scheme4: '#0096A3',
		}
	},
	body: {
		bg: '#F8F8F8'
	}
}

const linksEN = [
	{
		label: 'HOME',
		url: '/home',
	},
	{
		label: 'SERVICES', 
		url: '/servicios',
		options: [
			{
				label: 'Accounting & IFRS',
				url: '/contabilidad&NIIF'
			},
			{
				label: 'Taxation',
				url: '/tributarios'
			},
			{
				label: 'Labor',
				url: '/laborales'
			},
			{
				label: 'Exchange',
				url: '/cambiarios'
			},
			{
				label: 'Audit & assurance',
				url: '/auditoria&aseguramiento'
			},
			{
				label: 'Back Office',
				url: '/backoffice'
			}
		]
	},
	{
		label: 'CLIENTS',
		url: '/clientes'
	},
	{
		label: 'ABOUT US',
		url: '/nosotros'
	},
	{
		label: 'CONTACT',
		url: '/contacto'
	},
	{
		label: <i className="fa-solid fa-globe"></i>,
		type: 'action',
		options: [
			{
				label: 'COL-ES',
				url: 'ES'
			}, 
			{
				label: 'CA-EN',
				url: 'EN'
			}
		]
	}
]

const linksES = [
	{
		label: 'INICIO',
		url: '/home',
	},
	{
		label: 'SERVICIOS', 
		url: '/servicios',
		options: [
			{
				label: 'Contabilidad y NIIF',
				url: '/contabilidad&NIIF'
			},
			{
				label: 'Tributarios',
				url: '/tributarios'
			},
			{
				label: 'Laborales',
				url: '/laborales'
			},
			{
				label: 'Cambiarios',
				url: '/cambiarios'
			},
			{
				label: 'Auditoría y aseguramiento',
				url: '/auditoria&aseguramiento'
			},
			{
				label: 'Back Office',
				url: '/backoffice'
			}
		]
	},
	{
		label: 'CLIENTES',
		url: '/clientes'
	},
	{
		label: 'NOSOTROS',
		url: '/nosotros'
	},
	{
		label: 'CONTACTO',
		url: '/contacto'
	},
	{
		label: <i className="fa-solid fa-globe"></i>,
		type: 'action',
		options: [
			{
				label: 'COL-ES',
				url: 'ES'
			}, 
			{
				label: 'CA-EN',
				url: 'EN'
			}
		]
	}
]

const statsES = {
	title: 'Historias de éxito',
	subtl: 'QUE HEMOS HECHO',
	stats: [
		{
			text: 'Clientes actuales',
			value: 193,
			pctg: false,
			curr: false
		},
		{
			text: 'Dinero regresado en compensaciones y devoluciones en 2023',
			value: 2000,
			pctg: false,
			curr: true
		},
		{
			text: 'Total clientes de auditoría NDR',
			value: 9,
			pctg: false,
			curr: false
		},
		{
			text: 'Total clientes de auditoría AAC',
			value: 18,
			pctg: false,
			curr: false
		}
	]
}

const statsEN = {
	title: 'Success histories',
	subtl: 'WE MADE POSSIBLE',
	stats: [
		{
			text: 'Clients currently',
			value: 193,
			pctg: false,
			curr: false
		},
		{
			text: 'Money returned compensations and refunds in 2023',
			value: 2000,
			pctg: false,
			curr: true
		},
		{
			text: 'Number of Audit clients NDR have had',
			value: 9,
			pctg: false,
			curr: false
		},
		{
			text: 'Number of Audit clients AAC have had',
			value: 18,
			pctg: false,
			curr: false
		}
	]
}

const contactES = {
	title: 'Contáctanos',
	text : 'Sentimos los negocios de nuestros clientes como propios y trabajamos en pro de prestarles el mejor servicio posible. Déjanos tus datos para poder ser tus aliados estratégicos',
	placeholders: {
		name    : 'Nombre y apellido*',
		email   : 'Correo*',
		subject : 'Asunto*',
		phone   : 'Teléfono*',
		text    : 'Mensaje*',
		button  : 'Envíar'
	}
}

const contactEN = {
	title: 'Contact us',
	text : 'We feel our clients\' businesses are our own and we work to provide them with the best possible service. Leave us your data so we can be your strategic allies',
	placeholders: {
		name    : 'Name and lastname*',
		email   : 'Email*',
		subject : 'Subject*',
		phone   : 'Phone*',
		text    : 'Message*',
		button  : 'Send'
	}
}

const footerES = [
	{
		title: 'ACERCA DE',
		links: [
			{
				label: 'Servicios',
				url: '/servicios/contabilidad&NIIF'
			},
			{
				label: 'Historia',
				url: '/nosotros'
			},
			{
				label: 'Responsabilidad Social',
				url: '/mochuelo'
			}
		]
	},
	{
		title: 'CATEGORIAS',
		links: [
			{
				label: 'Contabilidad y NIIF',
				url: '/servicios/contabilidad&NIIF'
			},
			{
				label: 'Tributarios',
				url: '/servicios/tributarios'
			},
			{
				label: 'Laborales',
				url: '/servicios/laborales'
			},
			{
				label: 'Cambiarios',
				url: '/servicios/cambiarios'
			},
			{
				label: 'Auditoría y aseguramiento',
				url: '/servicios/auditoria&aseguramiento'
			},
			{
				label: 'Back Office',
				url: '/servicios/backoffice'
			}
		]
	},
	{
		title: 'LINKS ADICIONALES',
		links: [
			{
				label: 'Artículos',
				url: '/blog/0'
			},
			{
				label: 'Política de privacidad',
				url: '/privacy'
			},
			{
				label: 'Dashboard',
				url: '/dashboard'
			}
		]
	},
	{
		title: 'CONTACTO',
		links: [
			{
				label: 'Carrera 19 No. 82-85\nOficina 603\nBogotá, Colombia',
				url: 'https://www.google.com/maps/place/Edificio+Country+Office/@4.6695866,-74.0609342,17z/data=!3m1!4b1!4m6!3m5!1s0x8e3f9af43bcb41a1:0x5bef8a8bb5f59134!8m2!3d4.6695813!4d-74.0583593!16s%2Fg%2F11g9nrbn5q?hl=es&entry=ttu',
				blank: true
			},
			{
				label: 'Teléfonos: 7564400 – 7564402',
				url: ''
			},
			{
				label: 'Correo: contacto@ndrasesorias.net',
				url: 'mailto: contacto@ndrasesorias.net',
				blank: true
			}
		]
	}
]

const footerEN = [
	{
		title: 'ABOUT',
		links: [
			{
				label: 'Services',
				url: '/servicios/contabilidad&NIIF'
			},
			{
				label: 'History',
				url: '/nosotros'
			},
			{
				label: 'Social awareness',
				url: '/mochuelo'
			}
		]
	},
	{
		title: 'CATEGORIES',
		links: [
			{
				label: 'Accounting & IFRS',
				url: '/servicios/contabilidad&NIIF'
			},
			{
				label: 'Taxation',
				url: '/servicios/tributarios'
			},
			{
				label: 'Labor',
				url: '/servicios/laborales'
			},
			{
				label: 'Exchange',
				url: '/servicios/cambiarios'
			},
			{
				label: 'Audit & assurance',
				url: '/servicios/auditoria&aseguramiento'
			},
			{
				label: 'Back Office',
				url: '/servicios/backoffice'
			}
		]
	},
	{
		title: 'ADITIONAL LINKS',
		links: [
			{
				label: 'Articles',
				url: '/blog/0'
			},
			{
				label: 'Privacy policy',
				url: '/privacy'
			},
			{
				label: 'Dashboard',
				url: '/dashboard'
			}
		]
	},
	{
		title: 'CONTACT',
		links: [
			{
				label: 'Carrera 19 No. 82-85\nOffice 603\nBogotá, Colombia',
				url: 'https://www.google.com/maps/place/Edificio+Country+Office/@4.6695866,-74.0609342,17z/data=!3m1!4b1!4m6!3m5!1s0x8e3f9af43bcb41a1:0x5bef8a8bb5f59134!8m2!3d4.6695813!4d-74.0583593!16s%2Fg%2F11g9nrbn5q?hl=es&entry=ttu',
				blank: true
			},
			{
				label: 'Phone numbers: 7564400 – 7564402',
				url: null
			},
			{
				label: 'Email: contacto@ndrasesorias.net',
				url: 'mailto: contacto@ndrasesorias.net',
				blank: true
			}
		]
	}
]

const mochuES = {
	hlabel: 'NDR entiende el compromiso social que tienen las empresas en la actualidad que buscan dejar huella en las comunidades en donde operan',
	text  : 'Nuestra iniciativa social “Mochuelo” nace como un proyecto familiar que desde hace 30 años acompaña a las madres comunitarias de la escuela local del barrio Mochuelo en Ciudad Bolívar. Este proyecto tiene como meta llevar alegría navideña a más de 1000 niños en condiciones de vulnerabilidad cada año.\n\nA raíz de las necesidades detectadas en un sector vulnerable de nuestro país, con el transcurrir de los años, hemos logrado tejer una red de corazones generosos que se ha convertido en un factor multiplicador de compartir desde el corazón. Actualmente recibimos apoyo de familia, amigos, clientes y personas dispuestas a unirse a nuestro propósito común de compartir el espíritu festivo de la navidad con otros.'
}

const mochuEN = {
	hlabel: 'NDR understands the social commitment that companies have today that seek to leave their mark on the communities where they operate.',
	text  : 'Our social initiative "Mochuelo" was born as a family project that, for the past 30 years, has been accompanying community mothers from the local school in the Mochuelo neighborhood in Ciudad Bolívar. The goal of this project is to bring Christmas joy to over 1000 children in vulnerable conditions each year.\n\nAs a response to the identified needs in a vulnerable sector of our country, over the years, we have managed to weave a network of generous hearts that has become a multiplier for sharing from the heart. Currently, we receive support from family, friends, clients, and individuals willing to join our common purpose of sharing the festive spirit of Christmas with others.'
}

const privacyES = {
	text: 'La autorización suministrada en formularios electrónicos faculta a NDR ASESORÍAS S.A.S. para que recopile, almacene, use y suprima los datos personales aquí suministrados, especialmente, aquellos que son definidos como Datos Sensibles (*).\n\nNDR ASESORÍAS S.A.S. ajustándose a las normas del derecho de Hábeas Data, requiere realizar el "Tratamiento de Datos" antes señalado, de forma tal que la finalidad y uso que NDR Asesorías llevará a cabo de la información suministrada, será netamente académico, administrativo y publicitario. Bajo ninguna circunstancia se realizará Tratamiento de Datos personales con fines de comercialización o circulación. En todo caso aplicarán las excepciones de ley.\n\nEn virtud del artículo 8 de la ley 1581 de 2013, el titular de la información personal que es recopilada en este formulario, tiene los siguientes derechos:\n\nConocer, actualizar, rectificar y suprimir los datos suministrados; Conocer los usos que se han hecho de la información suministrada, cuando así lo solicite el titular; Revocar la autorización y/o solicitar la supresión del dato suministrado cuando en el Tratamiento realizado no se respeten los principios, derechos y garantías constitucionales y legales a favor del titular. Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.\n\nEl titular de la información suministrada, podrá ejercer cualquiera de los derechos mencionados, dirigiendo una petición al correo contacto@ndrasesorias.net. Recibida la solicitud en los términos dispuestos por NDR ASESORÍAS, se le dará trámite al requerimiento según lo establecido por la ley.\n\n(*) Datos Sensibles: Aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación.'
}

const privacyEN = {
	text: 'The authorization provided in electronic forms empowers NDR ASESORIAS S.A.S. to collect, store, use, and delete the personal data provided here, especially those defined as Sensitive Data (*).\n\nNDR ASESORIAS S.A.S., in compliance with the provisions of the Habeas Data Law, requires carrying out the aforementioned "Data Processing" in such a way that the purpose and use that NDR Asesorías will make of the information provided will be purely academic, administrative, and advertising. Under no circumstances will personal data processing be carried out for marketing or circulation purposes. In any case, legal exceptions will apply.\n\nPursuant to Article 8 of Law 1581 of 2013, the owner of the personal information collected in this form has the following rights:\n\nTo know, update, rectify, and delete the data provided; To know the uses that have been made of the information provided when requested by the owner; To revoke the authorization and/or request the deletion of the data provided when the Processing carried out does not respect the constitutional and legal principles, rights, and guarantees in favor of the owner. To access, free of charge, their personal data that have been subject to Processing.\n\nThe owner of the information provided may exercise any of the aforementioned rights by submitting a request to the email contacto@ndrasesorias.net. Once the request is received in the terms established by NDR ASESORIAS, the requirement will be processed according to the law.\n\n(*) Sensitive Data: Those that affect the privacy of the Owner or whose improper use may lead to discrimination.'
}

export {
	theme,
	mochuEN,
	mochuES,
	linksEN,
	linksES,
	statsES,
	statsEN,
	footerEN,
	footerES,
	contactES,
	contactEN,
	privacyES,
	privacyEN
}