
const netwsES = [
	{
		title: <h4><i className="fa-solid fa-phone"></i><br />Teléfonos</h4>,
		links: [
			{
				label: '(601) 7564400',
				url  : 'tel: +576017564402'
			},
			{
				label: '(601) 7564402',
				url  : 'tel: +576017564402'
			}
		]
	},
	{
		title: <h4><i className="fa-solid fa-location-dot"></i><br />Dirección</h4>,
		links: [
			{
				label: 'Carrera 19 No. 82-85\nOficina 603\nBogotá, Colombia',
				url  : 'https://www.google.com/maps/place/Edificio+Country+Office/@4.6695866,-74.0609342,17z/data=!3m1!4b1!4m6!3m5!1s0x8e3f9af43bcb41a1:0x5bef8a8bb5f59134!8m2!3d4.6695813!4d-74.0583593!16s%2Fg%2F11g9nrbn5q?hl=es&entry=ttu'
			}
		]
	},
	{
		title: <h4><i className="fa-regular fa-envelope"></i><br />Correo</h4>,
		links: [
			{
				label: 'contacto@ndrasesorias.net',
				url  : 'mailto: contacto@ndrasesorias.net'
			}
		]
	},
	{
		title: <h4><i className="fa-solid fa-share-nodes"></i><br />Conectate</h4>,
		links: [
			{
				label: <i className="fa-brands fa-linkedin-in"></i>,
				url  : 'https://www.linkedin.com/company/ndradvisoryfirm/'
			}
		]
	}
]

const netwsEN = [
	{
		title: <h4><i className="fa-solid fa-phone"></i><br />Phone numbers</h4>,
		links: [
			{
				label: '(601) 7564400',
				url  : 'tel: +576017564402'
			},
			{
				label: '(601) 7564402',
				url  : 'tel: +576017564402'
			}
		]
	},
	{
		title: <h4><i className="fa-solid fa-location-dot"></i><br />Address</h4>,
		links: [
			{
				label: 'Carrera 19 No. 82-85\nOficina 603\nBogotá, Colombia',
				url  : ''
			}
		]
	},
	{
		title: <h4><i className="fa-regular fa-envelope"></i><br />Email</h4>,
		links: [
			{
				label: 'contacto@ndrasesorias.net',
				url  : 'mailto: contacto@ndrasesorias.net'
			}
		]
	},
	{
		title: <h4><i className="fa-solid fa-share-nodes"></i><br />Conect</h4>,
		links: [
			{
				label: <i className="fa-brands fa-linkedin-in"></i>,
				url  : 'https://www.linkedin.com/company/ndradvisoryfirm/'
			}
		]
	}
]

export {
	netwsEN,
	netwsES
}