
import NavLink from './NavLink'
import { useEffect, useState } from 'react'
import CustomSpinner from './CustomSpinner'
import { StyledArticleCard } from './styles/ArcticleCard.styled'


export default function ArcticleCard(props) {

	const [ imageSrc , setImageSrc ] = useState(null)

	useEffect(()=>{
		getImage()
	}, [props.updatedAt])

	const getImage = async () => {
		try {
			let res = await fetch(props.imagemini, {
					cache: 'reload',
					mode: 'no-cors'
				})
			if (!res.ok) return
			let blob = await res.blob()
			let src  = URL.createObjectURL(blob) 
			setImageSrc(src)
		} catch (err) {
			console.log(err)
		}
	}

	const callEditCB = () => {
		if (!props.editCB||!props.uuid) return
		props.editCB(props.uuid)
	}

	const callDelCB = () => {
		if (!props.delCB||!props.uuid) return
		props.delCB(props.uuid)
	}


	return (
		<StyledArticleCard className='card' key={props.title}>
			{imageSrc && <img src={imageSrc} />}
			{!imageSrc && <CustomSpinner />}
			<div className='card-info'>
				<h4>{props.title}</h4>
				<div dangerouslySetInnerHTML={{__html: props.textmini}}></div>
				<NavLink 
					to={`/blog/${props.uuid}`}
				>
					<small>Leer más</small>
				</NavLink>
			</div>
			{props.isEdit && 
				<div className='edit-div'>
					<i 
						onClick={callEditCB}
						className="fa-solid fa-pen-to-square"></i>
					<i 
						onClick={callDelCB}
						className="fa-solid fa-trash"></i>
				</div>
			}
		</StyledArticleCard>
	)
}