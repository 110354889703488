
// Styles
import './Blog.css'

// Thirdparty
import Fade from 'react-reveal/Fade'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Components
import ArticleCard from '../components/ArticleCard'
import CustomSlider from '../components/CustomSlider'

// Contexts
import { useArticlesContext } from '../contexts/articles/ArticlesState'
import { useLanguageContext } from '../contexts/language/LanguageState'

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }


export default function Blog() {

	const { uuid } = useParams()
	const { lang } = useLanguageContext()
	const [ image, setImage ] = useState(null)
	const { getArticle, articles } = useArticlesContext()
	const [ article, setArticle ] = useState({})

	useEffect(()=>{
		setImage(null)
		setArticle({})
		callGetArticle()
		window.scrollTo(0, 0)
	}, [uuid])

	useEffect(()=>{
		if (!Object.keys(article).length) return
		getImage()
	}, [article])

	const callGetArticle = async() => {
		let res = await getArticle(uuid)
		if (!res.ok) return
		let data = res.data.article
		setArticle(data.reduce((a, b)=>
			({
				...a, 
				[b.lang]: {
					...b,
					date: new Date(b.updatedAt)
				}
			}), {})
		)
	}

	const getImage = async() => {
		try {
			let res = await fetch(article.ES.image, {
					cache: 'reload',
					mode: 'no-cors'
				})
			let blob = await res.blob()
			let src  = URL.createObjectURL(blob) 
			setImage(src)
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<div className='blog'>
			<Fade>
			<h1 className='title'>
				{article[lang]?.title}
			</h1>
			</Fade>
			<Fade>
			<div
				style={{
					display: article[lang]?'flex':'none'
				}} 
				className='info'>
				<small>
					<i className="fa-regular fa-circle-user"></i>
					&emsp;{article[lang]?.author}
				</small>
				<small>
					<i className="fa-regular fa-clock"></i>
					&emsp;
					{
						new Intl.DateTimeFormat(lang, dateOptions)
						.format(article[lang]?.date)
					}
				</small>
			</div>
			</Fade>
			{image &&
				<Fade>
				<img src={image} />
				</Fade>
			}
			<Fade>
			<div
				className='content'
				dangerouslySetInnerHTML={{__html: article[lang]?.text}}
			>
			</div>
			</Fade>
			<Fade>
			<h2>{lang==='ES'?'Más Articulos':'More Posts'}</h2>
			</Fade>
			<CustomSlider>
				{articles
					.filter(item=>(item.lang===lang&item.uuid!==uuid))
					.map((item, idx)=> 
					<ArticleCard 
						key={idx} 
						{...item} 
					/>
				)}
			</CustomSlider>
		</div>
	)

}