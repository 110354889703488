
import { useState } from 'react'
import SliderDots from './SliderDots'
import styled from 'styled-components'

export default function QuotesSlider({ quotes }) {

	const [ quoteIdx, setQuoteIdx ] = useState(null)

	return (
		<StyledQuotesSlider>
			<SliderDots nItems={quotes.length} move={true} cb={setQuoteIdx} />
			{quotes.map((item, idx)=>
				<div
					key={idx}
					className='styled-quote'
					style={{'opacity': quoteIdx==idx?1:0}}>
					<p>{item.quote}</p>
					<small>{item.author}</small>
				</div>
			)}
		</StyledQuotesSlider>
	)
}


const StyledQuotesSlider = styled.div`
	
	left: 0;
	right: 0;
	margin: auto;
	height: 10rem;
	position: relative;
	width: min(400px, 80%);

	& .styled-quote {
		display: grid;
		grid-gap: 1rem;
		padding: 2rem 0;
		text-align: center;
		position: absolute;
		transition: opacity 1s;
		color: ${({theme})=>theme.text.color.scheme3};
	}

	& .styled-quote small {
		text-align: right;
	}

`