

// Components
import NavLink from './NavLink'
import { StyledHero } from './styles/Hero.styled' 

// Thirdparty
import Fade from 'react-reveal/Fade'
import { useNavigate } from 'react-router-dom'

export default function Hero({ data }) {

	const navigate = useNavigate()
	
	return (
		<StyledHero>
			<Fade>
			<div>
			<h1 className='home-hero--title'>
				{data.title}
			</h1>
			<h3 className='home-hero--text'>
				{data.subtl}
			</h3>
			<a 
				onClick={e=>navigate('/contacto')}
				className='home-hero--more'>
				{data.button}
			</a>
			<a 
				onClick={e=>navigate('/mochuelo')}
				className='home-hero--link'>
				{data.link}&emsp;
				<i className="fa-solid fa-arrow-right-long"></i>
			</a>
			</div>
			<img src='./images/hero-banners/landing-hero-img.webp' />
			</Fade>
		</StyledHero>
	)

}