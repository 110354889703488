
// Components
import CustomHero from '../components/CustomHero'

// Styles 

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'

// Thirdparty
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'

export default function Contact() {

	const { lang, prvcy } = useLanguageContext()

	return (
		<StyledPrivacy>
			<CustomHero 
				title ={lang==='ES'?'Política de privacidad':'Privacy policy'}
				label1={lang==='ES'?'Inicio':'Home'}
				label2={lang==='ES'?'Privacidad':'Privacy'}
				/>
			<Fade>
			<div className='privacy-text'>
				<p>{prvcy.text}</p>
			</div>
			</Fade>
		</StyledPrivacy>
	)
}


const StyledPrivacy = styled.div`
	
	.privacy-text {
		margin: auto;
		padding: 5rem 0;
		background: #FAFAFA;
		white-space: pre-wrap;
		max-width: min(1200px, calc(100% - 7rem));
	}

`

