
import { 
	HeadText, 
	CustomList,
	ImageBanner
} from '../components/ServicesComponents'

// styles 
import { StyledNIIF } from './styles/NIIF.styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'


export default function NIIF() {

	const { lang, servs } = useLanguageContext()

	return (
		<StyledNIIF>
			<HeadText {...servs.niif} />
			<div className='niif-procesos'>
				<h2>
					{
						lang==='ES' ?
						'Servicios...' :
						'Services...'
					}
				</h2>
				{servs.niif.procs.map((item, idx)=>
					<p key={idx}>
						<img src={item.img} />
						{item.label}
					</p>
				)}
			</div>
			<ImageBanner {...servs.niif.img} />
		</StyledNIIF>
	)
}

