
import './Footer.css'
import NavLink from './NavLink'

// Context
import { useLanguageContext } from '../contexts/language/LanguageState'

export default function Footer({ data }) {

	const gotoTop = (isBlank) => {
		if (isBlank) return
		window.scrollTo(0, 0)
	}

	return (

		<footer>
			{data.map((item,idx)=>
				<div key={idx}>
					<h3>{item.title}</h3>
					{item.links.map((link,lid)=>
						<NavLink 
							key={lid} 
							to={link.url}
							target={link.blank?'_blank':'_self'}
						>
							<div onClick={e=>gotoTop(link.blank)}>
								{link.label}
							</div>
						</NavLink>
					)}
				</div>
			)}
		</footer>
	)

}