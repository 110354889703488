
import { 
	HeadText, 
	CustomList,
	ImageBanner
} from '../components/ServicesComponents'

// Styles 
import { StyledAuditoria } from './styles/Auditoria.styled'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'


export default function Auditoria() {

	const { lang, servs } = useLanguageContext()

	return (
		<StyledAuditoria>
			<HeadText {...servs.auditoria} />
			<ImageBanner {...servs.auditoria.img} />
			<CustomList {...servs.auditoria.list} />
		</StyledAuditoria>
	)
}

