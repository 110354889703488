
import styled from 'styled-components'

export const StyledCustomSlider = styled.div`
	
	margin: auto;
	overflow: hidden;
	position: relative;
	height: min-content;
	margin-bottom: 4rem;
	width: min(calc(100% - 0rem), 1200px);
	display: ${({$show})=>$show?'inline-block':'none'};

	& .slider-div {
		margin: auto;
		display: grid;
		grid-gap: 2.5rem;
		overflow: hidden;
		max-width: min(calc(100% - 100px), 1200px);
	}

	& .slider-grid {
		display: grid;
		grid-gap: 10px;
		width: calc(100%);
		grid-auto-flow: column;
		transition: transform 0.5s;
		grid-auto-columns: calc(100% / ${({$ncards})=>$ncards} - 10px);
		transform: translateX(calc(-100% * ${({$idx})=>$idx} + 5px));
	}

	& i[class*=chevron] {
		top: 50%;
		bottom: 50%;
		margin: auto;
		cursor: pointer;
		font-size: 2rem;
		position: absolute;
		color: ${({theme})=>theme.text.color.scheme1};
	}

	& i[class*=right] {
		right: 10px;
		filter: drop-shadow(2px 2px 1px #0008);
	}

	& i[class*=left] {
		left: 10px;
		filter: drop-shadow(-2px 2px 1px #0008);
	}

`