
// Utils
import queryReducer from '../Utils/query-reducer'
import { queryInit, queryWrapper } from '../Utils/query-reducer'
// Styles
import { StyledArticleMenu } from './styles/ArticleMenu.styled'
// Thirdparty
import { useRef, useState, useReducer, useEffect } from 'react'

const initArticle = {
	title  : '',
	author : '',
	text   : ''
}

const justText = (text) => {
	let p = document.createElement('p')
	p.innerHTML = text
	return p.innerText.slice(0, 240)
}

export default function ArticleMenu({init, saveCB, closeCB}) {

	const textDiv = useRef()
	const imgInput = useRef()
	const [ info, setInfo ] = useState('')
	const [ lang, setLang ] = useState('ES')
	const [ data, setData ] = useState(init??{
		ES: initArticle, 
		EN: initArticle
	})
	const text = useRef({
		EN: init?.EN.text??'',
		ES: init?.ES.text??''
	})
	const [ query, dispatch ] = useReducer(queryReducer, queryInit)

	useEffect(()=>{
		const prevLang = lang
		return () => {
			setData(prev=>({
				...prev,
				[prevLang]: {
					...prev[prevLang],
					text: text.current[prevLang],
					textmini: justText(text.current[prevLang])
				}
			}))			
		}
	}, [lang])

	const handleChange = (e) => {
		let { type, value, name } = e.target
		setData(prev=>({
			...prev,
			[lang]: {
				...prev[lang],
				[name]: value
			}
		}))
	}

	const handleText = (e) => {
		text.current = {
			...text.current,
			[lang]: e.target.innerHTML
		}
	}

	const callSave = queryWrapper(async (e) => {
		e.preventDefault()
		if (!saveCB) return
		let body = {
			...data, 
			[lang]: {
				...data[lang], 
				text: text.current[lang],
				textmini: justText(text.current[lang])
			}
		}
		const imageFile = imgInput.current.files[0]
		let res = await saveCB(body, imageFile)
		if (!res.ok) return res
		res.callb = closeCB
		return res
	}, dispatch)

	const callClose = (e) => {
		e.preventDefault()
		closeCB()
	}

	return (
		<StyledArticleMenu className='article-menu'>
			<label htmlFor='lang'>Lenguaje</label>
			<select 
				id='lang'
				name='lang'
				value={lang}
				onChange={e=>
					setLang(e.currentTarget.value)}
			>
				<option value='EN'>EN</option>
				<option value='ES'>ES</option>
			</select>
			<label htmlFor='title'>Titulo</label>
			<input 
				id='title'
				type='text' 
				name='title'
				placeholder='Titulo*'
				onChange={handleChange}
				value={data[lang].title}
			/>
			<label htmlFor='author'>Autor</label>
			<input 
				id='author'
				type='text' 
				name='author'
				placeholder='Autor*'
				onChange={handleChange}
				value={data[lang].author}
			/>	
			<label htmlFor='image'>Imagen</label>
			<input 
				ref={imgInput}
				type="file" 
				id="image" 
				accept="image/*"
			/>
			<label htmlFor='text'>Contenido</label>	
			<div
				id='text'
				name='text'
				ref={textDiv}
				contentEditable
				onInput={handleText}
				dangerouslySetInnerHTML={{__html: data[lang].text}}
			>
			</div>
			<button
				id='cancel' 
				onClick={callClose}
				disabled={query.waiting}
			>
				Cancelar
			</button>
			<button 
				id='save'
				onClick={callSave}
				disabled={query.waiting}
			>
				{!query.waiting && 
					<p>Guardar</p>
				}
				{query.waiting && 
					<i className="fa-solid fa-spinner"></i>
				}
			</button>
			<div title={query.info} className='info'>
				{query.info}
			</div>
		</StyledArticleMenu>
	)
}
