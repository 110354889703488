
// Components
import NavLink from './NavLink'

// Thirdparty
import { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contexts
import { useLanguageContext } from '../contexts/language/LanguageState'


export default function CaseSection(props) {

	const div = useRef()
	const { id } = useParams()
	const { lang, cases } = useLanguageContext()

	if (!cases[id]) return

	useEffect(()=>{
		div.current.scrollIntoView()
	}, [])

	return (
		<StyledCaseSection ref={div}>
			<NavLink to='/clientes'>
				<i className="fa-solid fa-chevron-left"></i>&emsp;
				{lang==='ES' && 'Más casos'}
				{lang==='EN' && 'More cases'}
			</NavLink>
			<h1>{cases[id].title}</h1>
			<img src={cases[id].img} />
			<p>{cases[id].text}</p>
		</StyledCaseSection>
	)
}

const StyledCaseSection = styled.div`

	margin: auto;
	padding: 8rem 4rem 4rem;
	width: min(1200px, calc(100% - 4rem));

	& > h1 {
		width: 60%;
		margin-top: 3rem;
	}

	& > p {
		color: #6B6B6B;
		font-size: 0.9rem;
		white-space: pre-wrap;
	}

	& img {
		width: 50%;
		float: right;
		margin-left: 1rem;
	}

	& * {
		margin-bottom: 2rem;
	}

	@media screen and (max-width: 900px) {

		padding: 8rem 2rem 4rem;

		& img {
			width: 100%;
		}

	}
	
`